export enum TrVar {
  ABitBehind,
  About,
  AboutBehaviourAlgorithm,
  AboutChatCoaching,
  AboutCoaching,
  AboutCoachingThroughChat,
  AboutOurPotentialMeasurement,
  AboutOurPotentialMeasurementSub1,
  AboutOurPotentialMeasurementSub2,
  AboutPotential,
  AboutPotentialReference1,
  AboutPotentialReference2,
  AboutPotentialReference3,
  AboutPotentialReference4,
  AboutPotentialReference5,
  AboutPotentialReference6,
  AboutPotentialReference7,
  AboutPotentialReference8,
  AboutTotalPotential,
  AboutTotalPotentialSub1,
  AboutYou,
  AboutYourCoaching,
  AboveDesiredState,
  Accept,
  AcceptedByYou,
  AcceptGDPR,
  AcceptStorePersonalInfo,
  Access,
  AccessBasic,
  AccessPrivate,
  AccountInactive,
  AchieveBalanceInYourLifeThroughCoaching,
  AClientThatIsAboutToBookAFortyFiveMinuteMeeting,
  Active,
  AddAvailableTime,
  AddCalendar,
  AddDescriptionsOfYourCoachProfileInTheLanguagesYourClientsWant,
  AdditionalInformation,
  AddLanguage,
  AddMeetingTimes,
  AddMeetings,
  Address,
  Age,
  Agreement,
  AiBetaVersion,
  AiIntro,
  All,
  AllClients,
  AllCoachingAreas,
  AllDevelopmentAreas,
  AllExercises,
  AllMeetingsBooked,
  AlreadyCreatedAccount,
  AlsoNeeds,
  AlternativesForStatusOfTheMeeting,
  Amount,
  AnalyzingBehaviour,
  AnalyzingResult,
  And,
  AnonymisedReport,
  AnotherSessionIsAlreadyStarted,
  AnswerMissing,
  AnswerQuestions,
  AnswerYourFollowUp,
  Archive,
  Archived,
  AreasOfDevelopment,
  AreTheConversationsBetweenMyCoachAndISaved,
  AreYouSureYouWantToContinue,
  AreYouSureYouWantToRemove,
  AskUs,
  AttachedFiles,
  AtLeast10Characters,
  AtTime,
  AtZebrain,
  Available,
  AvailableTimes,
  Awesome,
  Back,
  Background,
  BackToLoginPage,
  BankAccount,
  BankId,
  BankIdCompleteTimeout,
  BankIdError,
  BankIdMobile,
  BankIdOnThisDevice,
  BankIdStartTimeout,
  BankIdWithPersonNumber,
  BasicEnergy,
  BasicEnergySub1,
  BasicEnergySub2,
  BasicEnergySub3,
  BasicEnergySub4,
  BasicEnergyIsTheAbsenceOfHarmfulStress,
  BeANeutralPartyWhoWillGuideYou,
  Behaviour,
  BehaviourAnalysis,
  BehaviourProfile,
  BeInCharge,
  BeingAwareOfYourNeeds,
  BeingCoachAtZebrain,
  BeingInControl,
  BeMotivatedAndPreparedToDoTheWorkRequiredToAchieveYourGoals,
  BeResponsibleForActingInTheDirectionTowardsYourDesiredSituation,
  BestRegardsTeamZebrain,
  BetaVersion,
  Between,
  BigFive,
  BigFiveWithASixthFactor,
  BirgerJarlsgatan34,
  Birthday,
  Book,
  BookAMeetingLater,
  BookAMeetingWithYourCoach,
  Booked,
  BookMeeting,
  BookMoreMeetings,
  BookNewMeeting,
  Boosting,
  BoostingBehaviour,
  BoostingDesc,
  BoostYourCareerThroughCoaching,
  BoostYourSelfEsteemWithCoaching,
  ByBasicEnergyWeMeanTheAbsenceOfHarmfulStressInOurLives,
  ByContinuouslyTakingNewPotentialMeasurements,
  CalculatingDevelopmentAreas,
  CalculatingExercises,
  CalendarChangeTime,
  CanAlsoBe,
  CanBookLater,
  Cancel,
  Cancelled,
  CancelMeeting,
  CancelMeetingExplanation,
  CanIReturnToMyToMyChatCoachAfterReceivingCoaching,
  CannotBeBooked,
  CannotBookMeetings,
  CannotBookMeetingsAfter,
  CannotFindAccount,
  CannotReschedule,
  CannotWriteMessages,
  CertificateError,
  CertifiedSince,
  Change,
  ChangeBooking,
  ChangeChat,
  ChangeInCurrentState,
  ChangeLoginMethod,
  ChangeMeeting,
  ChangeMeetingBlock,
  ChangeProfilePicture,
  ChangeUnder,
  Chat,
  ChatCoaching,
  ChatCoachingWorksLikeThis,
  ChatToReschedule,
  ChatRead,
  ChatWith,
  ChatWithYourCoach,
  ChatWithYourCoachAndBookTheMeetingsEveryWeekInsteadOr,
  ChatWithYourCoachAndFindASuitableTime,
  Checkout,
  Choose,
  ChooseALanguageToWriteIn,
  ChooseANewPicture,
  ChooseAreaOfDevelopment,
  ChooseAreaOfDevelopmentAndLanguages,
  ChooseClient,
  ChooseFile,
  ChooseHowToLogin,
  ChooseInterest,
  ChooseInterestingAreasOfDevelopment,
  ChooseLanguage,
  ChooseLanguageForYourDescription,
  ChooseNewFile,
  ChooseOneOption,
  ChooseStatementAndPowerQuestions,
  ChooseTheLoginMethodYouUsedWhenYouCreatedYourAccount,
  ChooseYourCalendarBelow,
  City,
  Clarity,
  ClearFeedbackAndReflection,
  ClickAddCalendar,
  ClickOkayBelowThenYouGoToCalendar,
  ClickOnATimeInTheCalendarWhereYouWantToAddAMeetingSuggestion,
  ClickOnATimeInTheCalendarWhereYouWantToAddATimeInterval,
  ClickOnATimeInTheCalendarWhereYouWantToMoveTheClientMeeting,
  ClickOnImport,
  ClickOnTheColumnsHeaderToSort,
  ClickSubscribe,
  ClickToSelectNewTimeWith,
  ClickWhereYouWantToSuggestMeeting,
  Client,
  ClientCancelledLate,
  ClientCancelledLateOrDidNotShow,
  ClientDashboard,
  ClientInfo,
  ClientIsBehindSchedule,
  ClientOnboardingIntro,
  ClientPotentialDevelopment,
  ClientPotentialTasks,
  ClientsCoachingAreas,
  ClientSuggestion,
  ClientsWhoCanOnlyChat,
  ClientsYouHaveCoachedBefore,
  CloseChat,
  Coach,
  CoachAgreement,
  CoachAreas,
  CoachCancelMeetingWarning,
  CoachCertificate,
  CoachDescription,
  CoachesThatMatchDesiredProfile,
  CoachExpertise,
  CoachHasNoAvailableTimes,
  CoachHours,
  Coaching,
  CoachingAreas,
  CoachingIsAboutUnlockingYourFullPotentialByGuidingAndChallenging,
  CoachingIsAlsoAboutLookingForwardAndGuidingYou,
  CoachingLanguage,
  CoachLanguages,
  CoachMembership,
  CoachMeasureLink,
  CoachNotes,
  CoachOnboardingIntro1,
  CoachOnboardingIntro2,
  CoachOnboardingInside,
  CoachOnboardingSubscription,
  CoachSuggestion,
  CoachTasksHeader,
  CoachTasksIntro,
  CoachTasksLink,
  CoachTasksStep1,
  CoachTasksStep2,
  CoachTasksStep3,
  CoachTasksStep4,
  CoachTasksStep3Sub1,
  CoachTasksStep3Sub2,
  CoachTasksSubheader1,
  CoachQuestionsDone,
  ComingMeetings,
  Community,
  Company,
  Compare,
  ComparedToPreviousMeasurement,
  Completed,
  ConductCoachingWIthTheHelpOfPowerfulQuestions,
  Confirm,
  ConfirmMeeting,
  ConfirmRemoveChat,
  Confirmed,
  ConfirmedReschedule,
  CongratulationsYouGotLicense,
  ContactPerson,
  ContactSupportZebrain,
  Contact,
  ContactSupport,
  ContactUs,
  Contains,
  Content,
  Continue,
  ContinualMeasurementOfDevelopment,
  ContinuousFeedback,
  ContributeToTheProcessByBeingActiveAndOpenToTheProcess,
  ConversationsAreSaved,
  Cooperation,
  CopyTheUrlBelow,
  Correct,
  CouldNotFindLicense,
  CouldWeContactYourBoss,
  CreateAccount,
  CreateAccountBelow,
  CreateClarityOnYourGoals,
  CreateClearGoals,
  Created,
  CreateFree,
  CreateFreeAccount,
  CrownsPerMonth,
  CrucialFactors,
  CurrentState,
  CustomerFramework,
  CustomnizedCoaching,
  Date,
  DateHasPassed,
  DateOfBirthPlaceholder,
  DaysLeft,
  Decrease,
  Decreased,
  DescriptionInformation,
  DescriptionOfYouAsCoach,
  Desired,
  DesiredState,
  DesiredStateOrGap,
  DetailBehaviour,
  Detailed,
  DetailedBehaviour,
  DetailedDesc,
  DevelopFasterWithChatCoach,
  DevelopFasterWithCoach,
  DevelopmentAreas,
  DevelopmentAreasBeforeCoach,
  DevelopmentFor,
  DevelopYourFullPotential,
  DidWeReachGoal,
  Discover,
  DiscoverTheBenefitsOfChatCoaching,
  DoesNotAllowMeetingsWithCoach,
  DoesYourCoachNotHaveAnyTimes,
  DoFollowUp,
  Done,
  DoNotForgetToSave,
  DoNotWantToSpecify,
  DoYouAlreadyHaveAnAccount,
  DoYouHaveAQuestionOrWantToLeaveFeedback,
  DoYouNotHaveAnAccount,
  DoYouWantToCancel,
  DoYouWantToCancelMeeting,
  DoYouWantToCreateAndAccount,
  DoYouWantToImproveYourRelationshipWithYourColleagues,
  DoYouWantToRemoveMeetingSuggestion,
  DoYouWantToSave,
  DoYouWantToSignIn,
  DoYouWantToUpgrade,
  DriveYourDevelopmentForwardButNotGiveYouReadyMadeSolutions,
  Driving,
  DrivingBehaviour,
  DrivingDesc,
  EachBoxInTheBarShowsOnMeetingPeriod,
  EarliestBooking,
  Edit,
  EditAvailableTime,
  EditProfile,
  Email,
  EmailAndPassword,
  EmailContactPerson,
  EmailInUse,
  Employer,
  EmptyVisionBoard,
  Ended,
  EngagingInActivitiesYouAreInterestedIn,
  EnterVideoMeeting,
  EnterYourEmailAddressToRecoverPassword,
  EnterYourLicenseCode,
  EvaluatingOurActionsAndAdjustingThem,
  Example,
  Exercise,
  Exercises,
  ExperienceProgress,
  ExtinguishFactors,
  Facebook,
  Faq,
  FaqLeaveFeedbackQuestion, 
  FaqLeaveFeedbackAnswer, 
  FaqTechnicalIssuesQuestion, 
  FaqTechnicalIssuesAnswer, 
  FaqNoAvailableTimesQuestion, 
  FaqNoAvailableTimesAnswer, 
  FaqMissedMeetingQuestion, 
  FaqMissedMeetingAnswer, 
  FaqContactZebrainQuestion, 
  FaqContactZebrainAnswer, 
  FaqUnusedMeetingsQuestion, 
  FaqUnusedMeetingsAnswer, 
  FaqCancelMeetingQuestion, 
  FaqCancelMeetingAnswer, 
  FaqLicenseDurationQuestion, 
  FaqLicenseDurationAnswer, 
  FaqBiweeklyMeetingImportanceQuestion, 
  FaqBiweeklyMeetingImportanceAnswer, 
  FaqVideoChatIssuesQuestion, 
  FaqVideoChatIssuesAnswer,
  FaqICFAccreditedCoach,
  FaqICFCoachLevelRequirement,
  FaqICFCertificationValidity,
  FaqValidICFCertificationProcess,
  FaqRelationshipWithZebrain,
  FaqZebrainPartnershipDetails,
  FaqCostToJoinZebrain,
  FaqJoiningZebrainIsFree,
  FaqContractCommitmentDuration,
  FaqStandardContractTerms,
  FaqEmploymentFormAtZebrain,
  FaqEmploymentRequirements,
  FaqZebrainInside,
  FaqZebrainInsideExplanation,
  FaqInvoiceSubmission,
  FaqInvoiceProcess,
  FaqClientBase,
  FaqClientBaseExplanation,
  FaqMeetingClients,
  FaqDigitalCoaching,
  FaqClientMeetingDuration,
  FaqMeetingDurationDetails,
  FaqContactWithClients,
  FaqClientCommunication,
  FaqExtendingClientLicense,
  FaqLicenseExtensionProcess,
  FaqTechnicalIssuesWithPlatform,
  FaqTechnicalSupport,
  FastGuidance,
  FeelingBalancedEnergizedAndTheAbsence,
  FindUs,
  Finished,
  FirstName,
  FollowUp,
  FollowingUp,
  FollowUsOnSocialMedia,
  FormatPhoneNumber,
  FormErrors,
  FortyFiveIntroductionMeeting,
  FoundNoAccountWithThatPersonNumber,
  Free,
  From,
  FromUrl,
  FurtherDevelopmentOfBigFive,
  FurtherDevelopmentOfSocialStyles,
  FutureDescription,
  FutureMeetingsBooked,
  GetAPersonalChatCoach,
  GetAPersonalCoach,
  GetCoachedByAI,
  GetLicensePackage,
  GetStarted,
  GetStartedAsClient,
  GetStartedAsCoach,
  GetStartedToday,
  GetStartedWithYourAreasOfDevelopment,
  GiacomoRizzolottis,
  GiacomoRizzolottisResearchAboutMirrorNeurons,
  Gmail,
  GoalOfTodaysMeeting,
  GoBack,
  GoForward,
  GoneFrom,
  GoToChat,
  GoToSettingsMenu,
  GoToVideoMeeting,
  GoWebsite,
  Great,
  GreatChoice,
  GrowPotential,
  Had,
  HandleMeetingTimes,
  HappyToSeeYou,
  Has,
  HasBookedOneMeetingPerPeriod,
  HasSharedPotential,
  HasWorkedWith,
  Have,
  HaveInvitedYou,
  HaveYouDeveloped,
  HaveYouDoneYourPlan,
  HaveYouGainedInsights,
  HelenFisher,
  HelenFishersResearchTogetherWithBrainResearcherLucyLBrown,
  Help,
  Here,
  HereYouCanConfirmThatTheMeeting,
  HereYouCanSortOnMeasuresCurrentState,
  HereYouSeeTheCoachQuestions,
  HexacoModel,
  HideNotes,
  HideVideo,
  HighestGap,
  HighestValueOnTop,
  Hours,
  HowChangeCoach,
  HowChangeCoachAnswer,
  HowChangeCoachAnswer2,
  HowClientsSeeYou,
  HowDoesChatCoachingWork,
  HowDoICancelMyAccount,
  HowDoIFeel,
  HowDoIGetThere,
  HowDoIGetToWhereIWantToBe,
  HowDoIKnowIfImMakingProgress,
  HowDoISolveIssuesWithTheVideoChat,
  HowDoIWorkbestWithMyAreasOfDevelopmentToReachMyPotential,
  HowDoYouExperienceYourSituation,
  HowLongDoIHaveAccessToTheCoach,
  HowMotivatedAmI,
  HowToStrengthenYourSelfLeadership,
  HowToIncreaseYourStressResilience,
  HowToIdentifyYourStrengthsAndIncreaseYourMotivation,
  HowWeJudgeOthers,
  HowWorksChatCoaching,
  HowYouUseThePlatform,
  IAccept,
  IApproveThatZebrainStoresMyPersonalInformation,
  IDIInterpersonalDynamicInventory,
  IdNumber,
  IfAClientWantsToBeCoachedInALanguageYouHaveNotAdded,
  IfNobodyShowsUpPleaseContact,
  IfSomeoneBooksALongerMeeting,
  IfSomeoneHasBookedAMeetingInYourMeetingBlock,
  IfThereAreNoAvailableTimes,
  IfThisIsYourFirstTimeAsAZebrainUserYouCanProceedToSetUpYourAccount,
  IfYouAlreadyCreatedAnAccountWithAnotherLoginMethod,
  IfYouAlreadyHaveAnAccountSignInAndContinue,
  IfYouClickInAGreenMeetingBlockYouCanChange,
  IfYouDontChooseSortingTheTableIs,
  IfYouDontFollowGetInTouch,
  IfYouGetBookedMeetingsWithDifferentLengthsInAMeetingBlock,
  IfYouHaveCreatedAZebrainAccountBeforePleaseSendAMailToSupport,
  IfYouHaveHadCoach,
  IfYouHaveLicenseCode,
  IfYouHaventCreatedAnAccountToGetStartedContactSupport,
  IfYouLackSomethingEmail,
  IfYourCompanyPaysYourLicenseYouShouldNotCreateAccount,
  IfYourLicenseIsPaidByyourOrganisationAnAnonymisedReportWillBeAvailableForTheContactPersonInYourOrganisation,
  IfYouWantToCancelYourAccountPleaseContactZebrain,
  Ignition,
  IgnitionFactors,
  IgnitionFactorsAreInternalFactors,
  IHaveMissedAMeetingWhatDoIDo,
  IIdentifyAs,
  Improve,
  Included,
  InCommon,
  Incorrect,
  Increase,
  Increased,
  IndividualAccountOverview,
  InfoInactiveClient,
  InMonth,
  Inside,
  Insight,
  Instagram,
  InstructionEmail,
  Interest,
  InTheNextStepWeWillMatchYouWithOneOfOurCoaches,
  InvalidEmail,
  InvitationExpired,
  InvitationNotFound,
  Is,
  IsAlsoGoodAt,
  IsAnExcellentMatch,
  IsEspeciallyGood,
  IsUnchanged,
  ItIsCompletelyNaturalWithSetbacks,
  IWantToChat,
  IWantToUpgrade,
  KeepBoostingYourselfWithCoaching,
  KeepInMind,
  KeepWorkingWithYourAreaOfDevelopment,
  Kickstart,
  KnowWhatApplies,
  LangToBeCoached,
  LangToCoach,
  Language,
  Larger,
  LargestImprovement,
  LargestReachedPotential,
  LargestUnusedPotential,
  Last,
  LastDay,
  LastName,
  LastUpdated,
  LeaveAComment,
  LeaveContactInformationAndWeWillTakeOver,
  LeaveContacts,
  LeaveMeeting,
  Left,
  LeftToDesiredState,
  Length,
  License,
  LicenseCode,
  LicenseCodeNotFound,
  LicenseExpired,
  LicenseIsNotAvailable,
  LicenseIsValidTo,
  LicenseIsValidUpTo,
  LicenseType,
  LiftCoachingWithYourBoss,
  LinkedIn,
  LinkError,
  LogBack,
  Login,
  LoginCancelled,
  LoginFailed,
  LoginOnAnotherUnit,
  LoginOnThisUnit,
  LoginWithBankId,
  Logging,
  LowestValueOnTop,
  MacCalendar,
  MakeFollowUp,
  Man,
  ManagementCoachExperience,
  MatchingSortedCoaches,
  MatchingWithCoach,
  MatchingWithCoaches,
  MatchWithCoach,
  Max340Characters,
  Maximise,
  Meaningfulness,
  Measure,
  MeasureContinuously,
  MeasureEffectsCoaching,
  Measurement,
  MeasurePotential,
  MeasureYourDevelopment,
  MeasureYourPotentialAndDevelopment,
  Meeting,
  MeetingBookings,
  MeetAICoach,
  MeetingCancelled,
  MeetingClientCancelledLate,
  MeetingCoachDidNotShow,
  MeetingEveryFourWeeks,
  MeetingEveryOtherWeek,
  MeetingHistory,
  MeetingIncludedInLicense,
  MeetingIsClosedReopen,
  MeetingLength,
  MeetingMinPause,
  MeetingNotes,
  MeetingPartner,
  MeetingSuggestion,
  Meetings,
  MeetingsCannotEndBeforeTheyStart,
  MeetingsDone,
  MeetingsNoOverlap,
  MeetingsRemainingToBook,
  MeetingStarts,
  MeetingStatus,
  MeetingStatusIsNotAvailable,
  MeetingsWhereTheStartTimeHasPassed,
  MeetingTimeNotAvailable,
  MeetingWentAccordingToPlan,
  MeetingWithCoach,
  MessageFromCustomer,
  Microphone,
  Min,
  Minutes,
  MinutesBreakClientMeetings,
  MinutesEach,
  MinutesMeetingWithCoach,
  MissingInput,
  MissingStatus,
  Month,
  More,
  MoreAboutOurFourAreas,
  MoreAboutOurFourAreasSub1,
  MoreAboutSelfLeadership,
  MoreAboutSelfLeadershipSub1Part1,
  MoreAboutSelfLeadershipSub1Part2,
  MoreAboutSelfLeadershipSub2,
  MoreAboutYou,
  MostAsked,
  Motivation,
  MotivationSub1,
  MotivationContainsEverythingWeNeedToFeelMotivated,
  MotivationDescription,
  MovedToPreviousMeetings,
  MoveMeeting,
  MsgSent,
  MuchBehind,
  MuteMic,
  MyAccount,
  MyBookings,
  MyClients,
  MyCoachHasNoAvailableTimesWhatShouldIDo,
  MyCoachProfile,
  MyCoachSettings,
  MyLicense,
  MyLicenseIsntValidLongEnoughForMeToHaveMeetingsEveryOtherWeek,
  NA,
  Name,
  NameOfContact,
  NeedLicenseCode,
  New,
  NewCalendarSubscription,
  NewChat,
  NewMeasurement,
  NewTime,
  Next,
  NextMeeting,
  No,
  NoAccessToCoachYet,
  NoAccount,
  NoAvailableTimesBooking,
  NoBehaviourMeasure,
  NobodyShowsUpInTheMeeting,
  NoBookedMeeting,
  NoChat,
  NoEndDate,
  NonBinary,
  NoneToChatWith,
  NoFileError,
  NoSave,
  NoSeatsAvailable,
  NoSuggestedMeetings,
  NotConfirmed,
  NotSelectedCoach,
  NotStarted,
  NotifyMyEmployer,
  Now,
  NumberCoachHours,
  NumberYears,
  Of,
  OfferExpired,
  OfferIsValid,
  Onboarded,
  OncePerMonth,
  OneOneFourStockholm,
  OneStartedDevelopmentArea,
  Ongoing,
  OngoingPlan,
  Only,
  OnlyWorksSweden,
  OnTrack,
  OOpsChatWithYourCoachHopefullyYouCanFindANewTime,
  OpenChat,
  Optimise,
  Optional,
  Organisation,
  OrgNr,
  OrSomeoneYouThinkNeedCoaching,
  Other,
  OtherQuestions,
  OthersSeeYouSucceed,
  OurAreas,
  OurMatchingAlgorithmIsBasedOnBigFive,
  OurMatchingAlgorithmIsBasedOnBigFive2,
  OurMeasurementOfMotivationIsBasedOnSelfDeterminationTheory,
  OurMethod,
  Outlook,
  Overview,
  PageNotFound,
  Part1Of2,
  Part2Of2,
  Partially,
  Partly,
  Password,
  PasswordAgain,
  PasswordChanged,
  PasswordDontMatch,
  PasswordMustIncludeAtLeastTenCharacters,
  PasswordTooShort,
  PasteTheCopiedUrl,
  PausBetweenSessions,
  Payment,
  PayWithKlarna,
  PerformanceDescription,
  PerFourWeeks,
  PerMonth,
  PersonalData,
  PerTwoWeeks,
  PerWeek,
  Philosophy,
  Phone,
  PhoneContactPerson,
  PhoneNumber,
  PhoneNumberPlaceholder,
  Picture,
  PlanCompleted,
  PlansAndFollowUp,
  PleaseChooseAnotherClient,
  PleaseConsiderThatTheClientHasReceivedAMail,
  PleaseStartBankId,
  PotentialMeasurement,
  PowerAreaDone,
  PowerAreaDone0,
  PowerAreaDone1,
  PowerAreaDone2,
  PowerAreaDone3,
  PowerAreaGood0,
  PowerAreaGood1,
  PowerAreaGood2,
  PowerAreaGood3,
  PowerfulQuestion,
  PowerfulQuestionAsSupport,
  PowerfulQuestions,
  PowerfulQuestionsDescription,
  PowerQuestionsFeedback1,
  PowerQuestionsFeedback2,
  PowerQuestionsFeedback3,
  PowerQuestionsIntro,
  PowerQuestionsIntro0,
  PowerQuestionsIntro1,
  PowerQuestionsIntro2,
  PowerQuestionsIntro3,
  Preview,
  Previous,
  PreviousChats,
  PreviousCoaches,
  PreviousCoaching,
  PreviousMeetings,
  ProvideUsYourDetails,
  PurchaseConfirmation,
  Question,
  Questions,
  QuestionsToWorkWith,
  RaiseYourselfToNewHeights,
  ReachYourPotential,
  ReachYourPotentialFasterWithACoach,
  ReadMessage,
  ReadMore,
  ReadMoreAboutHowZebrainHandlesPersonalInformation,
  ReadMoreAboutYou,
  ReadMoreAboutYour,
  ReceiveContinuedGuidanceThroughYourDevelopment,
  ReceiveGuidanceInIdentifyingYourStrengthsAndMotivations,
  ReceiveRegognitionOfSuccess,
  RecognisingWhenWeSucceedAndRewardingOurselves,
  RecommendationTypeNps,
  RecommendationTypeNpsCoach,
  RecoverPassword,
  RedoTheAnalysis,
  References,
  Reflection,
  Reject,
  RejectedByYou,
  Relations,
  RelationsDescription,
  RememberToFollowUpYourPlan,
  RememberToTryToBePatientAndNotExpectInstantResult,
  Reminder,
  Remove,
  RemoveSuggestion,
  RepeatPassword,
  ReportInterest,
  Reschedule,
  RescheduleClientMeeting,
  RescheduleMeeting,
  ResearchBehind,
  ResetPassword,
  Result,
  ResultFromYourMeasurement,
  ResultPerQuestion,
  ResultsPerArea,
  Review,
  RevisedNEOPersonality,
  Right,
  RightNowWeHaveManyApplicants,
  Rotate,
  Save,
  SaveYourResults,
  ScanningCoaches,
  ScheduleAMeeting,
  SCMStereotypeContentModel,
  SeeChat,
  SeeFinishedExercises,
  SeeFollowUpExercises,
  SeeOngoingExercises,
  SeeQuestions,
  SeeVisionboard,
  SeeWholeProfile,
  SeeYourselfSucceed,
  Sek,
  Select,
  SelectChat,
  SelectCoach,
  SelectLanguageOfYourCoach,
  SelectLanguagesYouCanCoachIn,
  SelectNewTime,
  SelfInsight,
  SelfInsightSub1,
  SelfInsightSub2,
  SelfInsightDescription,
  SelfInsightIsANecessaryFoundation,
  SelfInsightIsTheFoundationToAllDevelopment,
  SelfLeadership,
  SelfLeadershipSub1,
  SelfLeadershipSub2,
  SelfLeadershipSub3,
  SelfLeadershipIsTheAbilityToWorkOnOurOwn,
  SelfLeadershipWasIntroducedInLiterature,
  Send,
  SendAEmailToSupportAndWeWillHelpYouOut,
  SendAMailToSupportAndWeWillHelpYou,
  SenseOfMeaningfulness,
  Settings,
  SettingYourOwnClearGoals,
  SetUpAccount,
  ShareLinkBody,
  ShareLinkTitle,
  SharonParkerDescription,
  SignedOut,
  SignInWithEmail,
  SignInWithNewPassword,
  SignOut,
  SinceLastMeasurement,
  SinceYouStarted,
  SituationAfterCoaching,
  ShortestMeetingTimeIs30Min,
  ShortSummary,
  Show,
  ShowingMeetingsWith,
  ShowLanguages,
  ShowNotes,
  ShowsAmongOtherThingsTheImportanceOfAdapting,
  ShowsChangeInCurrentStateSincePreviousMeeting,
  ShowVideo,
  Smaller,
  SocialStyles,
  Start,
  StartBankIdPhone,
  Started,
  StartState,
  StartPresenting,
  StartWorkingWithYourPotential,
  Statement,
  Statements,
  Statistics,
  STCSystemsCenteredTherapy,
  StopPresenting,
  StoreProfileForTwelveMonths,
  Strengths,
  StressDescription,
  SubmitCoachRequest,
  SubscribeFromWeb,
  Subscription,
  SubscriptionWillBeRenewed,
  SubscriptionWillEnd,
  SuggestedMeetings,
  SuggestMeeting,
  SuggestMeetingToClient,
  Summary,
  SummaryBillableMeetings,
  SumOfAndTheRelationshipBetweenTheseFourFactors,
  Support,
  SupportAtZebrainSe,
  SupportAndChallengeYouInTheProcess,
  Supporting,
  SupportingBehaviour,
  SupportingDesc,
  SwitchToClient,
  SwitchToCoach,
  TakeALookAtYourPotentialAssessmentOrRecommendedDevelopmentAreasForMoreIdeas,
  TakeTheShortcutToFullPotential,
  TechnicalProblemsTheClientGetsAnotherMeeting,
  TelephoneNumber,
  Terminate,
  Terminated,
  TestChatCoach,
  TestMeetingBeforeCoachSession,
  TextAboutMeAsCoach,
  TextHere,
  Thanks,
  ThanksCya,
  ThanksWhenZebrainHasApproved,
  ThatTimeHasAlreadyPassed,
  TheAbilityToUnderstandAndActAccording,
  TheChat,
  TheLicense,
  TheLinkThatTookYouHereIsNotMeantForFirstTimeUsers,
  TheMeetingIsBooked,
  TheMeetingTookPlaceInSpiteOfProblems,
  ThereAreNoConnectionsBetweenOurPersonalitiesOrOurDifferentBehaviouralPatterns,
  ThereIsAHelpSectionInTheVideoClientWhereYouCanFindSolutions,
  ThereIsNoSavedConversation,
  TheRoleOfTheClientIsTo,
  TheRoleOfTheCoachIsTo,
  TheRoleOfTheCoachIsToSupportAndChallengeYouInTheProcessOfReachingYourGoals,
  TheseFactorsNeedToBeSufficientlyFulfilled,
  TheseNotesAreOnlyVisible,
  ThinkAbout,
  ThisIsBecauseTheLicenseLacksEndDateOrLimitedNumberOfMeetings,
  ThisIsHowYouAccessYourUntappedPotential,
  ThisIsHowYouAccessYourUntappedPotentialSub1,
  ThisIsHowYouAccessYourUntappedPotentialSub2,
  ThisIsIncluded,
  ThisLicenseHasExpired,
  ThisLicenseHasNoAvailableSeats,
  ThisLinkHasExpired,
  ThisLinkNoLongerWorks,
  Time,
  TimeAvailability,
  TimeElevenFifteen,
  TimeElevenOClock,
  TimeForFollowup,
  TimeFrequency,
  TimeNineFortyFive,
  TimeNineOClock,
  TimeTenOClock,
  TimeTenThirty,
  TimesFrequency,
  TimesPerMonth,
  Tip,
  TipThanks,
  TipSomeone,
  TipSomeoneYouLike,
  TipYourBoss,
  To,
  ToCalendar,
  ToChat,
  ToContinuallyEvaluateOurActionsOverALongPeriod,
  ToCreateAnAccountInZebrainYouNeedAnInvitation,
  Today,
  ToEvaluateAndImproveThePlatformItMayInSomeSituationsBeNecessaryToReview,
  ToFeelReallyGood,
  ToGetIntoTheHabitOfWorkingOnYourPersonal,
  ToMeasurement,
  TooBigImgError,
  TooMuchGoingOnInYourHead,
  ToOverview,
  ToReadMoreAboutHowWeHandleYourPersonalInformation,
  Total,
  TotalPotential,
  TotalTime,
  Trainer,
  TreatTheContentOfTheConversationsPersonalTrustPrivateRelations,
  TwelveDigitalCoachQeustions,
  UnBookable,
  UncancelTermination,
  UnexpectedErrorOccurred,
  UnfortunatelyTheMeetingWillBeUsed,
  UnfortunatelyYouWillLoseMeetingsYouHaventUsed,
  UniqueMatching,
  UnknownLicenseError,
  UnlimitedUsage,
  UnlockExercise,
  UnmuteMic,
  Unread,
  UnreadMessages,
  UntilThenClickTheButtonBelow,
  UntilThenFillOutTheContactForm,
  UpdatePassword,
  Upgrade,
  UpgradeTo,
  UpgradeToCoaching,
  UploadCertificate,
  UploadedDocument,
  ValidBetween,
  ViewYourDevelopment,
  VideoChatWithCoach,
  VideoHelpNoPartnerPart1,
  VideoHelpNoPartnerPart2,
  VideoMeeting,
  VideoMeetingIn,
  W,
  WaitingFor,
  WaitingForConfirmation,
  WaitingForYourBankIdCode,
  WaitingForYourBankIdSigning,
  WantCoach,
  WantedState,
  WantedTimeBetweenMeetings,
  WantsToDecreaseTheirPotential,
  WantsToIncreaseTheirPotential,
  WantToCancel,
  WantToCancelMeeting,
  WantToCoachIn,
  WantToSave,
  WantToShareLink,
  Warning,
  WeAreSoHappyThatYouWantToBecomeACoachWithUs,
  WeAreWorkingToMakeItPossibleToUpgradeToCoach,
  Website,
  WeCouldNotCreateAccount,
  WeDemocratiseMentalStrength,
  Week,
  WeeksPlanned,
  WeHopeTheMeetingWentWell,
  Welcome,
  WelcomeAboard,
  WelcomeAndThanksForYourApplication,
  WelcomeToZebrainCreateAUserAndGetStarted,
  WelcomeToZebrainCreateAUserAndGetStartedWithYourDevelopment,
  Wellbeing,
  WellDone,
  WeRecommendEveryOtherWeekSoThatYouWillHaveTimeToDoTasks,
  WeReportCoachRequest,
  WeWantToTipThemAboutCoaching,
  WeWillBeInTouch,
  WeWillNotify,
  WeWillNotifyYourEmployerThatYouWantChatCoaching,
  WeWillNotifyYourEmployerThatYouWantVideoCoaching,
  WhatCanITalkToMyCoachAbout,
  WhatHappensIfIDontCancelWithin24HoursAdvance,
  WhatHappensIfIDontHaveTimeForAllMyMeetings,
  WhatHappensNext,
  WhatHappensToMyCoachIfMyLicenseIsUpgradedToVideoCoaching,
  WhatIsCoaching,
  WhatIsPotential,
  WhatIsPotentialSub1,
  WhatIsPotentialSub2,
  WhatShouldIDoIfIHaveTechnicalIssuesWithThePlatform,
  WhatTheClientHasDone,
  WhatWeNeedInOrderToFeelMotivated,
  WhatYouCanTalkToYourCoachAbout,
  WelcomeToFramwework,
  WhenWeAreAwareOfOurIgniterAndExtinguish,
  WhenWeUseOurBoostingBehaviour,
  WhenWeUseOurDetailedBehaviour,
  WhenWeUseOurDrivingBehaviour,
  WhenWeUseOurSupportingBehaviour,
  WhenWillYouDoIt,
  WhenYouGetUpgradedToVideoCoaching,
  WhenYourClientWantsToBookAMeetingZebrainWillCalculate,
  WhenYourLicenseExpires,
  WhenYouSaveTheMeeting,
  WhenYouSaveTheMeetingSuggestion,
  WhichActionsDidYouTake,
  WhichAllowsForMeeting,
  WhichAreYourKeyTakeaways,
  WhileYouWaitCheckoutSoMe,
  WhoAmI,
  WhoCanSeeMyAnswersInThePlatform,
  Whoops,
  WhyIsItImportantToBookAMeetingEveryOtherWeek,
  WhyZebrain,
  With,
  WithAccessYouUnlock,
  WithLicenseAccess,
  WithYou,
  WithOtherCoach,
  Woman,
  WorkExperience,
  WorkPlace,
  WorldsMostWidelyUsedResearchBasedPersonalityModels,
  WouldYouLikeToContinueReceivingCoaching,
  WriteAsOftenAsYouWant,
  WriteHere,
  WriteQuestion,
  WriteToYourCoachIn,
  WriteToYourCoachWhenever,
  WrongFormatEmail,
  WrongFormatPersonalNumber,
  WwwFacebookCom,
  WwwLinkedinCom,
  WwwZebrainSe,
  Yes,
  YesFollowUp,
  YesRemove,
  YesYouCanJustChooseThemAgain,
  You,
  YouAddAMeetingBlockForMonday,
  YouAlsoNeed,
  YouAre,
  YouAreAlso,
  YouAreAlsoGoodAt,
  YouAreEspeciallyGoodAt,
  YouCanBeAnonymous,
  YouCancelledTheMeetingLate,
  YouCannotChatWithClientAnymore,
  YouCannotMakeAnyChanges,
  YouCanOwnYourDataUnderGDPR,
  YouCanStillReadMessages,
  YouCanSynchroniseYourAvailableTimes,
  YouCanTalkToYourCoachAbout1,
  YouCanTalkToYourCoachAbout2,
  YouCanTalkToYourCoachAbout3,
  YouCanWorkOn,
  YouCreate,
  YouDontHaveALicense,
  YouDontHaveAPlan,
  YouGet,
  YouHave,
  YouHaveAcessToYourCoachWhileAccessLicense,
  YouHaveAlreadyUpgraded,
  YouHaveAlreadyUpgradedToAccess,
  YouHaveBeenOffered,
  YouHaveCancelled,
  YouHaveCompleted,
  YouHaveDecreasedYourTotalPotential,
  YouHaveImprovedYourTotalPotential,
  YouHaveLicense,
  YouHaveLicensePlan,
  YouHaveNoAvailableMeetingTimes,
  YouHaveNoUnreadMessages,
  YouHaveNoUpcomingMeetingsBooked,
  YouHaveNotChangedAnyInputs,
  YouHaveNotChangedCurrentState,
  YouHaveNotChangedDesiredState,
  YouHaveNotCompletedAnyMeasurement,
  YouHaveNotCreatedAnAccountYet,
  YouHaveNotHadAnyMeetingsYet,
  YouHaveToAgreeBeforeYouCanContinue,
  YouHaveUnreadMessages,
  YouHaveWorkedThrough,
  YouMissedTheMeeting,
  YouMustSelectOneAreaToBeCoached,
  YouMustSelectOneAreaToCoach,
  YouMustSelectOneLanguage,
  YouNeed,
  YouNeedToEnterALicenseCode,
  YouNeedToFeelGood,
  YouOfferTheClientAnotherMeeting,
  YourMeetingIsBooked,
  YouSeek,
  YouWant, 
  YouWantTo,
  YouWantToIncrease,
  YouWillBeMatchedWithACoachBasedOnTheAreas,
  Your,
  YourAnalysisResult,
  YourBehaviourProfile,
  YourBookings,
  YourChatCoach,
  YourAICoach,
  YourClients,
  YourCoach,
  YourCoachAreas,
  YourCoachRepliesThrice,
  YourCurrentState,
  YourDesiredState,
  YourDetails,
  YourEmployer,
  YourEmployerCannotSeeYourAnswersEither,
  YourLicense,
  YourLicenseDoesNotIncludeCoachMeetings,
  YourLicenseIsConnected,
  YourMeasurements,
  YourName,
  YourOverview,
  YourPreviousCoaches,
  YourPreviousMeasurements,
  YourProfile,
  YourProfileIsSaved12Months,
  YourProgram,
  YourProgramIntro,
  YourPurchaseIsBeingProcessed,
  YourSuggestion,
  YourSuggestionTo,
  Yours,
  YourStartState,
  YourTotalPotentialIsUnchanged,
  YourTotalPotentialWith,
  YouveGotAccessToEverything,
  YvonneAgazarinsModel,
  ZebrainLegal,
  ZebrainsClientsCanMeasureTheirEffectOfCoaching,
  ZebrainsMethodAreas,
  ZebrainsOwnMethod,
  ZebrainsReference1,
  ZebrainsReference2,
  ZebrainsReference3,
  ZebrainsReference4,
  ZebrainsTestMethods,
  ZebrainsUniqueMeasurementOfPotential,
  ZebrainsUniqueMeasurementOfPotentialContainsFourFactors,
}

type TrData = {
  [key: string]: string
}

type TrInterface = {
  [key in TrVar]: TrData
}

export const TrTxt: TrInterface = {
  [TrVar.ABitBehind]: {
    en: 'A bit behind',
    sv: 'Lite efter'
  },
  [TrVar.About]: {
    en: 'About',
    sv: 'Om'
  },
  [TrVar.AboutBehaviourAlgorithm]: {
    en: 'About our behavioural algorithm',
    sv: 'Om vår beteendealgoritm'
  },
  [TrVar.AboutChatCoaching]: {
    en: 'About chat coaching',
    sv: 'Om chattcoaching'
  },
  [TrVar.AboutCoaching]: {
    en: 'About coaching',
    sv: 'Om coaching'
  },
  [TrVar.AboutCoachingThroughChat]: {
    en: 'About coaching through chat',
    sv: 'Om coaching via chatt'
  },
  [TrVar.AboutOurPotentialMeasurement]: {
    en: `About our Potential Measurement`,
    sv: `Om vår potentialmätning`
  },
  [TrVar.AboutOurPotentialMeasurementSub1]: {
    en: `In Zebrain's unique Potential Measurement, we measure your 
                    unused potential by mapping the difference between
                    your current state and desired state. Current
                    state means your current situation, while desired
                    state is the target image of where you wish to be.`,
    sv: `I Zebrains unika Potentialmätning mäter vi din oanvända 
                    potential genom att kartlägga skillnaden mellan 
                    dina nulägen och önskade lägen. Nuläge innebär 
                    din nuvarande situation medan önskat läge är 
                    målbilden för dit du önskar nå.`
  },
  [TrVar.AboutOurPotentialMeasurementSub2]: {
    en: `In the measurement, you answer questions in four areas: Self-insight, 
                    Basic energy, Motivation and Self-leadership. These
                    areas lay the foundation for potential and our ability
                    to use it. The fact that we measure potential in
                    these four areas is based on extensive research,
                    within each area, and our own long practical
                    experience.`,
    sv: `I mätningen svarar du på frågor inom fyra områden: Självinsikt, 
                    Grundenergi, Motivation och Självledarskap. Dessa 
                    områden är var för sig och tillsammans avgöranden 
                    byggstenar för att vi ska kunna använda hela vår 
                    potential. Att vi mäter potential inom just dessa 
                    fyra områden är baserat på omfattande forskning 
                    inom respektive område och vår egen långa 
                    praktiska erfarenhet.`
  },
  [TrVar.AboutPotential]: {
    en: 'About potential',
    sv: 'Om potential'
  },
  [TrVar.AboutPotentialReference1]: {
    en: `D’Intino, R. S., Goldsby, M. G., Houghton, J. D. & Neck, C. P. Self-Leadership: A Process for Entrepreneurial Success. J. Leadersh. Organ. Stud. 13, 105–120 (2007).`,
    sv: `D’Intino, R. S., Goldsby, M. G., Houghton, J. D. & Neck, C. P. Self-Leadership: A Process for Entrepreneurial Success. J. Leadersh. Organ. Stud. 13, 105–120 (2007).`
  },
  [TrVar.AboutPotentialReference2]: {
    en: `Manz, C. C. Mastering self-leadership: empowering yourself for personal excellence. (Prentice Hall, 1992).`,
    sv: `Manz, C. C. Mastering self-leadership: empowering yourself for personal excellence. (Prentice Hall, 1992).`
  },
  [TrVar.AboutPotentialReference3]: {
    en: `Neck, C. P. & Houghton, J. D. Two decades of self‐leadership theory and research: Past developments, present trends, and future possibilities. J. Manag. Psychol. 21, 270–295 (2006).`,
    sv: `Neck, C. P. & Houghton, J. D. Two decades of self‐leadership theory and research: Past developments, present trends, and future possibilities. J. Manag. Psychol. 21, 270–295 (2006).`
  },
  [TrVar.AboutPotentialReference4]: {
    en: `Manz, C. C. Self-leadership: Toward an expanded theory of self-influence processes in organizations. Acad. Manage. Rev. 11, 585–600 (1986).`,
    sv: `Manz, C. C. Self-leadership: Toward an expanded theory of self-influence processes in organizations. Acad. Manage. Rev. 11, 585–600 (1986).`
  },
  [TrVar.AboutPotentialReference5]: {
    en: `Manz, C. C. & Sims, H. P. SuperLeadership: Beyond the myth of heroic leadership. Organ. Dyn. 19, 18–35 (1991).`,
    sv: `Manz, C. C. & Sims, H. P. SuperLeadership: Beyond the myth of heroic leadership. Organ. Dyn. 19, 18–35 (1991).`
  },
  [TrVar.AboutPotentialReference6]: {
    en: `DiLiello, T. C. & Houghton, J. D. Maximizing organizational leadership capacity for the future: Toward a model of self‐leadership, innovation and creativity. J. Manag. Psychol. 21, 319–337 (2006).`,
    sv: `DiLiello, T. C. & Houghton, J. D. Maximizing organizational leadership capacity for the future: Toward a model of self‐leadership, innovation and creativity. J. Manag. Psychol. 21, 319–337 (2006).`
  },
  [TrVar.AboutPotentialReference7]: {
    en: `Bäcklander, G. Autonomous, yet Aligned: Challenges of Self-Leadership in Context. (KTH Royal Institute of Technology, 2019).`,
    sv: `Bäcklander, G. Autonomous, yet Aligned: Challenges of Self-Leadership in Context. (KTH Royal Institute of Technology, 2019).`
  },
  [TrVar.AboutPotentialReference8]: {
    en: `Marques-Quinteiro, P. & Curral, L. A. Goal Orientation and Work Role Performance: Predicting Adaptive and Proactive Work Role Performance Through Self-Leadership Strategies. J. Psychol. 146, 559–577 (2012).`,
    sv: `Marques-Quinteiro, P. & Curral, L. A. Goal Orientation and Work Role Performance: Predicting Adaptive and Proactive Work Role Performance Through Self-Leadership Strategies. J. Psychol. 146, 559–577 (2012).`
  },
  [TrVar.AboutTotalPotential]: {
    en: 'Total potential',
    sv: 'Total potential'
  },
  [TrVar.AboutTotalPotentialSub1]: {
    en: `Total potential is our model that explains how the four 
                    areas Self-insight, Basic energy, Motivation
                    and Self-leadership interact and relate to
                    each other. If we use our full potential in
                    these areas, we have achieved total potential.
                    When we do that, we can both perform at our
                    peak and at the same time feel our best.`,
    sv: `Total potential är vår modell för att förklara hur de fyra 
                    områdena Självinsikt, Grundenergi, Motivation 
                    och Självledarskap samspelar och förhåller 
                    sig till varandra. Om vi använder hela vår 
                    potential inom dessa områden har vi uppnått 
                    total potential. När vi gör det kan vi både 
                    prestera på topp och samtidigt må riktigt 
                    bra.`
  },
  [TrVar.AboutYou]: {
    en: 'About you',
    sv: 'Om dig'
  },
  [TrVar.AboutYourCoaching]: {
    en: 'About your coaching',
    sv: 'Om din coaching'
  },
  [TrVar.AboveDesiredState]: {
    en: 'Above your desired state',
    sv: 'Över ditt önskade läge'
  },
  [TrVar.Accept]: {
    en: 'Accept',
    sv: 'Acceptera'
  },
  [TrVar.AcceptedByYou]: {
    en: 'Accepted by you',
    sv: 'Accepterat av dig'
  },
  [TrVar.AcceptGDPR]: {
    en: 'I accept that Zebrain stores my personal information according to GDPR.',
    sv: 'Jag godkänner att Zebrain lagrar mina personuppgifter enligt GDPR.'
  },
  [TrVar.AcceptStorePersonalInfo]: {
    en: 'I accept that Zebrain stores my personal information.',
    sv: 'Vänligen godkänn att Zebrain lagrar dina personuppgifter'
  },
  [TrVar.Access]: {
    en: 'Access',
    sv: 'Access'
  },
  [TrVar.AccessBasic]: {
    en: 'Access Basic',
    sv: 'Access Basic'
  },
  [TrVar.AccessPrivate]: {
    en: 'Access private',
    sv: 'Access privat'
  },
  [TrVar.AccountInactive]: {
    en: 'Your account is inactive.',
    sv: 'Ditt konto är inaktivt.'
  },
  [TrVar.AchieveBalanceInYourLifeThroughCoaching]: {
    en: 'Achieve balance in your life through coaching',
    sv: 'Få balans i livet med hjälp av coaching'
  },
  [TrVar.AClientThatIsAboutToBookAFortyFiveMinuteMeeting]: {
    en: `A client that is about to book a 45-minute meeting in the meeting block 09:00 - 12:00 will get
                  the suggestions:`,
    sv: `En klient som ska boka ett 45-minutersmöte i mötesblocket 09:00 - 12:00 kommer få förslagen:`
  },
  [TrVar.Active]: {
    en: 'Active',
    sv: 'Aktiv'
  },
  [TrVar.AddAvailableTime]: {
    en: 'Add available time',
    sv: 'Addera tillgänglig tid'
  },
  [TrVar.AddCalendar]: {
    en: 'Add calendar',
    sv: 'Lägg till kalender'
  },
  [TrVar.AddDescriptionsOfYourCoachProfileInTheLanguagesYourClientsWant]: {
    en: `Choose language for your profile description
                    Add descriptive texts about you as a coach in the language(s) you want the 
                    client to see about you when matching. If you want to write your presentation in a language other 
                    than the languages you want to coach in, select the correct language in the list below. For example, 
                    you can coach in Swedish, Danish and
                    German but have a description of you in English.`,
    sv: `Lägg till beskrivande texter av dig som coach på det eller de språk du vill coacha dina 
                    klienter på. Vill du skriva din presentation på ett annat språk än de språk du vill coacha på, väljer du rätt språk i listan under.`
  },
  [TrVar.AdditionalInformation]: {
    en: 'Additional information',
    sv: 'Ytterligare information'
  },
  [TrVar.AddLanguage]: {
    en: 'Add language',
    sv: 'Lägg till språk'
  },
  [TrVar.AddMeetingTimes]: {
    en: 'Add more meeting times unless you have already done so.',
    sv: 'Fyll på med fler mötestider om du inte redan gjort det.'
  },
  [TrVar.AddMeetings]: {
    en: 'Add meeting times',
    sv: 'Lägg till mötestider'
  },  
  [TrVar.Address]: {
    en: 'Address',
    sv: 'Adress'
  },
  [TrVar.Age]: {
    en: 'Age',
    sv: 'Ålder'
  },
  [TrVar.Agreement]: {
    en: 'Agreement',
    sv: 'Överenskommelse'
  },
  [TrVar.AiBetaVersion]: {
    en: 'AI Emma is a beta version and is still being trained in the coaching methodology.',
    sv: 'AI Emma är en betaversion och tränas fortfarande inom coachingmetodiken.'
  },
  [TrVar.AiIntro]: {
    en: 'Hi! My name is Emma, and I\'m an AI coach. Nice to meet you! What would you like to work with today?',
    sv: 'Hej! Jag heter Emma och är din AI-coach. Trevligt att träffa dig!. Vad vill du prata om idag?'
  },
  [TrVar.All]: {
    en: 'All',
    sv: 'Allt'
  },
  [TrVar.AllClients]: {
    en: 'All clients',
    sv: 'Alla klienter'
  },
  [TrVar.AllCoachingAreas]: {
    en: 'All coaching areas',
    sv: 'Alla coachingområden'
  },
  [TrVar.AllDevelopmentAreas]: {
    en: 'All areas of development',
    sv: 'Alla utvecklingsområden'
  },
  [TrVar.AllExercises]: {
    en: 'All exercies',
    sv: 'Alla övningar'
  },
  [TrVar.AllMeetingsBooked]: {
    en: 'You cannot book new meetings. You have booked all your meetings.',
    sv: 'Du kan inte boka nya möten. Du har bokat alla dina möten.'
  },
  [TrVar.AlreadyCreatedAccount]: {
    en: 'Already created account?',
    sv: 'Har du redan skapat konto?'
  },
  [TrVar.AlsoNeeds]: {
    en: 'Also needs',
    sv: 'Behöver också'
  },
  [TrVar.AlternativesForStatusOfTheMeeting]: {
    en: 'Alternatives for status of the meeting',
    sv: 'Alternativ för mötets status'
  },
  [TrVar.Amount]: {
    en: 'Amount',
    sv: 'Belopp'
  },
  [TrVar.AnalyzingBehaviour]: {
    en: 'Analysing behavioural profile...',
    sv: 'Analyserar beteendeprofil...'
  },
  [TrVar.AnalyzingResult]: {
    en: 'Analysing result...',
    sv: 'Analyserar resultat...'
  },  
  [TrVar.And]: {
    en: 'And',
    sv: 'Och'
  },
  [TrVar.AnonymisedReport]: {
    en: 'Anonymised report',
    sv: 'Anonymiserad rapport'
  },
  [TrVar.AnotherSessionIsAlreadyStarted]: {
    en: 'Another login session is already started. Try again',
    sv: 'En annan session är redan startad. Försök igen'
  },
  [TrVar.AnswerMissing]: {
    en: 'Answer missing',
    sv: 'Svar saknas'
  },
  [TrVar.AnswerQuestions]: {
    en: 'Answer questions',
    sv: 'Besvara frågor'
  },
  [TrVar.AnswerYourFollowUp]: {
    en: 'Answer your follow-up',
    sv: 'Svara på uppföljning'
  },
  [TrVar.Archive]: {
    en: 'Archive',
    sv: 'Arkiv'
  },
  [TrVar.Archived]: {
    en: '(Archived chat)',
    sv: '(Arkiverad chatt)'
  },
  [TrVar.AreasOfDevelopment]: {
    en: 'Areas of development',
    sv: 'Utvecklingsområden'
  },
  [TrVar.AreTheConversationsBetweenMyCoachAndISaved]: {
    en: 'Are the conversations between my coach and I being saved',
    sv: 'Sparas konversationen mellan mig och min coach?'
  },
  [TrVar.AreYouSureYouWantToContinue]: {
    en: 'Are you sure you want to continue?',
    sv: 'Är du säker på att du vill fortsätta?'
  },
  [TrVar.AreYouSureYouWantToRemove]: {
    en: 'Are you sure you want to remove',
    sv: 'Är du säker på att du vill ta bort'
  },
  [TrVar.AskUs]: {
    en: 'Ask us',
    sv: 'Fråga oss'
  },
  [TrVar.AttachedFiles]: {
    en: 'Attached files',
    sv: 'Bifogade filer'
  },
  [TrVar.AtLeast10Characters]: {
    en: 'At least 10 characters',
    sv: 'Minst 10 tecken'
  },
  [TrVar.AtTime]: {
    en: 'Time',
    sv: 'Klockan'
  },
  [TrVar.AtZebrain]: {
    en: '@zebraincoaching',
    sv: '@zebraincoaching'
  },
  [TrVar.Available]: {
    en: 'Available',
    sv: 'Ledig'
  },
  [TrVar.AvailableTimes]: {
    en: 'Available times',
    sv: 'Lediga tider'
  },
  [TrVar.Awesome]: {
    en: 'Awesome',
    sv: 'Härligt'
  },
  [TrVar.Back]: {
    en: 'Back',
    sv: 'Tillbaka'
  },
  [TrVar.BackToLoginPage]: {
    en: 'Back to login page',
    sv: 'Tillbaka till inloggning'
  },
  [TrVar.Background]: {
    en: 'Background',
    sv: 'Bakgrund'
  },
  [TrVar.BankAccount]: {
    en: 'Bank account',
    sv: 'Bank / postgiro'
  },
  [TrVar.BankId]: {
    en: 'BankID',
    sv: 'BankID'
  },
  [TrVar.BankIdCompleteTimeout]: {
    en: 'It took too long to complete the BankID process',
    sv: 'Det tog för lång tid att slutföra BankID'
  },
  [TrVar.BankIdError]: {
    en: 'It was not possible to login with BankID',
    sv: 'Det gick inte att logga in med BankID'
  },
  [TrVar.BankIdMobile]: {
    en: 'Mobile BankID',
    sv: 'Mobilt BankID'
  },
  [TrVar.BankIdOnThisDevice]: {
    en: 'BankID on this device',
    sv: 'BankID på den här enheten'
  },
  [TrVar.BankIdStartTimeout]: {
    en: 'It took too long for BankID to start',
    sv: 'Det tog för lång tid att öppna BankID'
  },
  [TrVar.BankIdWithPersonNumber]: {
    en: 'BankID with social security number',
    sv: 'BankID med personnummer'
  },  
  [TrVar.BasicEnergy]: {
    en: `Basic energy`,
    sv: 'Grundenergi'
  },
  [TrVar.BasicEnergySub1]: {
    en: `Our basic energy determines our capacity to meet challenges and 
                  achieve our goals. The better we feel, the greater our
                  capacity.`,
    sv: `Vår grundenergi avgör vilken kapacitet vi har att möta utmaningar 
                  och uppnå våra mål. Ju bättre vi mår, desto större blir 
                  vår kapacitet.`
  },
  [TrVar.BasicEnergySub2]: {
    en: `To increase our basic energy, it is important to identify the 
                  factors that consumes energy in our daily lives. The
                  most common factor is long-term stress. Stress itself
                  is not harmful for short periods - our bodies are
                  designed to handle temporary surges of adrenaline.
                  However, long-term stress and lack of recovery damages 
                  the body and our mental capacity. To manage stress and 
                  increase stress tolerance in our daily lives, it is 
                  important to identify what causes stress.`,
    sv: `För att öka grundenergin är det viktigt att identifiera vilka 
                  faktorer som stjäl energi i vår vardag. Den vanligaste 
                  faktorn är långvarig stress. Stress i sig är inte 
                  skadligt i kortare perioder  - våra kroppar är skapade 
                  för tillfälliga adrenalinpåslag. Långvarig stress och 
                  brist på återhämtning gör att kroppen och vår mentala 
                  kapacitet tar skada. För att hantera stress och öka 
                  stresstoleransen i vår vardag är det viktigt att 
                  identifiera vad som orsakar stress`
  },
  [TrVar.BasicEnergySub3]: {
    en: `The impact of long-term stress is well documented in research and 
                  concerns both personal health and effects on working life.`,
    sv: `Effekter av långvarig stress är väl dokumenterat i forskningen och 
                  gäller såväl den egna hälsan som effekter på arbetslivet.`
  },
  [TrVar.BasicEnergySub4]: {
    en: `Our measurement of stress is based on the questionnaires used in 
                  research and treatment both in Sweden and abroad,
                  including KEDS, Karolinska Exhaustion Disorder Scale.`,
    sv: `Vår mätning av stress bygger på de frågeformulär som används inom 
                  forskning och behandlingar både i Sverige och utomlands, 
                  bl.a KEDS, Karolinska Exhaustion Disorder Scale.`
  },
  [TrVar.BasicEnergyIsTheAbsenceOfHarmfulStress]: {
    en: `With basic energy, we mean the absence of harmful stress in our lives. Stress in its
                  own is not harmful and our bodies are well equipped to handle stress during shorter
                  time periods. However, we need every day rest and recovery to not risk both our
                  body and our mental capacity. Our test is built on the stress
                  tests that are used within research and treatments both in Sweden and abroad,
                  amongst others KEDS, Karolinska Exhaustion Disorder Scale. The effects of negative
                  stress are well documented in the research and applies both to your own health
                  as well as effects on your work.
                  `,
    sv: `Med grundenergi menar vi frånvaron av skadlig stress i våra liv. Stress i sig är
                  inte skadligt och våra kroppar är väl rustade för att hantera det under kortare
                  perioder. Men varje dag behöver vi vila och återhämtning för att inte riskera
                  att både kroppen och vår mentala kapacitet tar skada. Vårt test bygger på de
                  stresstester som används inom forskning och behandlingar både i Sverige och
                  utomlands, bl.a KEDS, Karolinska Exhaustion Disorder Scale. Effekter av negativ
                  stress är väl dokumenterat i forskningen och gäller såväl den egna hälsan som
                  effekter på arbetet.
                  `
  },
  [TrVar.BeANeutralPartyWhoWillGuideYou]: {
    en: `Be a neutral party, who will guide you through the process and keep the structure.`,
    sv: 'Vara en neutral part, som leder dig genom processen och håller i strukturen.'
  },
  [TrVar.Behaviour]: {
    en: `Behaviour`,
    sv: 'Beteende'
  },
  [TrVar.BehaviourAnalysis]: {
    en: `Behaviour analysis`,
    sv: 'Beteendeanalys'
  },
  [TrVar.BehaviourProfile]: {
    en: `Behavioural analysis`,
    sv: 'Beteendeprofil'
  },
  [TrVar.BeInCharge]: {
    en: 'To be in charge.',
    sv: 'Få bestämma.'
  },
  [TrVar.BeingAwareOfYourNeeds]: {
    en: `Being aware of your needs, drives and limitations and being able to act on them.`,
    sv: 'Att vara medveten om och kunna agera efter sina behov, drivkrafter och begränsningar.'
  },
  [TrVar.BeingCoachAtZebrain]: {
    en: 'Being a coach at Zebrain',
    sv: 'Att vara coach på Zebrain'
  },
  [TrVar.BeingInControl]: {
    en: 'Being in control',
    sv: 'Att få bestämma själv'
  },
  [TrVar.BeMotivatedAndPreparedToDoTheWorkRequiredToAchieveYourGoals]: {
    en: 'Be motivated and prepared to do the work required to achieve your goals.',
    sv: 'Vara motiverad och beredd att göra det arbete som krävs för att nå sina mål.'
  },
  [TrVar.BeResponsibleForActingInTheDirectionTowardsYourDesiredSituation]: {
    en: 'Be responsible for acting in the direction towards your desired situation.',
    sv: 'Vara ansvarig för att agera i riktning mot sitt önskade läge.'
  },
  [TrVar.BestRegardsTeamZebrain]: {
    en: 'Best regards Team Zebrain',
    sv: 'Vänligen Team Zebrain'
  },
  [TrVar.BetaVersion]: {
    en: 'Beta version',
    sv: 'Betaversion'
  },
  [TrVar.Between]: {
    en: 'Between',
    sv: 'Mellan'
  },
  [TrVar.BigFive]: {
    en: 'Big Five',
    sv: 'Big Five'
  },
  [TrVar.BigFiveWithASixthFactor]: {
    en: 'Big Five with a sixth factor, Honesty and Humanity.',
    sv: 'Big Five med en sjätte faktor, Ärlighet och Mänsklighet.'
  },
  [TrVar.BirgerJarlsgatan34]: {
    en: 'Birger Jarlsgatan 34',
    sv: 'Birger Jarlsgatan 34'
  },
  [TrVar.Birthday]: {
    en: 'Date of birth',
    sv: 'Födelsedatum'
  },
  [TrVar.Book]: {
    en: 'Book',
    sv: 'Boka'
  },
  [TrVar.BookAMeetingLater]: {
    en: 'Book a meeting later',
    sv: 'Boka möte senare'
  },
  [TrVar.BookAMeetingWithYourCoach]: {
    en: 'Book a meeting with your coach',
    sv: 'Boka möte med din coach'
  },
  [TrVar.Booked]: {
    en: 'Booked',
    sv: 'Bokad'
  },
  [TrVar.BookMeeting]: {
    en: 'Book meeting',
    sv: 'Boka möte'
  },
  [TrVar.BookMoreMeetings]: {
    en: 'Book more meetings',
    sv: 'Boka fler möten'
  },
  [TrVar.BookNewMeeting]: {
    en: 'Book new meeting',
    sv: 'Boka nytt möte'
  },
  [TrVar.Boosting]: {
    en: 'Boosting',
    sv: 'Engagerande'
  },
  [TrVar.BoostingBehaviour]: {
    en: 'Boosting behaviour',
    sv: 'Boosting behaviour'
  },
  [TrVar.BoostingDesc]: {
    en: 'With this behaviour we want to influence others with strong and positive experiences. We are creative, spontaneous and uninterested in details. We spread positive energy and see opportunities where others see obstacles.',
    sv: 'Med detta beteende vill vi påverka andra med starka och positiva upplevelser. Vi är kreativa, spontana och ointresserade av detaljer. Vi sprider positiv energi och ser möjligheter där andra ser hinder.'
  },
  [TrVar.BoostYourCareerThroughCoaching]: {
    en: 'Boost your career through coaching',
    sv: 'Boosta din karriär med coaching'
  },
  [TrVar.BoostYourSelfEsteemWithCoaching]: {
    en: 'Boost your self-esteem with coaching',
    sv: 'Öka din självkänsla med coaching'
  },
  [TrVar.ByBasicEnergyWeMeanTheAbsenceOfHarmfulStressInOurLives]: {
    en: `By basic energy we mean the absence of harmful stress in our lives. Stress in itself is not harmful
                      and our bodies are well equipped to deal with it for shorter periods, but every day we need time for
                      rest and recovery. Otherwise we risk damaging our body and our mental capacity. Our test is based on
                      the stress tests used in research and treatments both in Sweden and abroad. Among others: KEDS and
                      Karolinska Exhaustion Disorder Scale. Effects of negative stress are well documented in research
                      and concern personal health as well as work performance.`,
    sv: `Med grundenergi menar vi frånvaron av skadlig stress i våra liv. Stress i sig är inte skadligt och våra
                      kroppar är väl rustade för att hantera det under kortare perioder. Men varje dag behöver vi vila och
                      återhämtning annars riskerar vi att både kroppen och vår mentala kapacitet tar skada. Vårt test bygger på
                      de stresstester som används inom forskning och behandlingar både i Sverige och utomlands. Bl.a KEDS,
                      Karolinska Exhaustion Disorder Scale, Effekter av negativ stress är väl dokumenterat i forskningen och
                      gäller såväl den egna hälsan som effekter på arbetet.`
  },
  [TrVar.ByContinuouslyTakingNewPotentialMeasurements]: {
    en: `By continuously taking new potential measurements, you will clearly see your change over time. 
                      We therefore recommend that you measure your potential once every month. You do not need
                      to redo the entire assessment. It is enough to simply make adjustments 
                      in the areas where you feel you have changed.
`,
    sv: `Genom att kontinuerligt göra nya potentialmätningar kommer du tydligt se din förändring över tid. Vi rekommenderar att du 
                      gör en ny mätning varje månad. Du behöver inte göra om hela mätningen. Det räcker med att du ändrar de reglage 
                      där du upplever en förändring.`
  },
  [TrVar.CalculatingDevelopmentAreas]: {
    en: 'Calculating areas of development...',
    sv: 'Räknar ut utvecklingsområden...'
  },
  [TrVar.CalculatingExercises]: {
    en: 'Calculating exercises...',
    sv: 'Räknar ut övningar...'
  },  
  [TrVar.CalendarChangeTime]: {
    en: 'Go to the calendar and choose a new time',
    sv: 'Gå till kalendern och välj en ny tid'
  },
  [TrVar.CanAlsoBe]: {
    en: 'Can also be',
    sv: 'Kan också vara'
  },
  [TrVar.CanBookLater]: {
    en: `If you can't find a suitable time you can try to book later`,
    sv: 'Om du inte hittar en tid som passar kan du prova att boka vid ett senare tillfälle'
  },
  [TrVar.Cancel]: {
    en: 'Cancel',
    sv: 'Avbryt'
  },
  [TrVar.Cancelled]: {
    en: 'Cancelled',
    sv: 'Avbruten'
  },
  [TrVar.CancelMeeting]: {
    en: 'Cancel meeting',
    sv: 'Avboka möte'
  },
  [TrVar.CancelMeetingExplanation]: {
    en: 'Here you can explain why you cancelled',
    sv: 'Här kan du skriva varför du avbokade'
  },
  [TrVar.CanIReturnToMyToMyChatCoachAfterReceivingCoaching]: {
    en: 'Can I return to my chat coach after receiving coaching via video from another coach?',
    sv: 'Kan jag få tillbaka coachen jag chattar med efter att jag fått coaching via video av en annan coach?'
  },
  [TrVar.CannotBeBooked]: {
    en: 'Cannot be booked',
    sv: 'Kan ej bokas'
  },
  [TrVar.CannotBookMeetings]: {
    en: `You cannot book new meetings.`,
    sv: 'Du kan inte boka nya möten.'
  },  
  [TrVar.CannotBookMeetingsAfter]: {
    en: 'You cannot book meetings after',
    sv: 'Du kan inte boka möten senare än'
  },
  [TrVar.CannotFindAccount]: {
    en: `We cannot find your account`,
    sv: 'Vi hittar inte ditt konto.'
  },
  [TrVar.CannotReschedule]: {
    en: 'Mötet startar inom 24 timmar, så du kan inte boka om detta möte.',
    sv: 'The meeting starts within 24 hours, you cannot reschedule this meeting.'
  },  
  [TrVar.CannotWriteMessages]: {
    en: `You cannot write new messages.`,
    sv: 'Du kan inte skriva nya meddelanden.'
  },  
  [TrVar.CertificateError]: {
    en: 'Choose a coach certificate',
    sv: 'Välj ett coachcertifikat'
  },
  [TrVar.CertifiedSince]: {
    en: `Certified since`,
    sv: 'Certifierad sedan'
  },
  [TrVar.Change]: {
    en: 'Change',
    sv: 'Ändra'
  },  
  [TrVar.ChangeBooking]: {
    en: 'Change booking',
    sv: 'Ändra bokning'
  },
  [TrVar.ChangeChat]: {
    en: 'Change chat',
    sv: 'Byt chatt'
  },  
  [TrVar.ChangeInCurrentState]: {
    en: 'Change in current state',
    sv: 'Ändring i nuläge'
  },
  [TrVar.CustomerFramework]: {
    en: 'Employer framework',
    sv: 'Ramverk från arbetsgivare'
  },
  [TrVar.ChangeLoginMethod]: {
    en: 'Change login method',
    sv: 'Byt inloggningssätt'
  },  
  [TrVar.ChangeMeeting]: {
    en: 'Do you want to change meeting time?',
    sv: 'Vill du ändra mötestid?'
  },  
  [TrVar.ChangeMeetingBlock]: {
    en: `Change meeting block`,
    sv: 'Ändra mötesblock'
  },
  [TrVar.ChangeProfilePicture]: {
    en: `Change profile picture`,
    sv: 'Byt profilbild'
  },
  [TrVar.ChangeUnder]: {
    en: `Change under`,
    sv: 'Ändra under'
  },
  [TrVar.Chat]: {
    en: `Chat`,
    sv: 'Chatt'
  },
  [TrVar.ChatCoaching]: {
    en: 'Chat coaching!',
    sv: 'Prova coaching via chatt!'
  },
  [TrVar.ChatCoachingWorksLikeThis]: {
    en: `Chat coaching is a text-based form of coaching where you write and ask your coach questions regularly. You can write frequently and brief or less frequently and more extensive - you decide the pace according to your preferences and needs. Either way, we recommend that you try to create a routine of when you write so that the coaching becomes consistent. Your coach will respond three times a week.`,
    sv: 'Coaching via chatt är en textbaserad form av coaching där du skriver med din coach. Du bestämmer själv hur ofta och hur mycket du vill skriva men oavsett så rekommenderar vi att du försöker få in en rutin för när du skriver så att coachingen blir regelbunden. Din coach svarar vid tre tillfällen per veckan.'
  },
  [TrVar.ChatToReschedule]: {
    en: `You can ask if your coach can reschedule the meeting`,
    sv: 'Du kan gå till chatten och fråga om din coach kan flytta mötet'
  },
  [TrVar.ChatRead]: {
    en: `Read`,
    sv: 'Läst'
  },
  [TrVar.ChatWith]: {
    en: `Chat with`,
    sv: 'Chatta med'
  },
  [TrVar.ChatWithYourCoach]: {
    en: `Chat with your coach`,
    sv: 'Chatta med din coach'
  },
  [TrVar.ChatWithYourCoachAndBookTheMeetingsEveryWeekInsteadOr]: {
    en: `Chat with your coach and book the meetings every week instead. Or
                              talk to your manager about extending your license.`,
    sv: 'Chatta med din coach och boka mötena till en gång i veckan istället. Eller prata med din chef om att förlänga licensen.'
  },  
  [TrVar.ChatWithYourCoachAndFindASuitableTime]: {
    en: `Chat with your coach, hopefully you can find a time that suits you both.`,
    sv: 'Chatta med din coach så kan ni säkert hitta en tid som passar er båda.'
  },
  [TrVar.Checkout]: {
    en: `Kassan`,
    sv: 'Checkout'
  },
  [TrVar.Choose]: {
    en: `Choose`,
    sv: 'Välj'
  },
  [TrVar.ChooseALanguageToWriteIn]: {
    en: 'Choose a language to write in',
    sv: 'Välj språk att skriva på'
  },
  [TrVar.ChooseANewPicture]: {
    en: `Choose a new picture`,
    sv: 'Välj ny bild'
  },
  [TrVar.ChooseAreaOfDevelopment]: {
    en: `Choose areas of development`,
    sv: 'Välj coachingområden'
  },
  [TrVar.ChooseAreaOfDevelopmentAndLanguages]: {
    en: `Choose areas of development and language`,
    sv: 'Välj coachingområden och språk'
  },
  [TrVar.ChooseClient]: {
    en: `Choose client`,
    sv: 'Välj klient'
  },
  [TrVar.ChooseFile]: {
    en: 'Choose file to upload',
    sv: 'Välj fil att ladda upp'
  },
  [TrVar.ChooseHowToLogin]: {
    en: `Choose how to login`,
    sv: 'Välj hur du vill logga in i framtiden'
  },
  [TrVar.ChooseInterest]: {
    en: 'Choose the areas that you want to develop within.',
    sv: 'Välj vilka områden du vill utvecklas inom.'
  },
  [TrVar.ChooseInterestingAreasOfDevelopment]: {
    en: 'Choose the areas you want to develop in.',
    sv: 'Välj de coachingområden du är intresserad av.'
  },
  [TrVar.ChooseLanguage]: {
    en: 'Choose language in the platform',
    sv: 'Välj språk i plattformen'
  },
  [TrVar.ChooseLanguageForYourDescription]: {
    en: 'Choose language for your coach description',
    sv: 'Välj språk för beskrivning av dig'
  },
  [TrVar.ChooseNewFile]: {
    en: 'Upload a new file',
    sv: 'Ladda upp ny fil'
  },
  [TrVar.ChooseOneOption]: {
    en: 'Choose one option',
    sv: 'Välj ett alternativ'
  },
  [TrVar.ChooseStatementAndPowerQuestions]: {
    en: 'Choose the statement below and work on your powerful questions.',
    sv: 'Välj påstående nedan och jobba med dina kraftfulla frågor.'
  },
  [TrVar.ChooseTheLoginMethodYouUsedWhenYouCreatedYourAccount]: {
    en: `Choose the login method you used when your created your account.`,
    sv: 'Välj den inloggningsmetod du använde när du skapade ditt konto.'
  },
  [TrVar.ChooseYourCalendarBelow]: {
    en: `Choose your calendar below (if you have another calendar you can try one of the concepts below).`,
    sv: 'Välj din kalender nedan (har du annan kalender kan du prova dig fram - konceptet bör vara liknande).'
  },
  [TrVar.City]: {
    en: `City`,
    sv: 'Stad'
  },
  [TrVar.Clarity]: {
    en: `Clarity.`,
    sv: 'Tydlighet.'
  },  
  [TrVar.ClearFeedbackAndReflection]: {
    en: `Use self reflection to get clarity around unwanted behaviour to learn what works.  ***`,
    sv: `Tydlig egen feedback med korrigering vid oönskade beteende för att öka
                  medvetenheten om vad som fungerar och inte fungerar. Bestraffning i sig är
                  enbart negativt för prestationerna. ***`
  }, 
  [TrVar.ClickAddCalendar]: {
    en: `Click 'Add calendar'`,
    sv: `Klicka på 'Lägg till kalender'`
  },
  [TrVar.ClickOkayBelowThenYouGoToCalendar]: {
    en: `Click Ok below. Then you click in the calendar on the time you want to move the meeting to.`,
    sv: 'Klicka Ok nedan. Sedan klickar du i kalendern på den tid du vill flytta mötet till.'
  },
  [TrVar.ClickOnATimeInTheCalendarWhereYouWantToAddAMeetingSuggestion]: {
    en: `Click on a time in the calendar where you want to add a meeting suggestion to client.`,
    sv: 'Klicka på en tid i kalendern där du vill lägga till ett mötesförslag.'
  },
  [TrVar.ClickOnATimeInTheCalendarWhereYouWantToAddATimeInterval]: {
    en: `Click on a time in the calendar where you want to add a time interval where you can accept meetings.
                 You can then enter start and end time and save. The end time defines when the last meeting in the
                 meeting block shall be finished.`,
    sv: `Klicka på en tid i kalendern där du vill lägga till ett tidsintervall där du kan ta emot möten. Ange sedan
                 exakt starttid och sluttid och spara. Sluttiden anger den tid då sista mötet i mötesblocket
                 ska vara klart.`
  },
  [TrVar.ClickOnATimeInTheCalendarWhereYouWantToMoveTheClientMeeting]: {
    en: `Click on a time in the calendar where you want to move the client meeting.`,
    sv: 'Klicka på en tid i kalendern där du flytta mötet till.'
  },
  [TrVar.ClickOnImport]: {
    en: `Click 'Import'`,
    sv: `Klicka på 'Importera'`
  },  
  [TrVar.ClickOnTheColumnsHeaderToSort]: {
    en: `Click on the column's header to sort. Click again to reverse sorting.`,
    sv: 'Klicka på kolumnens rubrik för att sortera. Klicka igen för att sortera omvänt.'
  },
  [TrVar.ClickSubscribe]: {
    en: `Click 'Subscribe'`,
    sv: `Klicka på 'Prenumerera'`
  },
  [TrVar.ClickToSelectNewTimeWith]: {
    en: `Click to select new time with`,
    sv: 'Klicka för att välja ny tid för möte med'
  },
  [TrVar.ClickWhereYouWantToSuggestMeeting]: {
    en: `Click where you want to suggest meeting with`,
    sv: 'Klicka där du vill föreslå möte med'
  },
  [TrVar.Client]: {
    en: `Client`,
    sv: 'Klient'
  },
  [TrVar.ClientCancelledLate]: {
    en: `Client cancelled meeting late`,
    sv: 'Klienten avbokade mötet sent'
  },
  [TrVar.ClientCancelledLateOrDidNotShow]: {
    en: `Client cancelled too late, or did not show up in meeting`,
    sv: 'Klienten avbokade för sent, eller dök inte upp i mötet'
  },
  [TrVar.ClientDashboard]: {
    en: `Client's Dashboard`,
    sv: 'Klientens Dashboard'
  },
  [TrVar.ClientInfo]: {
    en: `Client info`,
    sv: 'Klientinfo'
  },
  [TrVar.ClientIsBehindSchedule]: {
    en: `Client is behind schedule. Please chat with client about increasing the meeting tempo`,
    sv: 'Klienten ligger efter. Chatta gärna med klienten om att öka takten'
  },
  [TrVar.ClientOnboardingIntro]: {
    en: 'Before we start with your onboarding, we kindly ask you to fill in your license code below so we know which one of our licenses you should have.',
    sv: 'Innan vi börjar med din onboarding ber vi dig att fylla i din licenskod nedan så vi vet vilken av våra licenser just du ska ha.'
  },
  [TrVar.ClientPotentialDevelopment]: {
    en: `Client's development since first measurement`,
    sv: 'Klientens utveckling sedan första mätningen'
  },
  [TrVar.ClientPotentialTasks]: {
    en: `Client's work with powerful questions`,
    sv: 'Klientens arbete med kraftfulla frågor'
  },
  [TrVar.ClientsCoachingAreas]: {
    en: `The client's coaching areas`,
    sv: 'Klientens coachingområden'
  },
  [TrVar.ClientSuggestion]: {
    en: `Client suggestion`,
    sv: 'Klientförslag'
  },
  [TrVar.ClientsWhoCanOnlyChat]: {
    en: `Clients who can only chat`,
    sv: 'Klienter som bara får chatta'
  },
  [TrVar.ClientsYouHaveCoachedBefore]: {
    en: `Clients you have coached before`,
    sv: 'Klienter du har coachat förut'
  },
  [TrVar.CloseChat]: {
    en: `Close chat`,
    sv: 'Stäng chat'
  },
  [TrVar.Coach]: {
    en: `Coach`,
    sv: 'Coach'
  },
  [TrVar.CoachAgreement]: {
    en: `Coaching agreement`,
    sv: 'Coachingöverenskommelse'
  },
  [TrVar.CoachAreas]: {
    en: `Coaching areas`,
    sv: 'Coachingområden'
  },
  [TrVar.CoachCancelMeetingWarning]: {
    en: 'NB! Meeting will be removed if you cancel here.',
    sv: 'Obs! Mötet tas bort helt om du avbokar här.'
  },
  [TrVar.CoachCertificate]: {
    en: `Coach certificate`,
    sv: 'Coachcertifikat'
  },
  [TrVar.CoachDescription]: {
    en: `Coach description`,
    sv: 'Om coachen'
  },
  [TrVar.CoachesThatMatchDesiredProfile]: {
    en: `Coaches with complete profiles that match Zebrain's and our clients' needs will be contacted by us for further steps in the process.`,
    sv: 'Coacher med kompletta profiler som matchar Zebrains och våra kunders behov kommer bli kontaktade av oss för fortsatta steg i processen.'
  },
  [TrVar.CoachExpertise]: {
    en: `Coach expertise`,
    sv: 'Coachexpertis'
  },
  [TrVar.CoachHasNoAvailableTimes]: {
    en: 'Your coach has no available times. Chat with your coach and you will hopefully find a time that suits you both.',
    sv: 'Din coach har tyvärr inte lagt upp några tider. Chatta med din coach så kan ni säkert hitta en tid som passar.'
  },
  [TrVar.CoachHours]: {
    en: 'Hours coached',
    sv: 'Arbetade coachtimmar'
  },
  [TrVar.Coaching]: {
    en: 'Coaching',
    sv: 'Coaching'
  },
  [TrVar.CoachingAreas]: {
    en: 'Areas of development',
    sv: 'Coachingområden'
  },
  [TrVar.CoachingIsAboutUnlockingYourFullPotentialByGuidingAndChallenging]: {
    en: 'Coaching is about unlocking your full potential by guiding and challenging you to find your own path towards change. A coach is a specialist at your development process, guiding you to new thoughts, perspectives and approaches, with the aim of increasing your self-awareness and releasing your full power. By helping you to set realistic and achievable goals, the coach guides you step by step towards your desired state.',
    sv: 'Coaching handlar om att frigöra hela din inneboende potential genom att vägleda och utmana dig att själv hitta vägen till förändring. En coach är specialist på din utvecklingsprocess den vägleder dig till nya tankar, perspektiv och förhållningssätt med målet att öka din medvetenhet om dig själv och frigöra din fulla kraft. Genom att hjälpa dig att sätta realistiska och genomförbara mål guidar coachen dig stegvis mot ditt önskade läge.'
  },
  [TrVar.CoachingIsAlsoAboutLookingForwardAndGuidingYou]: {
    en: 'Coaching is also about looking forward and guiding you to challenge yourself and change your current situation.',
    sv: 'Coaching handlar också om att blicka framåt genom att guida och utmana dig till en förändring av din nuvarande situation.'
  },
  [TrVar.CoachingLanguage]: {
    en: 'Coaching language',
    sv: 'Coaching språk'
  },
  [TrVar.CoachLanguages]: {
    en: 'Languages coach can coach in',
    sv: 'Språk coachen kan coacha i'
  },
  [TrVar.CoachMembership]: {
    en: `Coaching federation membership`,
    sv: 'Coachmedlemskap'
  },
  [TrVar.CoachMeasureLink]: {
    en: `You can see your client's measurements under "My clients"`,
    sv: 'Du kan se dina klienters mätningar under "Mina klienter"'
  },
  [TrVar.CoachNotes]: {
    en: `Coach notes`,
    sv: 'Coachanteckningar'
  },
  [TrVar.CoachOnboardingIntro1]: {
    en: 'First we would like to know what kind of coach you want to become at Zebrain',
    sv: 'Först vill vi veta vilken typ av coach du vill bli i Zebrain'
  },
  [TrVar.CoachOnboardingIntro2]: {
    en: 'If you are applying both as a Subscription Coach and an Inside Coach, then select the box for Subscription Coach and we will proceed to receive all the information we need.',
    sv: 'Om du söker som både Subscription Coach och Inside Coach, välj då rutan för Subscription så får vi in allt underlag vi behöver.'
  },
  [TrVar.CoachOnboardingInside]: {
    en: `If you want to use Zebrain's platform to coach your own clients`,
    sv: 'Om du vill använda Zebrains plattform för att coacha egna klienter'
  },
  [TrVar.CoachOnboardingSubscription]: {
    en: 'If you want to be part of Zebrain and match with our subscribed clients',
    sv: 'Om du vill vara en del av vår prenumerationslösning och få coachuppdrag av Zebrains klienter'
  },
  [TrVar.CoachSuggestion]: {
    en: `Coach suggestion`,
    sv: 'Coachförslag'
  },
  [TrVar.CoachTasksHeader]: {
    en: 'Powerful questions to help grow potential during and after coaching',
    sv: 'Kraftfulla frågor som stöd under och efter coachingen'
  },
  [TrVar.CoachTasksIntro]: {
    en: `The Powerful Questions (and in a few cases, suggestions for activities) appear to the client in cases where they have marked a certain distance/gap between the Current State and the Desired State. The purpose is to further strengthen and ensure a truly positive result of the coaching and the questions have strong support in extensive research. You can read more about the research under the heading "Total Potential" on your coaching page in Zebrain's platform. The factors that we use for the Powerful Questions are the same factors  as behind the measurements of Total Potential in the platform. As a coach, you will be able to see what issues your client sees and chooses to work with.`,
    sv: 'De Kraftfulla frågorna (och i några få fall, förslag på aktiviteter) dyker upp för klienten i de fall hen har markerat ett visst avstånd mellan Nuläge och Önskat läge. Syftet är att ytterligare stärka och säkerställa ett riktigt positivt resultat av coachingen och har starkt stöd i forskningen. Läs mer under rubriken ”Total Potential” på din coachsida i Zebrains plattform. Det är samma faktorer som ligger bakom mätningarna av Total potential i plattformen. Som coach kommer du kunna se vilka frågor klienten ser och väljer att arbeta med.'
  },
  [TrVar.CoachTasksLink]: {
    en: `You can see the progress of your client's work with Powerful Questions under "My clients"`,
    sv: 'Du kan följa dina klienters arbete med Kraftfulla frågor under "Mina klienter"'
  },
  [TrVar.CoachTasksStep1]: {
    en: 'Self-insight which is the basis for all development and success. Being aware of their strengths and understanding their driving forces as well as their obstacles is just as crucial for the company as for the individual.',
    sv: 'Självinsikt som är grunden till all utveckling och framgång. Att vara medveten om sina styrkor och förstå sina drivkrafter likaväl som sina hinder är lika avgörande för företaget som för den enskilda individen.'
  },
  [TrVar.CoachTasksStep2]: {
    en: 'Base energy refers to the absence of harmful stress in our lives. Stress in itself is not harmful and our bodies are well equipped to deal with it for shorter periods. But every day we need rest and recovery otherwise we risk that both the body and our mental capacity will be damaged.',
    sv: 'Grundenergi som avser frånvaron av skadlig stress i våra liv. Stress i sig är inte skadligt och våra kroppar är väl rustade för att hantera det under kortare perioder. Men varje dag behöver vi vila och återhämtning annars riskerar vi att både kroppen och vår mentala kapacitet tar skada.'
  },
  [TrVar.CoachTasksStep3]: {
    en: 'Motivation is about what we need, both to feel motivated and to be able to perform. Here we distinguish between:',
    sv: 'Motivation handlar om vad vi behöver både för att uppleva oss motiverade och kunna prestera. Där skiljer vi på:'
  },
  [TrVar.CoachTasksStep4]: {
    en: 'Self-leadership refers to the ability to take ourselves towards what is important to us. Self-insight, Base energy/ life balance and Motivation aim  towards us being able to effectively apply a strong Self-Leadership. It is crucial for coaching to provide optimal effects including ROI (Return Of Investment) and is well documented in research.',
    sv: 'Självledarskap avser förmågan att ta oss själva till det som är viktigt för oss. Självinsikt, Livsbalans och Motivation syftar till att effektivt kunna tillämpa ett effektivt Självledarskap. Det är avgörande för att coachingen skall ge optimala effekter inklusive ROI (Return Of Investment) och är väl dokumenterat i forskning.'
  },
  [TrVar.CoachTasksStep3Sub1]: {
    en: 'Extinguishing factors required for us not to be demotivated. They need to be fulfilled enough not to diminish our motivation: Clarity - Know what applies - Cooperation - Community – Relationships',
    sv: 'Släckarfaktorer som krävs för att vi inte skall bli demotiverade. De behöver vara tillräckligt uppfyllda för att inte dra ner vår motivation: Tydlighet - Veta vad som gäller – Samarbete – Gemenskap - Relationer'
  },
  [TrVar.CoachTasksStep3Sub2]: {
    en:'Ignition factors that increase our motivation the more we get of them: Meaningfulness - Interest - Get to decide - See for yourself that you succeed - Others point out that you succeed',
    sv: 'Tändarfaktorer som ökar vår motivation ju mer vi får av dom: Meningsfullhet – Intresse - Få bestämma - Se själv att man lyckas - Andra påtalar att man lyckas.'
  },
  [TrVar.CoachTasksSubheader1]: {
    en: 'Just like the Total Potential measurements, the Powerful Questions are divided into four steps:',
    sv: 'Precis som mätningarna av Total Potential så är De Kraftfulla frågorna indelade i fyra steg:'
  },
  [TrVar.CoachQuestionsDone]: {
    en: 'Coach questions done',
    sv: 'Coachfrågor gjorda'
  },
  [TrVar.ComingMeetings]: {
    en: 'Upcoming meetings',
    sv: 'Kommande möten'
  },
  [TrVar.Community]: {
    en: 'Community.',
    sv: 'Gemenskap.'
  },
  [TrVar.Company]: {
    en: 'Company',
    sv: 'Företag'
  },
  [TrVar.Compare]: {
    en: 'Compare all',
    sv: 'Jämför alla'
  },
  [TrVar.ComparedToPreviousMeasurement]: {
    en: 'Compared to previous measure',
    sv: 'Jämfört med föregående mätning'
  },
  [TrVar.Completed]: {
    en: 'Completed',
    sv: 'Färdiga'
  },
  [TrVar.ConductCoachingWIthTheHelpOfPowerfulQuestions]: {
    en: `Conduct coaching with the help of powerful questions based on the client's needs.`,
    sv: 'Driva coaching med hjälp av kraftfulla frågor utifrån klientens behov.'
  },
  [TrVar.Confirm]: {
    en: 'Confirm',
    sv: 'Bekräfta'
  },
  [TrVar.ConfirmMeeting]: {
    en: 'Confirm meeting',
    sv: 'Bekräfta möte'
  },
  [TrVar.ConfirmRemoveChat]: {
    en: 'Are you sure you want to remove this chat?',
    sv: 'Är du säker på att du vill ta bort chatten?'
  },
  [TrVar.Confirmed]: {
    en: 'Confirmed',
    sv: 'Bekräftat'
  },
  [TrVar.ConfirmedReschedule]: {
    en: 'I have confirmed this change with client',
    sv: 'Jag har stämt av denna ändring med klient'
  },
  [TrVar.CongratulationsYouGotLicense]: {
    en: 'Congratulations - you got the license',
    sv: 'Grattis - du har fått paketet'
  },
  [TrVar.ContactPerson]: {
    en: 'Contact person',
    sv: 'Kontaktperson'
  },
  [TrVar.ContactSupportZebrain]: {
    en: 'Contact support@zebrain.se and we will help you',
    sv: 'Kontakta support@zebrain.se så hjälper vi dig'
  },
  [TrVar.Contact]: {
    en: 'Contact',
    sv: 'Kontakt'
  },
  [TrVar.ContactSupport]: {
    en: 'Contact support',
    sv: 'Kontakta support'
  },
  [TrVar.ContactUs]: {
    en: 'Contact us',
    sv: 'Kontakta oss'
  },
  [TrVar.Contains]: {
    en: 'Contains',
    sv: 'Innehåller'
  },
  [TrVar.Content]: {
    en: 'Content',
    sv: 'Innehåll'
  },
  [TrVar.Continue]: {
    en: 'Continue',
    sv: 'Fortsätt'
  },
  [TrVar.ContinualMeasurementOfDevelopment]: {
    en: 'Continual measurements of your development',
    sv: 'Kontinuerlig mätning av din utveckling'
  },
  [TrVar.ContinuousFeedback]: {
    en: 'Get continuous feedback over an extended period of time. ****',
    sv: 'Kontinuerlig uppföljning under lång tid. ****'
  },
  [TrVar.ContributeToTheProcessByBeingActiveAndOpenToTheProcess]: {
    en: 'Contribute to the process by being active and open to the process.',
    sv: 'Bidra till processen genom att vara aktiv och öppen för processen.'
  },
  [TrVar.ConversationsAreSaved]: {
    en: 'Yes, they are saved even when you change your license. They will remain in the chat.',
    sv: 'Ja, den sparas även när du byter licens. Den ligger då kvar på sidan för chatten.'
  },
  [TrVar.Cooperation]: {
    en: 'Co-operation.',
    sv: 'Samarbete.'
  },
  [TrVar.CopyTheUrlBelow]: {
    en: 'Copy the url below',
    sv: 'Kopiera url:en nedan'
  },
  [TrVar.Correct]: {
    en: 'Correct',
    sv: 'Stämmer'
  },
  [TrVar.CouldNotFindLicense]: {
    en: 'Could not find this license',
    sv: 'Hittade inte denna licens'
  },
  [TrVar.CouldWeContactYourBoss]: {
    en: 'Could we speak to your boss?',
    sv: 'Skulle vi kunna få prata med din chef?'
  },
  [TrVar.CreateAccount]: {
    en: 'Create account',
    sv: 'Skapa konto'
  },
  [TrVar.CreateAccountBelow]: {
    en: 'Create account below to get started.',
    sv: 'Tryck på Skapa konto för att komma igång!'
  },
  [TrVar.CreateClarityOnYourGoals]: {
    en: 'Get clarity on your goals with positive feedback and see and positive change. **',
    sv: 'Tydlig egen positiv feedback och positiva konsekvenser. **'
  },
  [TrVar.CreateClearGoals]: {
    en: 'Create clear personal goals in relation with your affected surroundings. *',
    sv: 'Sätta egna tydliga mål i interaktion med berörd omgivning. *'
  },
  [TrVar.Created]: {
    en: 'Created',
    sv: 'Skapad'
  },
  [TrVar.CreateFree]: {
    en: 'Create free account',
    sv: 'Skapa gratis konto'
  },
  [TrVar.CreateFreeAccount]: {
    en: 'Create a free account',
    sv: 'Skapa ett gratis konto'
  },
  [TrVar.CrownsPerMonth]: {
    en: 'Sek / month',
    sv: 'Kr / månad'
  },
  [TrVar.CrucialFactors]: {
    en: 'Some crucial factors for success',
    sv: 'Några avgörande faktorer för att lyckas'
  },
  [TrVar.CurrentState]: {
    en: 'Current state',
    sv: 'Nuläge'
  },
  [TrVar.CustomnizedCoaching]: {
    en: 'Customized coaching based on your needs',
    sv: 'Anpassad coaching efter dina behov'
  },
  [TrVar.Date]: {
    en: 'Date',
    sv: 'Datum'
  },
  [TrVar.DateHasPassed]: {
    en: 'Date has passed',
    sv: 'Datumet har passerats'
  },
  [TrVar.DateOfBirthPlaceholder]: {
    en: `YYYYMMDD`,
    sv: `ÅÅÅÅMMDD`
  },
  [TrVar.DaysLeft]: {
    en: 'Days left',
    sv: 'Dagar kvar'
  },
  [TrVar.Decrease]: {
    en: 'Decrease',
    sv: 'Minska'
  },
  [TrVar.Decreased]: {
    en: 'Decreased',
    sv: 'Minskat'
  },
  [TrVar.DescriptionInformation]: {
    en: `Short summary, Emphasize your professional strengths and write about your 
                background - both as a coach but also if you have other valuable
                professional experience to highlight. For example, if you have
                worked in a certain industry or held managerial positions.`,
    sv: `Tryck på dina professionella styrkor och berätta om din bakgrund - både 
                som coach men även om du har andra värdefulla yrkeserfarenheter 
                att lyfta fram. Exempelvis om du har arbetat inom en viss industri 
                eller haft chefspositioner.`
  },
  [TrVar.DescriptionOfYouAsCoach]: {
    en: 'Description of you as a coach',
    sv: 'Beskrivning av dig som coach'
  },
  [TrVar.Desired]: {
    en: `Desired`,
    sv: 'Önskat'
  },
  [TrVar.DesiredState]: {
    en: `Desired state`,
    sv: 'Önskeläge'
  },
  [TrVar.DesiredStateOrGap]: {
    en: `Desired state or gap`,
    sv: 'Önskat läge eller gap'
  },
  [TrVar.DetailBehaviour]: {
    en: 'Detailed behaviour',
    sv: 'Detailed behaviour'
  },
  [TrVar.Detailed]: {
    en: 'Detailed',
    sv: 'Detaljerad'
  },
  [TrVar.DetailedBehaviour]: {
    en: 'About behavioural styles',
    sv: 'Om beteendestilar'
  },
  [TrVar.DetailedDesc]: {
    en: `Our detailed behaviour means that we are objective and meticulous so that what we do is right and correct. We set high standards for quality and take our time to make sure we don't miss a detail.`,
    sv: 'Vårt detaljerade beteende innebär att vi är sakliga och noggranna så att det vi ägnar oss åt blir rätt. Vi ställer höga kvar på kvalitet och tar gärna god tid på oss för att inte missa någon detalj.'
  },
  [TrVar.DevelopFasterWithChatCoach]: {
    en: 'Develop faster with a chat coach',
    sv: 'Utvecklas snabbare med chat-coach'
  },
  [TrVar.DevelopFasterWithCoach]: {
    en: 'Develop faster with a video coach',
    sv: 'Utvecklas snabbare med video-coach'
  },
  [TrVar.DevelopmentAreas]: {
    en: `Areas of development`,
    sv: 'Utvecklingsområden'
  },
  [TrVar.DevelopmentAreasBeforeCoach]: {
    en: 'First choose your development areas. Then you can choose coach.',
    sv: 'Börja med att välja dina utvecklingsområden. Sedan kan du välja coach.'
  },
  [TrVar.DevelopmentFor]: {
    en: `Development for`,
    sv: 'Utveckling för'
  },
  [TrVar.DevelopYourFullPotential]: {
    en: `Develop your full potential`,
    sv: 'Utveckla din fulla potential'
  },  
  [TrVar.DidWeReachGoal]: {
    en: 'Did we reach goal',
    sv: 'Nådde vi målet'
  },
  [TrVar.Discover]: {
    en: `Discover`,
    sv: 'Discover'
  },
  [TrVar.DiscoverTheBenefitsOfChatCoaching]: {
    en: 'Discover the benefits of chat coaching',
    sv: 'Prova fördelarna med coaching via chatt'
  },
  [TrVar.DoesNotAllowMeetingsWithCoach]: {
    en: 'Your license does not allow you too book meetings with your coach',
    sv: 'Din licens ger dig inte rätt att boka möten med coach'
  },
  [TrVar.DoesYourCoachNotHaveAnyTimes]: {
    en: 'Does your coach not have any available times?',
    sv: 'Har din coach inga tider?'
  },
  [TrVar.DoFollowUp]: {
    en: `Follow-up`,
    sv: 'Gör uppföljning'
  },
  [TrVar.Done]: {
    en: `Done`,
    sv: 'Klar'
  },
  [TrVar.DoNotForgetToSave]: {
    en: `Don't forget to save after you remove a language.`,
    sv: 'Glöm inte att spara när du tagit bort ett språk.'
  },
  [TrVar.DoNotWantToSpecify]: {
    en: `Do not want to specify`,
    sv: 'Vill ej ange'
  },
  [TrVar.DoYouAlreadyHaveAnAccount]: {
    en: `Do you already have an account?`,
    sv: 'Har du redan ett konto?'
  },
  [TrVar.DoYouHaveAQuestionOrWantToLeaveFeedback]: {
    en: 'Do you have a question, or want to leave feedback?',
    sv: 'Har du en fråga eller vill lämna feedback?'
  },
  [TrVar.DoYouNotHaveAnAccount]: {
    en: `Do you not have an account?`,
    sv: 'Har du inget konto?'
  },
  [TrVar.DoYouWantToCancel]: {
    en: `Are you sure that you want to cancel? Your adjusmemnts won't be saved.`,
    sv: 'Är du säker på att du vill avbryta? Dina ändringar kommer inte att sparas.'
  },
  [TrVar.DoYouWantToCancelMeeting]: {
    en: `Do you want to cancel your meeting?`,
    sv: 'Vill du avboka ditt möte?'
  },
  [TrVar.DoYouWantToCreateAndAccount]: {
    en: 'Do you want to create an account?',
    sv: 'Vill du skapa konto?'
  },
  [TrVar.DoYouWantToImproveYourRelationshipWithYourColleagues]: {
    en: 'Do you want to improve your relationship with your colleagues?',
    sv: 'Vill du boosta samarbetet med dina kollegor?'
  },
  [TrVar.DoYouWantToRemoveMeetingSuggestion]: {
    en: `Do you want to remove the meeting suggestion?`,
    sv: 'Vill du ta bort mötesförslaget?'
  },
  [TrVar.DoYouWantToSave]: {
    en: `Are you sure that you want to save? You haven't adjusted any of the inputs.`,
    sv: 'Är du säker på att du vill spara? Du har inte ändrat på något reglage.'
  },
  [TrVar.DoYouWantToSignIn]: {
    en: 'Do you want to sign in?',
    sv: 'Vill du logga in?'
  },
  [TrVar.DoYouWantToUpgrade]: {
    en: `Do you want to upgrade and receive a personal coach?`,
    sv: 'Vill du uppgradera och få en personlig coach?'
  },
  [TrVar.DriveYourDevelopmentForwardButNotGiveYouReadyMadeSolutions]: {
    en: `Drive your development forward, but not give you ready-made solutions or answers.`,
    sv: 'Driva din utveckling framåt, men ger dig inga färdiga lösningar eller svar.'
  },
  [TrVar.Driving]: {
    en: `Driving`,
    sv: 'Drivande'
  },
  [TrVar.DrivingBehaviour]: {
    en: `Driving behaviour`,
    sv: 'Driving behaviour'
  },
  [TrVar.DrivingDesc]: {
    en: `The Driving behaviour makes us good at pushing for change and ensuring that we achieve concrete and measurable results. We want to be influential and keep a fast pace as we move forward.`,
    sv: 'Det drivande beteendet gör oss bra på att driva på förändring och kontrollera att vi uppnår konkreta och mätbara resultat. Vi vill gärna ha inflytande och håller ett högt tempo när vi strävar framåt.'
  },
  [TrVar.EachBoxInTheBarShowsOnMeetingPeriod]: {
    en: `Each box in the bar shows on meeting period, and each dot shows a meeting. Ideally`,
    sv: 'Varje ruta i grafen visar en mötesperiod, och varje prick visar ett möte. Idealiskt sett har'
  },
  [TrVar.EarliestBooking]: {
    en: 'Set the margin you want from when a client books a meeting until the meeting starts.',
    sv: 'Ställ in hur mycket marginal du vill ha från att en klient bokar ett möte tills att mötet startar.'
  },
  [TrVar.Edit]: {
    en: `Edit`,
    sv: 'Ändra'
  },
  [TrVar.EditAvailableTime]: {
    en: `Edit available time`,
    sv: 'Ändra tillgänglig tid'
  },
  [TrVar.EditProfile]: {
    en: `Edit profile`,
    sv: 'Redigera profil'
  },
  [TrVar.Email]: {
    en: `Email`,
    sv: 'Mejladress'
  },
  [TrVar.EmailAndPassword]: {
    en: `Email and password`,
    sv: 'Mejl och lösenord'
  },
  [TrVar.EmailContactPerson]: {
    en: `Email address to contact person`,
    sv: 'Mejladress till kontaktperson'
  },
  [TrVar.EmailInUse]: {
    en: 'The email address is already used by another account',
    sv: 'Mejladressen används redan på ett annat konto'
  },
  [TrVar.Employer]: {
    en: `Employer`,
    sv: 'Arbetsgivare'
  },
  [TrVar.EmptyVisionBoard]: {
    en: 'No meeting notes',
    sv: 'Inga mötesanteckningar'
  },
  [TrVar.Ended]: {
    en: 'Ended',
    sv: 'Avslutad'
  },
  [TrVar.EngagingInActivitiesYouAreInterestedIn]: {
    en: 'Engaging in activities you are interested in',
    sv: 'Ägna sig åt aktiviteter man är intresserad av'
  },
  [TrVar.EnterVideoMeeting]: {
    en: 'Enter meeting',
    sv: 'Till mötet'
  },
  [TrVar.EnterYourEmailAddressToRecoverPassword]: {
    en: 'Enter your email address to recover your password',
    sv: 'Fyll i din mejladress för att återställa ditt lösenord'
  },
  [TrVar.EnterYourLicenseCode]: {
    en: 'Enter your license code',
    sv: 'Fyll i din licenskod'
  },
  [TrVar.EvaluatingOurActionsAndAdjustingThem]: {
    en: `Evaluating our actions and adjusting them as we gain new 
                    insights about ourselves. Punishment is only
                    negative for our performance`,
    sv: `Utvärdera sitt agerande och justera det i takt med att att 
                    vi får nya insikter om oss själva. Bestraffning 
                    är enbart negativt för vår prestation.`
  },
  [TrVar.Example]: {
    en: 'Example',
    sv: 'Exempel'
  },
  [TrVar.Exercise]: {
    en: 'Exercise',
    sv: 'Övning'
  },
  [TrVar.Exercises]: {
    en: 'Exercises',
    sv: 'Övningar'
  },
  [TrVar.ExperienceProgress]: {
    en: 'Experiencing progress',
    sv: 'Se sina framsteg'
  },
  [TrVar.ExtinguishFactors]: {
    en: 'Extinguishing factors that are required so that we are not demotivated',
    sv: 'Släckarfaktorer som krävs för att vi inte skall bli demotiverade'
  },
  [TrVar.Facebook]: {
    en: 'Facebook',
    sv: 'Facebook'
  },
  [TrVar.Faq]: {
    en: 'FAQ',
    sv: 'Vanliga frågor'
  },
  [TrVar.FaqLeaveFeedbackQuestion]: {
    en: 'Leave feedback or ask a question',
    sv: 'Lämna feedback eller ställ en fråga'
  },
  [TrVar.FaqLeaveFeedbackAnswer]: {
    en: "You can't find the answer to your question, or do you want to leave feedback? Of course, it's possible to be anonymous.",
    sv: 'Hittar du inte svar på din fråga eller vill lämna feedback? Du kan självklart vara anonym.'
  },
  [TrVar.FaqTechnicalIssuesQuestion]: {
    en: 'What should I do if I have technical issues with the platform?',
    sv: 'Vad gör jag om jag har tekniska problem med plattformen?'
  },
  [TrVar.FaqTechnicalIssuesAnswer]: {
    en: 'Send an email to support@zebrain.se and describe your issue and we will help you.',
    sv: 'Mejla support@zebrain.se och beskriv problemet så hjälper vi dig.'
  },
  [TrVar.FaqNoAvailableTimesQuestion]: {
    en: 'My coach has no available times. What should I do?',
    sv: 'Min coach har inga lediga tider. Vad gör jag?'
  },
  [TrVar.FaqNoAvailableTimesAnswer]: {
    en: 'Chat with your coach, hopefully you can find a time that suits you both.',
    sv: 'Chatta med din coach så kan ni säkert hitta en tid som passar er båda.'
  },
  [TrVar.FaqMissedMeetingQuestion]: {
    en: "What do I do if I've missed a meeting?",
    sv: 'Jag har missat ett möte! Vad gör jag nu?'
  },
  [TrVar.FaqMissedMeetingAnswer]: {
    en: 'Oops. Chat with your coach, hopefully you can find a new time if you haven\'t already used all of your meetings.',
    sv: 'Ojdå. Chatta med din coach så kan ni säkert hitta en ny tid om du inte redan bokat alla.'
  },
  [TrVar.FaqContactZebrainQuestion]: {
    en: 'Can I contact Zebrain without my coach seeing the message?',
    sv: 'Kan jag prata med Zebrain utan att min coach ser?'
  },
  [TrVar.FaqContactZebrainAnswer]: {
    en: 'Yes. Chat directly with Zebrain, and you can speak with somebody else.',
    sv: 'Inga problem. Chatta direkt med Zebrain så får du prata med någon annan.'
  },
  [TrVar.FaqUnusedMeetingsQuestion]: {
    en: 'What happens if I dont have time for all my meetings?',
    sv: 'Vad händer om jag inte hinner ha alla möten?'
  },
  [TrVar.FaqUnusedMeetingsAnswer]: {
    en: 'Unfortunately, you will lose meetings you haven\'t used. Try to schedule all of your meetings the first time you speak with your coach. If you have coaching through your company, please ask your manager to extend your license.',
    sv: 'Du förlorar tyvärr de möten som du inte nyttjat. Försök boka alla möten när du pratar med din coach första gången. Om du har coaching via företaget, be gärna din chef förlänga licensen.'
  },
  [TrVar.FaqCancelMeetingQuestion]: {
    en: 'What happens if I don\'t cancel my meeting 24 hours in advance?',
    sv: 'Vad händer om jag inte bokar av mitt möte ett dygn innan?'
  },
  [TrVar.FaqCancelMeetingAnswer]: {
    en: 'Unfortunately, that meeting will be considered used.',
    sv: 'Då går tyvärr det mötestillfället förlorat.'
  },
  [TrVar.FaqLicenseDurationQuestion]: {
    en: 'My license isn\'t valid long enough for me to have a meeting every other week. What do I do?',
    sv: 'Min licens gäller inte länge nog för att hinna möte varannan vecka. Vad gör jag?'
  },
  [TrVar.FaqLicenseDurationAnswer]: {
    en: 'Chat with your coach and book the meetings every week instead. Or talk to your manager about extending your license.',
    sv: 'Chatta med din coach och boka mötena till en gång i veckan istället. Eller prata med din chef om att förlänga licensen.'
  },
  [TrVar.FaqBiweeklyMeetingImportanceQuestion]: {
    en: 'Why is it important to book a meeting every other week?',
    sv: 'Varför är det viktigt att boka möten varannan vecka?'
  },
  [TrVar.FaqBiweeklyMeetingImportanceAnswer]: {
    en: 'We recommend every other week so that you will have time to do tasks and analyze what has been said. Of course, if you want to book meetings more frequently at times that is fine.',
    sv: 'Vi rekommenderar varannan vecka för att du ska hinna göra uppgifter och analysera det som sagts, men vill du boka tätare möten någon gång går det självklart bra.'
  },
  [TrVar.FaqVideoChatIssuesQuestion]: {
    en: 'How do I solve issues with the video chat?',
    sv: 'Hur löser jag problem med ljud och/eller bild i videochatten?'
  },
  [TrVar.FaqVideoChatIssuesAnswer]: {
    en: 'There is a help section in the video client where you can find solutions for the most common technical issues. If the issue still remains, chat with your coach or email support@zebrain.se.',
    sv: 'I videoklienten finns ett Hjälp-avsnitt där du hittar lösningar på de vanligaste tekniska problemen. Om problemet kvarstår, chatta med din coach eller mejla support@zebrain.se.'
  },
  [TrVar.FaqICFAccreditedCoach]: {
    en: 'Do I have to be an ICF accredited coach?',
    sv: 'Måste jag vara ICF krediterad coach?'
  },
  
  [TrVar.FaqICFCoachLevelRequirement]: {
    en: 'Yes, you must be an ICF coach at ACC, PCC or MCC level. At the ACC level, Zebrain requires 200 hours of coaching experience (ICF requires 100 hours).',
    sv: 'Ja, du ska vara ICF coach på ACC, PCC eller MCC nivå. På ACC nivå kräver Zebrain 200 timmar coachingerfarenhet (ICF kräver 100 timmar).'
  },
  
  [TrVar.FaqICFCertificationValidity]: {
    en: 'Does my ICF certification have to be valid?',
    sv: 'Måste min ICF certifiering vara giltig?'
  },
  
  [TrVar.FaqValidICFCertificationProcess]: {
    en: 'Yes, all our coaches must have valid certifications. You show your valid certification by uploading the document you received from ICF where the level, your name and the validity date are clearly visible. Alternatively, a valid link to your page on ICF where the certification is visible.',
    sv: 'Ja, alla våra coacher måste ha giltiga. Du visar din giltiga certifiering genom att ladda upp det dokument du fått från ICF där nivå, ditt namn samt giltighetsdatum syns tydligt. Alternativt en giltig länk till din sida på ICF där cerfitierigen syns.'
  },
  
  [TrVar.FaqRelationshipWithZebrain]: {
    en: 'What is my relationship with Zebrain?',
    sv: 'Vad blir min relation till Zebrain?'
  },
  
  [TrVar.FaqZebrainPartnershipDetails]: {
    en: 'Between you and Zebrain, no employment relationship is established and you do not guarantee us any minimum capacity. Zebrain build on a long-term partnership with you. You are the ambassador and representative of Zebrain.',
    sv: 'Mellan dig och Zebrain upprättas inget anställningsförhållande och du garanterar oss inte någon minimikapacitet. Zebrain bygger vidare på ett långsiktigt samarbete med dig, du är ambassadör och representant för Zebrain.'
  },
  
  [TrVar.FaqCostToJoinZebrain]: {
    en: 'Does it cost anything to join Zebrain?',
    sv: 'Kostar det något att ansluta sig till Zebrain?'
  },
  
  [TrVar.FaqJoiningZebrainIsFree]: {
    en: 'It is completely free to become a coach with us. Welcome with your application.',
    sv: 'Det kostar inget att bli coach hos oss. Välkommen med din ansökan.'
  },
  
  [TrVar.FaqContractCommitmentDuration]: {
    en: 'How long do I commit to a contract?',
    sv: 'Hur länge förbinder jag mig i ett avtal?'
  },
  
  [TrVar.FaqStandardContractTerms]: {
    en: 'Our agreement is a standard agreement with 1 month’s mutual notice.',
    sv: 'Vårt avtal är ett standardavtal med 1 månads ömsesidig uppsägningstid.'
  },
  
  [TrVar.FaqEmploymentFormAtZebrain]: {
    en: 'What is the form of employment at Zebrain?',
    sv: 'Hur är anställningsformen på Zebrain?'
  },
  
  [TrVar.FaqEmploymentRequirements]: {
    en: 'To become part of our team, you must have liability insurance and be self-employed or use a self-employment company such as Frilans finans.',
    sv: 'För att bli en del av vårt team måste du ha en ansvarsförsäkring och vara egenföretagare eller använda ett egenanställningsbolag som exempelvis Frilans finans.'
  },
  [TrVar.FaqZebrainInside]: {
    en: 'What is Zebrain Inside?',
    sv: 'Vad är Zebrain Inside?'
  },
  
  [TrVar.FaqZebrainInsideExplanation]: {
    en: 'If you as a coach or company bring your own clients into the platform, you can choose to limit their availability so that they only match with you. We call this variant Zebrain Inside. For questions about this email jessica@zebrain.se.',
    sv: 'Om du som coach eller företag tar med egna klienter in i plattformen kan du välja att begränsa deras tillgänglighet så de endast matchar med dig. Denna variant kallar vi Zebrain Inside. För frågor om detta maila jessica@zebrain.se.'
  },
  
  [TrVar.FaqInvoiceSubmission]: {
    en: 'Where and when do I send my invoice?',
    sv: 'Var och när skickar jag min faktura?'
  },
  
  [TrVar.FaqInvoiceProcess]: {
    en: 'You invoice at the end of the month for the current month. You send the invoice to inbox.lev.849054@arkivplats.se.',
    sv: 'Du fakturerar vid månadens slut för den aktuella månaden. Du skickar fakturan till inbox.lev.849054@arkivplats.se.'
  },
  
  [TrVar.FaqClientBase]: {
    en: 'What is my client base?',
    sv: 'Hur fungerar min klientbas?'
  },
  
  [TrVar.FaqClientBaseExplanation]: {
    en: 'As a Zebrain-coach, you will get access to our client base, where both corporate customers and private customers can log in and be matched with you. The result is a varied and interesting client range. We do not limit ourselves to any specific businesses or industries.',
    sv: 'Som Zebrain-coach får du tillgång till vår kundbas, där både företagskunder och privatkunder kan logga in och matchas med dig. Resultatet är ett varierat och intressant klientutbud. Vi begränsar oss inte till några specifika företag eller branscher.'
  },
  
  [TrVar.FaqMeetingClients]: {
    en: 'How do I meet my clients?',
    sv: 'Hur träffar jag mina klienter?'
  },
  
  [TrVar.FaqDigitalCoaching]: {
    en: 'Coaching via Zebrain is 100% digital, so you only meet your clients via video meeting on our platform.',
    sv: 'Zebrains coaching är 100% digital. Det innebär att du träffar dina klienter i videomöten i vår plattform.'
  },
  
  [TrVar.FaqClientMeetingDuration]: {
    en: 'How long are the client meetings?',
    sv: 'Hur långa är klientmötena?'
  },
  
  [TrVar.FaqMeetingDurationDetails]: {
    en: 'Your first meeting with a new client is 45 minutes. After that, the meetings are 30 or 45 minutes long. All meetings always take place via our digital platform.',
    sv: 'Ditt första möte med en ny klient är alltid 45 minuter. Därefter är mötena 30 eller 45 minuter långa beroende på licens. Alla möten sker i vår digitala plattform.'
  },
  
  [TrVar.FaqContactWithClients]: {
    en: 'How can I contact my client?',
    sv: 'Hur har jag kontakt med min klient?'
  },
  
  [TrVar.FaqClientCommunication]: {
    en: 'You can contact your client through our platform. You can message your client on your profile in the “Client-chat”.',
    sv: 'Du får tillgång till en komplett plattform och ett administrativt verktyg där du sköter alla bokningar, ombokningar och avbokningar. På plattformen hittar du bland annat en virtuell whiteboard, en chatt och ett emojis-bibliotek och du kan följa dina klienters framsteg genom mätningar och dataanalys.'
  },
  
  [TrVar.FaqExtendingClientLicense]: {
    en: 'How do I extend a license for my client?',
    sv: 'Hur förlänger jag en licens för min klient?'
  },
  
  [TrVar.FaqLicenseExtensionProcess]: {
    en: 'Send an email to support@zebrain.se',
    sv: 'Mejla till support@Zebrain.se så hjälper vi dig'
  },
  
  [TrVar.FaqTechnicalIssuesWithPlatform]: {
    en: 'What should I do if I have technical issues with the platform?',
    sv: 'Vad gör jag om jag har tekniska problem med plattformen?'
  },
  
  [TrVar.FaqTechnicalSupport]: {
    en: 'Send an email to support@zebrain.se and describe your issue and we will help you.',
    sv: 'Mejla support@zebrain.se och beskriv problemet så hjälper vi dig.'
  },




  [TrVar.FastGuidance]: {
    en: 'Fast and efficient guidance',
    sv: 'Snabb och effektiv vägledning'
  },
  [TrVar.FeelingBalancedEnergizedAndTheAbsence]: {
    en: `Being aware of your needs, drives and limitations and being able to act on them.`,
    sv: `Att känna sig återhämtad och uppleva frånvaro från skadlig stress.`
  },
  [TrVar.FindUs]: {
    en: 'You can find us here',
    sv: 'Här hittar du oss'
  },
  [TrVar.Finished]: {
    en: 'Finished?',
    sv: 'Avslutat?'
  },
  [TrVar.FirstName]: {
    en: 'First name',
    sv: 'Förnamn'
  },
  [TrVar.FollowUp]: {
    en: 'Follow-up',
    sv: 'Uppföljning'
  },
  [TrVar.FollowingUp]: {
    en: 'Follow up',
    sv: 'Följ upp'
  },
  [TrVar.FollowUsOnSocialMedia]: {
    en: 'You are welcome to follow us on social media',
    sv: 'Välkommen att följa oss på sociala medier'
  },
  [TrVar.FormatPhoneNumber]: {
    en: 'Phone number format is +447012345678',
    sv: 'Format på telefonnummer är +467012345678'
  },
  [TrVar.FormErrors]: {
    en: 'There are errors in the form above.',
    sv: 'Det finns fel i formuläret ovan.'
  },
  [TrVar.FortyFiveIntroductionMeeting]: {
    en: '45 min intro meeting with coach',
    sv: '45 min intro-möte med coach'
  },
  [TrVar.FoundNoAccountWithThatPersonNumber]: {
    en: `Found no account with that person number. Contact support@zebrain.se if you haven't created account before.`,
    sv: `Hittar inget konto med det personnumret. Kontakta support@zebrain.se om du inte har skapat konto förut.`
  },
  [TrVar.Free]: {
    en: 'Free',
    sv: 'Free'
  },
  [TrVar.From]: {
    en: 'From',
    sv: 'Från'
  },
  [TrVar.FromUrl]: {
    en: 'From URL',
    sv: 'Från URL'
  },
  [TrVar.FurtherDevelopmentOfBigFive]: {
    en: 'Further development of Big Five with subcategories.',
    sv: 'Vidareutveckling av Big Five med underkategorier.'
  },
  [TrVar.FurtherDevelopmentOfSocialStyles]: {
    en: `Further development of Social Styles which, among other
                  things, describe the impressions we make on each other. R Zackrison et al.`,
    sv: `Vidareutveckling av Social Styles som bl.a beskriver de
                  intryck vi gör på varandra. R Zackrison m.fl.`
  },
  [TrVar.FutureDescription]: {
    en: `Receive guidance on how to concretize and follow up on your goals.`,
    sv: 'Få vägledning i hur du konkretiserar och följer upp dina mål för framtiden.'
  },
  [TrVar.FutureMeetingsBooked]: {
    en: 'Future meetings booked',
    sv: 'Framtida möten bokade'
  },
  [TrVar.GetAPersonalChatCoach]: {
    en: 'Select your own coach and start asking questions.',
    sv: 'Få en personlig coach att chatta med.'
  },
  [TrVar.GetAPersonalCoach]: {
    en: 'Choose your own coach from your top matches.',
    sv: 'Få en personligt matchad coach'
  },
  [TrVar.GetCoachedByAI]: {
    en: 'Get coached by AI Emma',
    sv: 'Bli coachad av AI Emma'
  },
  [TrVar.GetLicensePackage]: {
    en: 'Get license package',
    sv: 'Hämta licenspaket'
  },
  [TrVar.GetStarted]: {
    en: 'Get started',
    sv: 'Kom igång'
  },
  [TrVar.GetStartedAsClient]: {
    en: 'If you want to find a Zebrain coach',
    sv: 'Om du vill hitta en Zebrain-coach'
  },
  [TrVar.GetStartedAsCoach]: {
    en: 'If you want to become a Zebrain coach',
    sv: 'Om du vill bli en Zebrain-coach'
  },
  [TrVar.GetStartedToday]: {
    en: 'Get started today',
    sv: 'Kom igång redan idag'
  },
  [TrVar.GetStartedWithYourAreasOfDevelopment]: {
    en: 'Get started with your areas of development!',
    sv: 'Kom igång med dina utvecklingsområden!'
  },
  [TrVar.GiacomoRizzolottis]: {
    en: `Giacomo Rizzolottis.`,
    sv: 'Giacomo Rizzolottis.'
  },
  [TrVar.GiacomoRizzolottisResearchAboutMirrorNeurons]: {
    en: `Giacomo Rizzolottis' research about mirror neurons which shows how we affect each other
                  with our emotions and the importance of emotional intelligence EQ.`,
    sv: `Giacomo Rizzolottis forskning om spegelneuroner som visar på hur vi påverkar varandra
                  med våra känslor och betydelsen av emotionell intelligens EQ.`
  },
  [TrVar.Gmail]: {
    en: 'Gmail',
    sv: 'Gmail'
  },
  [TrVar.GoalOfTodaysMeeting]: {
    en: `Goal of today's meeting`,
    sv: 'Mål med dagens möte'
  },
  [TrVar.GoBack]: {
    en: 'Go back',
    sv: 'Gå tillbaka'
  },
  [TrVar.GoForward]: {
    en: 'Next',
    sv: 'Gå vidare'
  },
  [TrVar.GoneFrom]: {
    en: 'Gone from',
    sv: 'Gått från'
  },
  [TrVar.GoToChat]: {
    en: `Go to chat`,
    sv: 'Gå till chatt'
  },
  [TrVar.GoToSettingsMenu]: {
    en: `Go to settings menu`,
    sv: 'Gå till inställningar meny'
  },
  [TrVar.GoToVideoMeeting]: {
    en: 'Meeting opened',
    sv: 'Mötet öppnat'
  },
  [TrVar.GoWebsite]: {
    en: 'Go to Zebrain.se',
    sv: 'Gå till Zebrain.se'
  },
  [TrVar.Great]: {
    en: 'Great',
    sv: 'Härligt'
  },
  [TrVar.GreatChoice]: {
    en: 'Great choice',
    sv: 'Bra val'
  },
  [TrVar.GrowPotential]: {
    en: 'Grow potential',
    sv: 'Utveckla potential'
  },
  [TrVar.Had]: {
    en: `Had`,
    sv: 'Haft'
  },
  [TrVar.HandleMeetingTimes]: {
    en: 'Handle meeting times',
    sv: 'Hantera mötestider'
  },
  [TrVar.HappyToSeeYou]: {
    en: `Happy to see you!`,
    sv: 'Kul att du är här!'
  },
  [TrVar.Has]: {
    en: `Has`,
    sv: 'Har'
  },
  [TrVar.HasBookedOneMeetingPerPeriod]: {
    en: `Has booked one meeting per period.`,
    sv: 'Bokat ett möte per mötesperiod.'
  },
  [TrVar.HasSharedPotential]: {
    en: `Has shared potential measurements with you`,
    sv: 'Har delat sina mätningar med dig'
  },
  [TrVar.HasWorkedWith]: {
    en: `Has worked with.`,
    sv: 'Har jobbat med.'
  },
  [TrVar.Have]: {
    en: `Have`,
    sv: 'Har'
  },
  [TrVar.HaveInvitedYou]: {
    en: `It's time to get started with coaching on Zebrain.`,
    sv: 'Nu är det dags för dig att komma igång med coaching på Zebrain.'
  },
  [TrVar.HaveYouDeveloped]: {
    en: `Have you developed?`,
    sv: 'Har du utvecklats?'
  },
  [TrVar.HaveYouDoneYourPlan]: {
    en: `Have you completed your plan and want to follow it up?`,
    sv: 'Har du genomfört din plan och vill göra uppföljning?'
  },  
  [TrVar.HaveYouGainedInsights]: {
    en: `Have you gained new insights since your last measurement? Check your current and wanted states, maybe you want to adjust something?`,
    sv: 'Har du fått nya insikter sen din förra mätning? Kolla igenom dina nulägen och önskade lägen, kanske vill du justera något?'
  },  
  [TrVar.Help]: {
    en: `Help`,
    sv: 'Hjälp'
  }, 
  [TrVar.HelenFisher]: {
    en: `Helen Fisher`,
    sv: 'Helen Fisher'
  },  
  [TrVar.HelenFishersResearchTogetherWithBrainResearcherLucyLBrown]: {
    en: `Helen Fisher's research together with brain researcher Lucy L. Brown and psychologist Art Aron
                  based on analysis of biologically based personality dimensions in 40,000 individuals and patterns of
                  attractions in 28,000 individuals as well as data from genetics, neurology and personality psychology.
                  Giacomo Rizzolotti’s research about mirror neurons which shows how we affect each other
                  with our emotions and the importance of emotional intelligence EQ.`,
    sv: `Helen Fishers forskning tillsammans med hjärnforskaren Lucy L. Brown och psykologen Art Aron
                  utifrån analyser av biologiskt baserade personlighetsdimensioner hos 40 000 individer och mönster av
                  attraktioner hos 28 000 individer samt data från bl.a genetik, neurologi och personlighetspsykologi
                  samt Giacomo Rizzolottis forskning om spegelneuroner som visar på hur vi påverkar varandra
                  med våra känslor och betydelsen av emotionell intelligens EQ.`
  }, 
  [TrVar.Here]: {
    en: `Here`,
    sv: 'Här'
  }, 
  [TrVar.HereYouCanConfirmThatTheMeeting]: {
    en: `Here you can confirm that the meeting went according to plan, or if there was a problem.`,
    sv: 'Här kan du bekräfta att mötet gick som planerat, eller om det uppstod problem.'
  }, 
  [TrVar.HereYouCanSortOnMeasuresCurrentState]: {
    en: `Here you can sort on the measure's current state`,
    sv: 'Här kan du sortera på mätningens nuläge'
  }, 
  [TrVar.HereYouSeeTheCoachQuestions]: {
    en: `Here you see the coach questions`,
    sv: 'Här kan du se vilka coachfrågor'
  },  
  [TrVar.HexacoModel]: {
    en: `Hexaco model of personality.`,
    sv: 'Hexaco model of personality.'
  },
  [TrVar.HideNotes]: {
    en: `Hide notes`,
    sv: 'Dölj anteckningar'
  },
  [TrVar.HideVideo]: {
    en: `Hide video`,
    sv: 'Dölj video'
  },
  [TrVar.HighestGap]: {
    en: `Highest gap`,
    sv: 'Högst gap'
  },
  [TrVar.HighestValueOnTop]: {
    en: `Highest value on top`,
    sv: 'Högst värde överst'
  },
  [TrVar.Hours]: {
    en: `Hours`,
    sv: 'Timmar'
  },
  [TrVar.HowClientsSeeYou]: {
    en: 'How your clients will see information about you',
    sv: 'Så här ser din profil ut för dina klienter'
  },
  [TrVar.HowDoesChatCoachingWork]: {
    en: 'How does chat coaching work?',
    sv: 'Hur fungerar coaching via chatt?'
  },
  [TrVar.HowDoICancelMyAccount]: {
    en: 'How do I cancel my account?',
    sv: 'Hur avslutar jag mitt konto?'
  },
  [TrVar.HowDoIFeel]: {
    en: 'How do I feel',
    sv: 'Hur mår jag'
  },
  [TrVar.HowDoIGetThere]: {
    en: 'How do I get there?',
    sv: 'Hur tar jag mig dit jag vill?'
  },
  [TrVar.HowDoIGetToWhereIWantToBe]: {
    en: `How do I get to where I want be`,
    sv: 'Hur tar jag mig dit jag vill'
  },
  [TrVar.HowDoISolveIssuesWithTheVideoChat]: {
    en: 'How do I solve issues with the video chat?',
    sv: 'Hur löser jag problem med ljud och/eller bild i videochatten?' 
  },  
  [TrVar.HowDoIKnowIfImMakingProgress]: {
    en: `How do I know if I'm making progress?`,
    sv: 'Hur vet jag om jag har utvecklats?'
  }, 
  [TrVar.HowDoIWorkbestWithMyAreasOfDevelopmentToReachMyPotential]: {
    en: 'How do I work best with my areas of development to reach my untapped potential?',
    sv: 'Hur jobbar jag bäst med mina utvecklingsområden för att nå min oanvända potential?'
  },
  [TrVar.HowDoYouExperienceYourSituation]: {
    en: `How do you experience your situation today?`,
    sv: 'Hur upplever du din situation idag?'
  },
  [TrVar.HowLongDoIHaveAccessToTheCoach]: {
    en: 'How long do I have access to the coach I’m chatting with?',
    sv: 'Hur länge har jag tillgång till min coachen jag chattar med?'
  },
  [TrVar.HowMotivatedAmI]: {
    en: 'How motivated am I',
    sv: 'Hur motiverad är jag'
  },
  [TrVar.HowToIdentifyYourStrengthsAndIncreaseYourMotivation]: {
    en: 'How to strengthen your self-leadership',
    sv: 'Hur du stärker ditt självledarskap'
  },
  [TrVar.HowToIncreaseYourStressResilience]: {
    en: 'How to increase your stress resilience',
    sv: 'Hur du ökar din stresstålighet'
  },
  [TrVar.HowToStrengthenYourSelfLeadership]: {
    en: 'How to identify your strengths and increase your motivation',
    sv: 'Hur du hittar din styrkor och ökar din drivkraft'
  },
  [TrVar.HowWeJudgeOthers]: {
    en: `How we judge others, instinctively, based on stereotypes. Amy Cuddy.`,
    sv: 'Hur vi dömer andra spontant utifrån stereotyper. Amy Cuddy.'
  },
  [TrVar.HowWorksChatCoaching]: {
    en: 'How chat coaching works',
    sv: 'Så fungerar chattcoaching'
  },
  [TrVar.HowYouUseThePlatform]: {
    en: 'How you use the platform',
    sv: 'Hur du använder plattformen'
  },
  [TrVar.IAccept]: {
    en: `I accept`,
    sv: 'Jag godkänner'
  },
  [TrVar.IApproveThatZebrainStoresMyPersonalInformation]: {
    en: `I approve that Zebrain stores my personal information in accordance to GDPR.`,
    sv: 'Jag godkänner att Zebrain lagrar mina personuppgifter enligt GDPR.'
  },
  [TrVar.IDIInterpersonalDynamicInventory]: {
    en: `IDI Interpersonal Dynamic Inventory.`,
    sv: 'IDI Interpersonal Dynamic Inventory.'
  },
  [TrVar.IdNumber]: {
    en: `Personal identity number`,
    sv: 'Personnummer'
  },
  [TrVar.IfAClientWantsToBeCoachedInALanguageYouHaveNotAdded]: {
    en: `If a client wants to be coached in a language you
              have not added a description in, the language of your first
              added presentation will be displayed.`,
    sv: `Om en klient vill bli coachad på ett språk du inte lagt till en beskrivning
              för kommer språket på din först tillagda presentation visas.`
  },
  [TrVar.IfNobodyShowsUpPleaseContact]: {
    en: `If nobody shows up, please contact`,
    sv: 'Om ingen kommer, hör av dig till'
  },
  [TrVar.IfSomeoneBooksALongerMeeting]: {
    en: `If someone books a longer meeting`,
    sv: 'Om någon bokar längre möte'
  },
  [TrVar.IfSomeoneHasBookedAMeetingInYourMeetingBlock]: {
    en: `If someone has booked a meeting in your meeting block, Zebrain subtracts that meeting from suggestions
                  to next cliekt. If a client for example books a meeting 10:30 in the meeting block above, the next
                  client will get the suggestions:`,
    sv: `Om någon bokat ett möte i ditt mötesblock subtraherar Zebrain det mötet från förslag till klient. Om någon
                  t ex bokar ett möte 10:30 i mötesblocket ovan kommer nästa klient få förslagen:`
  },
  [TrVar.IfThereAreNoAvailableTimes]: {
    en: 'If there are no available times',
    sv: 'Om det saknas lediga tider'
  },
  [TrVar.IfThisIsYourFirstTimeAsAZebrainUserYouCanProceedToSetUpYourAccount]: {
    en: 'If this is your first time as a Zebrain user, you can proceed to set up your account:',
    sv: 'Om det här är första gången du använder Zebrain, så kan du fortsätta sätta upp ditt konto:'
  },
  [TrVar.IfYouAlreadyCreatedAnAccountWithAnotherLoginMethod]: {
    en: `If you already created an account with another login method, you should log out from 
                this session and login with your first login method.`,
    sv: `Om du redan skapat konto med en annan inloggningsmetod bör du logga ut från denna session och logga 
                in med samma metod som när du skapade kontot.`
  },
  [TrVar.IfYouAlreadyHaveAnAccountSignInAndContinue]: {
    en: 'If you already have an account, sign back in and continue where you left off',
    sv: 'Om du redan har ett konto är det bara att logga in och fortsätta där du slutade.'
  },
  [TrVar.IfYouClickInAGreenMeetingBlockYouCanChange]: {
    en: `If you click in a green meeting block you can change start and end time. You can also remove the
                  meeting block. Please note that booked meetings are not affected by changed meeting blocks.
                  If you want to cancel a booked meeting you can do that under My bookings.`,
    sv: `Om du klickar i ett grönt mötesblock kan du ändra start- och sluttid. Du kan också ta bort mötesblocket.
                  Tänk på att bokade möten inte påverkas av ändrade mötesblock. Vill du avboka ett möte gör du det
                  under Mina bokningar.`
  },
  [TrVar.IfYouDontChooseSortingTheTableIs]: {
    en: `If you don't choose sorting, the table is sorted by`,
    sv: 'Väljer du inte sortering är tabellen sorterad efter'
  },
  [TrVar.IfYouDontFollowGetInTouch]: {
    en: `If you don't follow, get in touch with hakan@zebrain.se`,
    sv: 'Om ni inte hänger med, hör av er till hakan@zebrain.se'
  },
  [TrVar.IfYouHaveCreatedAZebrainAccountBeforePleaseSendAMailToSupport]: {
    en: `If you have created a Zebrain account before, please send a mail to support@zebrain.se
                and we'll help you.`,
    sv: 'Om du har skapat ett Zebrain konto förut, var god mejla support@zebrain.se och vi kommer hjälpa dig.'
  },
  [TrVar.IfYouGetBookedMeetingsWithDifferentLengthsInAMeetingBlock]: {
    en: `If you get booked meetings with different lengths in a meeting block, the pause might be longer than
                  your selected meeting pause.`,
    sv: `Om du i samma mötesblock får kortare och längre möten inbokade kan pausen mellan möten bli längre än din
                  valda mötespaus.`
  },
  [TrVar.IfYouHaveHadCoach]: {
    en: `But if you had had it then you could've booked meetings with your coach here.`,
    sv: 'Men hade du haft det så hade du kunnat boka möten med din coach här.'
  },
  [TrVar.IfYouHaveLicenseCode]: {
    en: 'If you have a license code enter it here',
    sv: 'Om du har en licenskod fyll i den här'
  },
  [TrVar.IfYouHaventCreatedAnAccountToGetStartedContactSupport]: {
    en: `If you haven't created an account, to get started contact support.`,
    sv: 'Om du inte har skapat ett konto, behöver du kontakta support för att få hjälp med att komma igång.'
  },
  [TrVar.IfYouLackSomethingEmail]: {
    en: `If there is something you lack or don't understand, send a mail to`,
    sv: 'Om det är något ni saknar eller inte förstår, mejla'
  },
  [TrVar.IfYourCompanyPaysYourLicenseYouShouldNotCreateAccount]: {
    en: `If your company pays your license you should not create account here. 
                      Contact support@zebrain.se and we will help you.`,
    sv: `Om ditt företag betalar din licens ska du inte skapa konto här. 
                      Kontakta support@zebrain.se så hjälper vi dig.`
  },
  [TrVar.IfYourLicenseIsPaidByyourOrganisationAnAnonymisedReportWillBeAvailableForTheContactPersonInYourOrganisation]: {
    en: `If your license is paid by your organisation an anonymised report will be available for the
                contact person in your organisation. The report will contain average values of behaviour styles
                and measurement areas. The report is only available if at least five persons have responded.`,
    sv: `Om din licens betalas av din organisation kommer en anonymiserad rapport tillgängliggöras för
                    kundansvarig hos din organisation. I rapporten presenteras genomsnitt av svarandes beteendestilar och
                    områden inom mätningen. Rapporten är bara tillgänglig om minst fem personer svarat.`
  },
  [TrVar.IfYouWantToCancelYourAccountPleaseContactZebrain]: {
    en: ' If you want to cancel your account, please contact support@zebrain.se and we will help you.',
    sv: 'Vill avsluta ditt konto är du välkommen att höra av dig till support@zebrain.se så hjälper vi dig.'
  },
  [TrVar.Ignition]: {
    en: 'Igniter factors',
    sv: 'Tändarfaktorer'
  },
  [TrVar.IgnitionFactors]: {
    en: 'Igniter factors increases our motivation the more we get of them',
    sv: 'Tändarfaktorer ökar vår motivation ju mer vi får av dem'
  },
  [TrVar.IgnitionFactorsAreInternalFactors]: {
    en: `Igniter factors are internal factors that fuels our motivation and boosts our performance. The more we experience them, the more our motivation increases. `,
    sv: `Tändarfaktorer är inre faktorer som tänder vår motivation och ökar vår prestation. Ju mer vi upplever dem, ju mer växer vår motivation.`
  },
  [TrVar.IHaveMissedAMeetingWhatDoIDo]: {
    en: `What do I do if I've missed a meeting?`,
    sv: 'Jag har missat ett möte! Vad gör jag nu?'
  },
  [TrVar.IIdentifyAs]: {
    en: `I identify as`,
    sv: 'Jag identifierar mig som'
  },
  [TrVar.Improve]: {
    en: `Improve`,
    sv: 'Förbättra'
  },
  [TrVar.Included]: {
    en: `Included`,
    sv: 'Detta ingår'
  },
  [TrVar.InCommon]: {
    en: `In common`,
    sv: 'Gemensamma'
  },
  [TrVar.Incorrect]: {
    en: `Incorrect`,
    sv: 'Stämmer inte'
  },
  [TrVar.Increase]: {
    en: `Increase`,
    sv: 'Öka'
  },
  [TrVar.Increased]: {
    en: `Increased`,
    sv: 'Ökat'
  },
  [TrVar.IndividualAccountOverview]: {
    en: `Individual account overview`,
    sv: 'Personlig kontoöversikt'
  },
  [TrVar.InfoInactiveClient]: {
    en: 'You can still work with measurements and areas of development.',
    sv: 'Du kan fortfarande jobba med mätningar och utvecklingsområden.'
  },
  [TrVar.InMonth]: {
    en: `/month`,
    sv: '/månad'
  },
  [TrVar.Inside]: {
    en: `Inside`,
    sv: 'Inside'
  },
  [TrVar.Insight]: {
    en: `Insight`,
    sv: 'Insikt'
  },
  [TrVar.Instagram]: {
    en: 'Instagram',
    sv: 'Instagram'
  },
  [TrVar.InstructionEmail]: {
    en: `We have sent an email with instructions to`,
    sv: 'Vi har skickat ett mejl med instruktioner till'
  },
  [TrVar.Interest]: {
    en: `Interest.`,
    sv: 'Intresse.'
  },
  [TrVar.InTheNextStepWeWillMatchYouWithOneOfOurCoaches]: {
    en: 'In the next step, we will match you with one of our coaches.',
    sv: 'I nästa steg ska vi matcha dig med en av våra coacher.'
  },
  [TrVar.InvalidEmail]: {
    en: 'You must enter a valid email address',
    sv: 'Du måste ange en giltig mejladress'
  },
  [TrVar.InvitationExpired]: {
    en: `This invitation has expired`,
    sv: `This invitation has expired`
  },
  [TrVar.InvitationNotFound]: {
    en: `We couldn't find your invitation`,
    sv: 'Vi hittar tyvärr inte din inbjudan'
  },
  [TrVar.Is]: {
    en: `Is`,
    sv: 'Är'
  },
  [TrVar.IsAlsoGoodAt]: {
    en: `Is also good at`,
    sv: 'Är också bra på att'
  },
  [TrVar.IsAnExcellentMatch]: {
    en: 'Is an excellent match for you',
    sv: 'Passar dig utmärkt'
  },
  [TrVar.IsEspeciallyGood]: {
    en: `Is especially good at`,
    sv: 'Är speciellt bra på att'
  },
  [TrVar.IsUnchanged]: {
    en: 'Has not changed',
    sv: 'Har inte förändrats'
  },
  [TrVar.ItIsCompletelyNaturalWithSetbacks]: {
    en: `It is completely natural with both setbacks and lack of progress in all personal
                      development. It is a natural part of the process forward. Make no big deal of lack of
                      progress or setbacks, keep working on the path you have started and results will come.
                      Maybe you need to focus a bit more, maybe you should just accept the situation.
                      You now best yourself.`,
    sv: `Det är helt naturligt med både motgångar och stillastående i all personlig utveckling.
                      Det är en naturlig del av processen framåt. Gör ingen stor sak av att det står stilla
                      eller går tillbaka utan fortsätt på den väg du påbörjat så kommer resultaten.
                      Kanske behöver du fokusera lite mer kanske är det bara att acceptera. Du vet bäst själv.`
  },
  [TrVar.IWantToChat]: {
    en: `I want to chat`,
    sv: 'Jag vill chatta'
  },
  [TrVar.IWantToUpgrade]: {
    en: `I want to upgrade`,
    sv: 'Jag vill uppgradera'
  },
  [TrVar.KeepBoostingYourselfWithCoaching]: {
    en: 'Keep boosting yourself with coaching',
    sv: 'Fortsätt boosta dig själv med coaching'
  },
  [TrVar.KeepInMind]: {
    en: 'Keep in mind',
    sv: 'Tänk på'
  },
  [TrVar.KeepWorkingWithYourAreaOfDevelopment]: {
    en: `Keep working with your area of development`,
    sv: 'Fortsätt jobba med ditt utvecklingsområde'
  },
  [TrVar.Kickstart]: {
    en: `Kickstart`,
    sv: 'Kickstarta'
  },
  [TrVar.KnowWhatApplies]: {
    en: `To know what applies.`,
    sv: 'Veta vad som gäller.'
  },
  [TrVar.LangToBeCoached]: {
    en: 'Choose language for your coaching sessions',
    sv: 'Välj språk att coachas på'
  },
  [TrVar.LangToCoach]: {
    en:  'Choose language for your coaching sessions',
    sv: 'Språk att coacha på'
  },
  [TrVar.Language]: {
    en: `Language`,
    sv: 'Språk'
  },
  [TrVar.Larger]: {
    en: `Larger`,
    sv: 'Större'
  },
  [TrVar.LargestImprovement]: {
    en: 'Largest improvement',
    sv: 'Störst förbättring',
  },
  [TrVar.LargestReachedPotential]: {
    en: 'Largest reached potential',
    sv: 'Högst uppnådd potential',
  },
  [TrVar.LargestUnusedPotential]: {
    en: 'Largest unused potential',
    sv: 'Störst outnyttjad potential'
  },
  [TrVar.Last]: {
    en: 'Last',
    sv: 'Senaste'
  },
  [TrVar.LastDay]: {
    en: 'Last day',
    sv: 'Sista dag'
  },
  [TrVar.LastName]: {
    en: 'Last name',
    sv: 'Efternamn'
  },
  [TrVar.LastUpdated]: {
    en: 'Last updated',
    sv: 'Senast uppdaterad'
  },
  [TrVar.LeaveAComment]: {
    en: 'Leave a comment',
    sv: 'Lämna kommentar'
  },
  [TrVar.LeaveContactInformationAndWeWillTakeOver]: {
    en: `Leave contact information and we'll take it from there.`,
    sv: 'Lämna kontaktuppgifter så tar vi det därifrån.'
  },  
  [TrVar.LeaveContacts]: {
    en: 'Leave contact information to your employer and we will take it from there.',
    sv: 'Lämna kontaktuppgifter till din arbetsgivare så tar vi det därifrån.'
  },
  [TrVar.LeaveMeeting]: {
    en: 'Leave meeting',
    sv: 'Lämna mötet'
  },
  [TrVar.Left]: {
    en: 'Left',
    sv: 'Vänster'
  },
  [TrVar.LeftToDesiredState]: {
    en: 'Left to your desired state',
    sv: 'Kvar till ditt önskade läge'
  },
  [TrVar.Length]: {
    en: 'Length',
    sv: 'Längd'
  },  
  [TrVar.License]: {
    en: 'License',
    sv: 'Licens'
  },
  [TrVar.LicenseCode]: {
    en: 'License code',
    sv: 'Licenskod'
  },
  [TrVar.LicenseCodeNotFound]: {
    en: 'Could not find the campaign code',
    sv: 'Hittade inte licenskoden'
  },  
  [TrVar.LicenseExpired]: {
    en: 'The license has expired',
    sv: 'Du har klickat på en länk som inte längre fungerar'
  },
  [TrVar.LicenseIsNotAvailable]: {
    en: 'License is not available',
    sv: 'Licensen är inte tillgänglig'
  },
  [TrVar.LicenseIsValidTo]: {
    en: 'The license is valid to',
    sv: 'Du kan använda paketet till och med'
  },
  [TrVar.LicenseIsValidUpTo]: {
    en: 'The license is active until',
    sv: 'Licensen är giltig t o m'
  },
  [TrVar.LicenseType]: {
    en: 'License type',
    sv: 'Licenstyp'
  },
  [TrVar.LiftCoachingWithYourBoss]: {
    en: 'Bring up coaching with your boss',
    sv: 'Lyft coaching med chefen'
  },
  [TrVar.LinkedIn]: {
    en: 'LinkedIn',
    sv: 'LinkedIn'
  },
  [TrVar.LinkError]: {
    en: 'Link error',
    sv: 'Länkfel'
  },
  [TrVar.LogBack]: {
    en: 'Sign back in',
    sv: 'Logga in igen'
  },
  [TrVar.Login]: {
    en: 'Log in',
    sv: 'Logga in här'
  },
  [TrVar.LoginCancelled]: {
    en: 'The login was cancelled',
    sv: 'Inloggningen avbröts'
  },
  [TrVar.LoginFailed]: {
    en: 'Wrong login credentials',
    sv: 'Fel inloggningsuppgifter'
  },
  [TrVar.LoginOnAnotherUnit]: {
    en: 'Log in with another device',
    sv: 'Logga in med en annan enhet'
  },
  [TrVar.LoginOnThisUnit]: {
    en: 'Log in on this device',
    sv: 'Logga in på den här enheten'
  },
  [TrVar.LoginWithBankId]: {
    en: 'Log in with BankID',
    sv: 'Logga in med BankID'
  },
  [TrVar.Logging]: {
    en: 'Log in',
    sv: 'Logga in'
  },
  [TrVar.LowestValueOnTop]: {
    en: 'Lowest value on top',
    sv: 'Lägst värde överst'
  },
  [TrVar.MacCalendar]: {
    en: 'Mac Calendar',
    sv: 'Mac Kalender'
  },
  [TrVar.MakeFollowUp]: {
    en: 'Do the follow-up',
    sv: 'Gör uppföljning'
  },
  [TrVar.Man]: {
    en: 'Man',
    sv: 'Man'
  },
  [TrVar.ManagementCoachExperience]: {
    en: 'Years of management coaching experience',
    sv: 'Erfarenhet av ledarskapscoaching (år)'
  },
  [TrVar.MatchingSortedCoaches]: {
    en: 'We have matched you with the coaches who match your profile the best. Choose whoever you want!',
    sv: 'Vi har matchat dig med de coacher som passar just din profil allra bäst. Välj och vraka!'
  },
  [TrVar.MatchingWithCoach]: {
    en: 'Matching with coach',
    sv: 'Matchning med coach'
  },
  [TrVar.MatchingWithCoaches]: {
    en: 'Matching with coaches',
    sv: 'Matchar med coacher'
  },
  [TrVar.MatchWithCoach]: {
    en: 'Match with coach',
    sv: 'Matcha med coach'
  },
  [TrVar.Max340Characters]: {
    en: 'Max 340 characters',
    sv: 'Max 340 tecken'
  },
  [TrVar.Maximise]: {
    en: 'Maximise',
    sv: 'Maximera'
  },
  [TrVar.Meaningfulness]: {
    en: 'Meaningfulness.',
    sv: 'Meningsfullhet.'
  },
  [TrVar.Measure]: {
    en: 'Measure',
    sv: 'Mätning'
  },
  [TrVar.MeasureContinuously]: {
    en: 'By continuously measuring what you experience within the four areas, we measure your development and your total potential.',
    sv: 'Genom att löpande under din utvecklingsprocess markera vad du upplever inom de fyra områdena mäter du din personliga utveckling och förändringen av din totala potential.'
  },
  [TrVar.MeasureEffectsCoaching]: {
    en: 'Measure the effects of your development work',
    sv: 'Mäta effekter av ditt utvecklingsarbete'
  },
  [TrVar.Measurement]: {
    en: 'Measurement',
    sv: 'Mätning'
  },
  [TrVar.MeasureYourDevelopment]: {
    en: 'Measure your development',
    sv: 'Mät din utveckling'
  },
  [TrVar.MeasureYourPotentialAndDevelopment]: {
    en: 'Measurement of your potential and development',
    sv: 'Mätning av din potential och utveckling'
  },
  [TrVar.MeasurePotential]: {
    en: 'Measure potential',
    sv: 'Mäta potential'
  },
  [TrVar.Meeting]: {
    en: 'Meeting',
    sv: 'Möte'
  },
  [TrVar.MeetingBookings]: {
    en: 'Meeting bookings',
    sv: 'Mötesbokningar'
  },
  [TrVar.MeetAICoach]: {
    en: 'Meet our AI coach Emma.',
    sv: 'Träffa vår AI coach Emma.'
  },
  [TrVar.MeetingCancelled]: {
    en: 'Meeting cancelled',
    sv: 'Mötet inställt'
  },
  [TrVar.MeetingClientCancelledLate]: {
    en: 'Client cancelled late',
    sv: 'Klient avbokade sent'
  },
  [TrVar.MeetingCoachDidNotShow]: {
    en: 'Coach missed meeting',
    sv: 'Coach missade mötet'
  },
  [TrVar.MeetingEveryFourWeeks]: {
    en: 'Meeting every four weeks',
    sv: 'Möte var fjärde vecka'
  },
  [TrVar.MeetingEveryOtherWeek]: {
    en: 'Meeting every other week',
    sv: 'Möte varannan vecka'
  },
  [TrVar.MeetingHistory]: {
    en: 'Meeting history',
    sv: 'Möteshistorik'
  },
  [TrVar.MeetingIncludedInLicense]: {
    en: 'Meetings are included in license',
    sv: 'Möten ingår i licensen'
  },
  [TrVar.MeetingIsClosedReopen]: {
    en: 'Meeting window is closed. Click again if you want to reopen meeting.',
    sv: 'Mötesfönstret har stängts. Klicka igen om du vill öppna mötet.'
  },
  [TrVar.MeetingLength]: {
    en: 'Meeting length',
    sv: 'Möteslängd'
  },
  [TrVar.MeetingMinPause]: {
    en: 'There must be at least 15 minutes between meetings',
    sv: 'Det måste vara minst en kvart mellan mötesblock'
  },
  [TrVar.MeetingNotes]: {
    en: 'Meeting notes',
    sv: 'Mötesanteckningar'
  },
  [TrVar.MeetingPartner]: {
    en: 'Meeting partner',
    sv: 'Mötespartner'
  },
  [TrVar.MeetingSuggestion]: {
    en: 'Meeting suggestion',
    sv: 'Mötesförslag'
  },
  [TrVar.Meetings]: {
    en: 'Meetings',
    sv: 'Möten'
  },
  [TrVar.MeetingsCannotEndBeforeTheyStart]: {
    en: 'Meetings cannot end before they start',
    sv: 'Möten kan inte sluta innan de börjar'
  },
  [TrVar.MeetingsDone]: {
    en: 'Meetings done',
    sv: 'Möten avklarade'
  },
  [TrVar.MeetingsNoOverlap]: {
    en: 'Meetings may not overlap',
    sv: 'Mötesblock får inte överlappa varandra'
  },
  [TrVar.MeetingsRemainingToBook]: {
    en: 'Meetings remaining to book',
    sv: 'Möten kvar att boka'
  },
  [TrVar.MeetingStarts]: {
    en: 'Meeting starts',
    sv: 'Mötet startar'
  },
  [TrVar.MeetingStatus]: {
    en: 'Meeting status',
    sv: 'Mötesstatus'
  },
  [TrVar.MeetingStatusIsNotAvailable]: {
    en: 'Meeting status is not available',
    sv: 'Mötesstatus är inte tillgänglig'
  },
  [TrVar.MeetingsWhereTheStartTimeHasPassed]: {
    en: 'Meetings where start time has passed cannot be changed',
    sv: 'Möten vars starttid har passerats kan inte ändras'
  },
  [TrVar.MeetingTimeNotAvailable]: {
    en: 'The selected time is not free (or is in the past)',
    sv: 'Den valda tiden är inte ledig (eller har passerats)'
  },
  [TrVar.MeetingWentAccordingToPlan]: {
    en: 'Meeting went according to plan',
    sv: 'Mötet gick enligt plan'
  },
  [TrVar.MeetingWithCoach]: {
    en: 'Meeting with coach',
    sv: 'Möte med coach'
  },
  [TrVar.MessageFromCustomer]: {
    en: 'Message from customer',
    sv: 'Meddelande från kund'
  },
  [TrVar.Microphone]: {
    en: 'Microphone',
    sv: 'Mikrofon'
  },
  [TrVar.Min]: {
    en: 'Min',
    sv: 'Min'
  },
  [TrVar.Minutes]: {
    en: 'Minutes',
    sv: 'Minuter'
  },
  [TrVar.MinutesBreakClientMeetings]: {
    en: 'minutes break between client meetings',
    sv: 'minuters paus mellan klientmöten'
  },
  [TrVar.MinutesEach]: {
    en: 'Minutes each',
    sv: 'Minuter'
  },
  [TrVar.MinutesMeetingWithCoach]: {
    en: 'Min video meeting with coach',
    sv: 'Min videomöte med coach'
  },
  [TrVar.MissingInput]: {
    en: 'Missing input for a question',
    sv: 'Det saknas svar på en fråga'
  },
  [TrVar.MissingStatus]: {
    en: 'Missing status',
    sv: 'Saknas status'
  },
  [TrVar.Month]: {
    en: 'Month',
    sv: 'Månad'
  },
  [TrVar.MoreAboutOurFourAreas]: {
    en: 'More about our four areas',
    sv: 'Mer om våra fyra områden'
  },
  [TrVar.MoreAboutOurFourAreasSub1]: {
    en: `You measure and work with your potential within the areas Self-insight, Basic energy, Motivation and Self-leadership:`,
    sv: `Du mäter och jobbar med din potential inom områdena Självinsikt, Grundenergi, Motivation och Självledarskap:`
  },
  [TrVar.MoreAboutSelfLeadership]: {
    en: `More on Self-leadership`,
    sv: `Mer om självledarskap`
  },
  [TrVar.MoreAboutSelfLeadershipSub1Part1]: {
    en: `The concept of "self-leadership" was first introduced in literature as a self-imposed process`,
    sv: `Begreppet "Självledarskap" introducerades först i litteraturen som en självpåverkan process`
  },
  [TrVar.MoreAboutSelfLeadershipSub1Part2]: {
    en: `With the aim of working more effectively. Research shows that people who practice systematic self-leadership are more likely to perform well in both their personal and professional lives.`,
    sv: `Med syfte att kunna arbeta mer effektivt. Forskning visar att personer som praktiserar ett systematiskt självledarskap har större sannolikhet att prestera både i privat och i arbetslivet.`
  },
  [TrVar.MoreAboutSelfLeadershipSub2]: {
    en: `Sharon Parker is one of many who have shown that internal motivation is necessary to 
                    achieve self-leadership, but that it is not sufficient to achieve success.
                    Setting clear goals and expectations is essential for success.`,
    sv: `Sharon Parker är en av många som har visat att inre motivation är nödvändigt för att 
                    uppnå självledarskap, men att det inte är tillräckligt för att uppnå framgång. 
                    För att lyckas är det viktigt att sätta tydliga mål och tydliga förväntningar.`
  },
  [TrVar.MoreAboutYou]: {
    en: 'More about you',
    sv: 'Mer om dig'
  },
  [TrVar.More]: {
    en: 'More...',
    sv: 'Mer...'
  },
  [TrVar.MostAsked]: {
    en: 'Here are answers to frequently asked questions',
    sv: 'Här får du svar på de vanligaste frågorna'
  },
  [TrVar.Motivation]: {
    en: 'Motivation',
    sv: 'Motivation'
  },
  [TrVar.MotivationSub1]: {
    en: `Motivation is made up of the factors that influence our willingness to perform a particular action. At Zebrain, we 
                  distinguish between two types of factors: Igniter and Extinguishing factors.`,
    sv: `Motivation är de faktorer som påverkar vår vilja att utföra en viss handling. På Zebrain skiljer vi på två typer: 
                  Tändarfaktorer och Släckarfaktorer.`
  },
  [TrVar.MotivationContainsEverythingWeNeedToFeelMotivated]: {
    en: `Motivation contains everything we need to both feel motivated and perform.
                  Sometimes we all feel that we must perform even with a lack of motivation. In our model we distinguish
                  between two types of motivational factors:
                  `,
    sv: `Motivation handlar om vad vi behöver både för att uppleva oss motiverade och
                  för att prestera. Ibland kan vi känna motivation utan att det följs av prestation
                  precis som vi ibland kan prestera trots att vi egentligen inte vill. I vår modell
                  skiljer vi på två typer av motivationsfaktorer:
                  `
  },
  [TrVar.MotivationDescription]: {
    en: `Get to know what factors that turns your motivation on and off.`,
    sv: `Lär känna vilka faktorer som väcker och släcker din motivation.`
  },
  [TrVar.MovedToPreviousMeetings]: {
    en: 'Moved to Previous meetings',
    sv: 'Flyttad till Tidigare möten'
  },
  [TrVar.MoveMeeting]: {
    en: 'Reschedule meeting',
    sv: 'Flytta möte'
  },
  [TrVar.MsgSent]: {
    en: 'Your message has been sent!',
    sv: 'Ditt meddelande är skickat!'
  },
  [TrVar.MuchBehind]: {
    en: 'Much behind',
    sv: 'Mycket efter'
  },
  [TrVar.MuteMic]: {
    en: 'Mute microphone',
    sv: 'Stäng av ljud'
  },
  [TrVar.MyAccount]: {
    en: 'My account',
    sv: 'Mitt konto'
  },
  [TrVar.MyBookings]: {
    en: 'My bookings',
    sv: 'Mina bokningar'
  },
  [TrVar.MyClients]: {
    en: 'My clients',
    sv: 'Mina klienter'
  },
  [TrVar.MyCoachHasNoAvailableTimesWhatShouldIDo]: {
    en: 'My coach has no available times. What should I do?',
    sv: 'Min coach har inga lediga tider. Vad gör jag?'
  },
  [TrVar.MyCoachProfile]: {
    en: 'My coach profile',
    sv: 'Min coachprofil'
  },
  [TrVar.MyCoachSettings]: {
    en: 'My coach settings',
    sv: 'Mina coachinställningar'
  },
  [TrVar.MyLicense]: {
    en: 'My license',
    sv: 'Min licens'
  },
  [TrVar.MyLicenseIsntValidLongEnoughForMeToHaveMeetingsEveryOtherWeek]: {
    en: `My license isn't valid long enough for me to have a meeting every other week. What do I do?`,
    sv: 'Min licens gäller inte länge nog för att hinna möte varannan vecka. Vad gör jag?'
  },
  [TrVar.NA]: {
    en: 'N/A',
    sv: 'N/A'
  },
  [TrVar.Name]: {
    en: 'Name',
    sv: 'Namn'
  },
  [TrVar.NameOfContact]: {
    en: 'Name of contact person',
    sv: 'Namn på kontaktperson'
  },
  [TrVar.NeedLicenseCode]: {
    en: 'At the moment, you cannot continue without a license code.',
    sv: 'Just nu kan du inte fortsätta utan licenskod.'
  },
  [TrVar.New]: {
    en: 'New',
    sv: 'Nyhet'
  },
  [TrVar.NewCalendarSubscription]: {
    en: 'New calendar subscription',
    sv: 'Ny kalenderprenumeration'
  },
  [TrVar.NewChat]: {
    en: 'New chat',
    sv: 'Ny chatt'
  },
  [TrVar.NewMeasurement]: {
    en: 'New measurement',
    sv: 'Ny mätning'
  },
  [TrVar.NewTime]: {
    en: 'New time',
    sv: 'Ny tid'
  },
  [TrVar.Next]: {
    en: 'Next',
    sv: 'Nästa'
  },
  [TrVar.NextMeeting]: {
    en: 'Next meeting',
    sv: 'Nästa möte'
  },
  [TrVar.No]: {
    en: 'No',
    sv: 'Nej'
  },
  [TrVar.NoAccessToCoachYet]: {
    en: `You don't yet have access to a coach to further increase your potential and strengthen your abilities. If you wish to add a coach to your growth process, please contact the person in charge at your company or let us know via the support button in the left menu. Thank you!`,
    sv: 'Du har ännu inte tillgång till coach för att ytterligare öka din potential och stärka dina förmågor. Om du önskar addera en coach till din utvecklingsprocess kan du kontakta ansvarig på ditt företag eller meddela oss via supportknappen i vänstermenyn. Tack!'
  },
  [TrVar.NoAccount]: {
    en: 'No account',
    sv: 'Inget konto'
  },
  [TrVar.NoAvailableTimesBooking]: {
    en: `There are no available times for booking`,
    sv: 'Det finns inga tider att boka!'
  },
  [TrVar.NoBehaviourMeasure]: {
    en: `You've not completed any measurement of your behavioural profile.`,
    sv: 'Du har inte gjort någon mätning av din beteendeprofil.'
  },
  [TrVar.NobodyShowsUpInTheMeeting]: {
    en: 'Nobody shows up in the meeting',
    sv: 'Ingen dyker upp i mötet'
  },
  [TrVar.NoBookedMeeting]: {
    en: 'You have no booked meeting',
    sv: 'Du har inget möte inbokat'
  },
  [TrVar.NoChat]: {
    en: 'No chat',
    sv: 'Ingen chatt'
  },
  [TrVar.NoEndDate]: {
    en: 'No end date',
    sv: 'Löpande'
  },
  [TrVar.NonBinary]: {
    en: 'Non-binary',
    sv: 'Icke-binär'
  },
  [TrVar.NoneToChatWith]: {
    en: 'You are alone in the meeting - no one to chat with',
    sv: 'Du är ensam i mötet - det finns ingen att chatta med'
  },
  [TrVar.NoFileError]: {
    en: 'No file selected',
    sv: 'Ingen fil vald'
  },
  [TrVar.NoSave]: {
    en: 'No, save',
    sv: 'Nej, spara'
  },
  [TrVar.NoSeatsAvailable]: {
    en: 'There are no available seats for this license',
    sv: 'Det finns tyvärr inga platser kvar till coaching hos Zebrain'
  },
  [TrVar.NoSuggestedMeetings]: {
    en: 'No suggested meetings',
    sv: 'Inga föreslagna möten'
  },
  [TrVar.NotConfirmed]: {
    en: 'Not confirmed',
    sv: 'Inte bekräftat'
  },
  [TrVar.NotSelectedCoach]: {
    en: 'You have not selected a coach',
    sv: 'Du har inte valt coach'
  },
  [TrVar.NotStarted]: {
    en: 'Not started',
    sv: 'Inte påbörjad'
  },
  [TrVar.NotifyMyEmployer]: {
    en: 'Notify my employer',
    sv: 'Meddela min arbetsgivare'
  },
  [TrVar.Now]: {
    en: 'Now',
    sv: 'Nu'
  },
  [TrVar.NumberCoachHours]: {
    en: 'Hours of coaching experience',
    sv: 'Antal coachtimmar'
  },
  [TrVar.NumberYears]: {
    en: 'Number of years',
    sv: 'Antal år'
  },
  [TrVar.Of]: {
    en: 'Of',
    sv: 'Av'
  },
  [TrVar.OfferExpired]: {
    en: 'The offer expired',
    sv: 'Erjudandet gick ut'
  },
  [TrVar.OfferIsValid]: {
    en: 'The offer is valid until',
    sv: 'Erjudandet gäller till och med'
  },
  [TrVar.Onboarded]: {
    en: 'Onboarded',
    sv: 'Onboardad'
  },
  [TrVar.OncePerMonth]: {
    en: 'Once per month',
    sv: 'En gång per månad'
  },
  [TrVar.OneOneFourStockholm]: {
    en: '114 29 Stockholm',
    sv: '114 29 Stockholm'
  },
  [TrVar.OneStartedDevelopmentArea]: {
    en: '1 starting area of development',
    sv: '1st påbörjat utvecklingsområde'
  }, 
  [TrVar.Ongoing]: {
    en: 'Ongoing',
    sv: 'Pågår'
  },
  [TrVar.OngoingPlan]: {
    en: 'Ongoing plan',
    sv: 'Pågående plan'
  },
  [TrVar.Only]: {
    en: 'Only',
    sv: 'Endast'
  }, 
  [TrVar.OnlyWorksSweden]: {
    en: 'Only works in Sweden',
    sv: 'Fungerar bara i Sverige'
  },
  [TrVar.OnTrack]: {
    en: 'On track',
    sv: 'On track'
  }, 
  [TrVar.OOpsChatWithYourCoachHopefullyYouCanFindANewTime]: {
    en: `Oops. Chat with your coach, hopefully you can find a new time if you haven't already used all of your meetings.`,
    sv: 'Ojdå. Chatta med din coach så kan ni säkert hitta en ny tid om du inte redan bokat alla.'
  }, 
  [TrVar.OpenChat]: {
    en: 'Open chat',
    sv: 'Öppna chat'
  },
  [TrVar.Optimise]: {
    en: 'Optimise',
    sv: 'Optimera'
  },  
  [TrVar.Optional]: {
    en: 'Optional',
    sv: 'Valfritt'
  },  
  [TrVar.Organisation]: {
    en: 'Organisation',
    sv: 'Organisation'
  },
  [TrVar.OrgNr]: {
    en: 'Organisation number',
    sv: 'Organisationsnummer'
  },
  [TrVar.OrSomeoneYouThinkNeedCoaching]: {
    en: 'Or someone you think need coaching.',
    sv: 'Eller någon du tycker behöver coaching.'
  },  
  [TrVar.Other]: {
    en: 'Other',
    sv: 'Annat'
  },  
  [TrVar.OthersSeeYouSucceed]: {
    en: 'Others notice that you succeed.',
    sv: 'Andra påtalar att man lyckas.'
  },  
  [TrVar.OtherQuestions]: {
    en: 'Other questions',
    sv: 'Övriga frågor'
  },  
  [TrVar.OurAreas]: {
    en: 'Our four areas of development',
    sv: 'Våra fyra områden'
  },
  [TrVar.OurMatchingAlgorithmIsBasedOnBigFive]: {
    en: `All people exhibit the four behaviors but use them differently. 
                How much we use them may differ depending on the situation,
                but most of us exhibit a relatively consistent pattern of
                behaviour regardless of circumstances.`,
    sv: `Alla människor uppvisar de fyra beteendena men använder dem olika mycket. 
                Hur mycket vi använder dem kan skilja sig beroende på situation 
                men de flesta av oss uppvisar en relativt genomgående beteendegrund 
                oavsett omständigheter.`
  },  
  [TrVar.OurMatchingAlgorithmIsBasedOnBigFive2]: {
    en: `The algorithm is inspired by research in both neurophysiology and personality psychology.`,
    sv: `Algoritmen är inspirerad av forskning inom såväl neurofysiologi som personlighetspsykologi.`
  }, 
  [TrVar.OurMeasurementOfMotivationIsBasedOnSelfDeterminationTheory]: {
    en: `Our measurement of motivation is based on Self Determination Theory (SDT), developed in 
                    the 1970s by Edward Deci and Richard Ryan, Kenneth W Thomas and research by
                    Teresa Amabile, Director of Research at Harvard Business School, among others.
                    It also draws on more recent research showing how praise, especially unexpected
                    praise, has a positive impact on intrinsic motivation.`,
    sv: `Vår mätning av motivation bygger bl.a på Self Determination Theory (SDT) som utvecklades 
                    på 1970-talet av Edward Deci och Richard Ryan, Kenneth W Thomas samt forskning 
                    av Teresa Amabile, forskningschef på Harvard Business School. Den bygger också 
                    på senare forskning som visar hur beröm, framförallt oväntad, påverkar den inre 
                    motivationen positivt.`
  },
  [TrVar.OurMethod]: {
    en: 'Our method',
    sv: 'Vår metod'
  },
  [TrVar.Outlook]: {
    en: 'Outlook',
    sv: 'Outlook'
  },
  [TrVar.Overview]: {
    en: 'Overview',
    sv: 'Översikt'
  },
  [TrVar.PageNotFound]: {
    en: 'Page Not Found',
    sv: 'Sidan hittas inte'
  },
  [TrVar.Part1Of2]: {
    en: 'Part 1 of 2',
    sv: 'Steg 1 av 2'
  },
  [TrVar.Part2Of2]: {
    en: 'Part 2 of 2',
    sv: 'Steg 2 av 2'
  },
  [TrVar.Partially]: {
    en: 'Partially',
    sv: 'Delvis'
  },
  [TrVar.Partly]: {
    en: 'Partly',
    sv: 'Delvis'
  },
  [TrVar.Password]: {
    en: 'Password',
    sv: 'Lösenord'
  },
  [TrVar.PasswordAgain]: {
    en: 'Password (again)',
    sv: 'Lösenord (igen)'
  },
  [TrVar.PasswordChanged]: {
    en: 'Password changed',
    sv: 'Lösenord ändrat'
  },
  [TrVar.PasswordDontMatch]: {
    en: 'The passwords do not match',
    sv: 'Lösenorden matchar inte'
  },
  [TrVar.PasswordMustIncludeAtLeastTenCharacters]: {
    en: 'Password must include at least ten characters',
    sv: 'Lösenord måste innehålla minst tio tecken'
  },
  [TrVar.PasswordTooShort]: {
    en: 'Password is too short',
    sv: 'Lösenordet är för kort'
  },
  [TrVar.PasteTheCopiedUrl]: {
    en: 'Paste the copied URL',
    sv: 'Klistra in kopierad URL'
  },
  [TrVar.PausBetweenSessions]: {
    en: 'Break between meetings',
    sv: 'Paus mellan möten'
  },
  [TrVar.Payment]: {
    en: 'Payment',
    sv: 'Betalning'
  },  
  [TrVar.PayWithKlarna]: {
    en: 'Pay with Klarna',
    sv: 'Betala med Klarna'
  },  
  [TrVar.PerformanceDescription]: {
    en: `Become a master at using and structuring your resources more efficiently.`,
    sv: 'Bli mästare på att använda och anpassa dina resurser mer effektivt.'
  },
  [TrVar.PerFourWeeks]: {
    en: 'Every four weeks',
    sv: 'Var fjärde vecka'
  },
  [TrVar.PerMonth]: {
    en: '/ month',
    sv: '/ månaden'
  },
  [TrVar.PersonalData]: {
    en: 'Personal data',
    sv: 'Personuppgifter'
  },
  [TrVar.PerTwoWeeks]: {
    en: 'Every other week',
    sv: 'Varannan vecka'
  },
  [TrVar.PerWeek]: {
    en: 'Per week',
    sv: 'Per vecka'
  },
  [TrVar.Philosophy]: {
    en: 'Philsophy',
    sv: 'Filosofi'
  },
  [TrVar.Phone]: {
    en: 'Phone number',
    sv: 'Telefon'
  },
  [TrVar.PhoneContactPerson]: {
    en: 'Phone number to contact person',
    sv: 'Telefonnummer till kontaktperson'
  },
  [TrVar.PhoneNumber]: {
    en: 'Phone number',
    sv: 'Telefonnummer'
  },
  [TrVar.PhoneNumberPlaceholder]: {
    en: '+467012345678',
    sv: '+467012345678'
  },
  [TrVar.Picture]: {
    en: 'Picture',
    sv: 'Bild'
  },
  [TrVar.PlanCompleted]: {
    en: 'Plan completed',
    sv: 'Planen genomförd'
  },  
  [TrVar.PlansAndFollowUp]: {
    en: 'Plan and follow-up',
    sv: 'Plan och uppföljning'
  },
  [TrVar.PleaseChooseAnotherClient]: {
    en: 'Please choose another client',
    sv: 'Var god välj en annan klient'
  },
  [TrVar.PleaseConsiderThatTheClientHasReceivedAMail]: {
    en: `Please consider that the client has receiced a mail with your meeting proposal. You should wait a 
              while before your remove the proposal, so that the client has a chance to answer.`,
    sv: `Tänk på att klienten fått ett mejl med ditt mötesförslag. Du bör vänta lite innan du tar bort 
              förslaget, så att klienten har en chans att svara.`
  },
  [TrVar.PleaseStartBankId]: {
    en: 'Please start BankID',
    sv: 'Det är hög tid att starta BankID nu...'
  },
  [TrVar.PotentialMeasurement]: {
    en: 'Potential measurement',
    sv: 'Potentialmätning'
  },
  [TrVar.PowerAreaDone]: {
    en: `You have gone through all your powerful questions in the area of`,
    sv: 'Du har gått igenom alla dina kraftfulla frågor inom området'
  },
  [TrVar.PowerAreaDone0]: {
    en: `You can go back at any time and re-assess the same questions to see if you discover new opportunities to increase your self-insight.`,
    sv: 'Du kan när som helst gå tillbaka och fundera vidare utifrån samma frågor och se om du upptäcker nya möjligheter för att öka din självinsikt.'
  },
  [TrVar.PowerAreaDone1]: {
    en: `You also have the opportunity to go back and reflect further on the questions to see if you find new ways to increase your basic energy.`,
    sv: `Du kan när och om du vill gå tillbaka och reflektera vidare utifrån samma frågor och se om du hittar nya sätt att öka din grundenergi på.`
  },
  [TrVar.PowerAreaDone2]: {
    en: `Should you wish, you can go back to the questions again and see if you gain any new insights. This could increase your motivation even more.`,
    sv: `Du kan när du själv önskar gå tillbaka till frågorna igen och se vad du kan upptäcka som kommer att öka din motivation ännu mer.`
  },
  [TrVar.PowerAreaDone3]: {
    en: `If you want, you can always go back to the questions and see if you can strengthen your self-leadership even more through new insights.`,
    sv: 'När du själv vill kan du gå tillbaka till frågorna och se hur du kan stärka ditt självledarskap ännu mer.'
  },
  [TrVar.PowerAreaGood0]: {
    en: `Good job`,
    sv: 'Bra jobbat'
  },
  [TrVar.PowerAreaGood1]: {
    en: `Well done`,
    sv: 'Bra gjort'
  },
  [TrVar.PowerAreaGood2]: {
    en: `Nice`,
    sv: 'Fint'
  },
  [TrVar.PowerAreaGood3]: {
    en: `Strong`,
    sv: 'Starkt'
  },
  [TrVar.PowerfulQuestion]: {
    en: 'Powerful question',
    sv: 'Kraftfull fråga'
  },
  [TrVar.PowerfulQuestionAsSupport]: {
    en: 'Powerful questions as support',
    sv: 'Kraftfulla frågor som stöd'
  },
  [TrVar.PowerfulQuestions]: {
    en: 'Powerful questions',
    sv: 'Kraftfulla frågor'
  },  
  [TrVar.PowerfulQuestionsDescription]: {
    en: `The powerful questions, and in some cases suggestions of activities, are based on your measurements
                  of your Potential and your Current state and Wanted state. Every area - Self-insight, Basic energy,
                  Motivation and Self-leadership - has different statements with questions connected to them
                  and are adapted for you, your situation and your wishes.
                  `,
    sv: `De kraftfulla frågorna, och i några få fall förslag på aktiviteter, baseras på din
                  mätning av din Potential och ditt Nuläge och Önskade läge. Varje område - Självinsikt,
                  Grundenergi, Motivation och Självledarskap - har olika påståenden med frågor kopplade
                  till sig och är anpassade för dig, din situation och dina önskemål.
                  `
  },
  [TrVar.PowerQuestionsFeedback1]: {
    en: 'I have started to reflect on the question',
    sv: 'Jag har börjat reflektera kring frågan'
  },
  [TrVar.PowerQuestionsFeedback2]: {
    en: 'I have gone through the question thoroughly',
    sv: 'Jag har gått igenom frågan ordentligt'
  },
  [TrVar.PowerQuestionsFeedback3]: {
    en: 'I feel finished with the question',
    sv: 'Jag känner mig klar med frågan'
  },
  [TrVar.PowerQuestionsIntro]: {
    en: 'The path to your full potential goes through several powerful questions tailored to you, your situation and your desires. Each area - Self-Insight, Basic Energy, Motivation and Self-Leadership - has different statements with questions related to them. Choose which area you want to develop and work on one question at a time by reflecting and writing down your thoughts.',
    sv: 'Vägen till din fulla potential går genom flera kraftfulla frågor anpassade för dig, din situation och dina önskemål. Varje område – Självinsikt, Grundenergi, Motivation och Självledarskap – har olika påståenden med frågor kopplade till sig. Välj vilken del du vill utveckla och jobba med en fråga i taget genom att reflektera och skriva ned dina tankar.'
  },
  [TrVar.PowerQuestionsIntro0]: {
    en: `Who am I and what do I want? Self-insight is the foundation of all development and success. Being aware of one's strengths and understanding one's driving forces as well as one's obstacles is as crucial for the company as for the individual.`,
    sv: 'Vem är jag och vad vill jag? Självinsikt är grunden till all utveckling och framgång. Att vara medveten om sina styrkor och förstå sina drivkrafter likaväl som sina hinder är lika avgörande för företaget som för den enskilda individen.'
  },
  [TrVar.PowerQuestionsIntro1]: {
    en: 'How do I really feel? By basic energy we mean the absence of harmful stress in our lives. Stress in itself is not harmful and our bodies are well equipped to deal with it for shorter periods of time. But every day we need rest and recovery otherwise both the body and our mental capacity has the risk of being damaged.',
    sv: 'Hur mår jag egentligen? Med grundenergi menar vi frånvaron av skadlig stress i våra liv. Stress i sig är inte skadligt och våra kroppar är väl rustade för att hantera det under kortare perioder. Men varje dag behöver vi vila och återhämtning annars riskerar både kroppen och vår mentala kapacitet ta skada.'
  },
  [TrVar.PowerQuestionsIntro2]: {
    en: 'What do I need in order to perform? Motivation is about what we need to feel to be able to be motivated and to perform. Sometimes we can feel motivation without it being followed by an achievement, just as we can sometimes perform even though we do not really want to.',
    sv: 'Vad behöver jag för att prestera? Motivation handlar om vad vi behöver både för att uppleva oss motiverade och prestera. Ibland kan vi känna motivation utan att det följs av prestation, precis som vi ibland kan prestera trots att vi egentligen inte vill.'
  },
  [TrVar.PowerQuestionsIntro3]: {
    en: 'How do I succeed with what I want? The first three areas we measure and follow up is Self-insight, Basic energy and Motivation – their aim is to effectively apply the fourth area: Self-leadership. The meaning of Self-leadership is the ability to bring ourselves to what is important to us.',
    sv: 'Hur kan jag lyckas med det jag vill? De tre första områden vi mäter och följer upp – Självinsikt, Grundenergi och Motivation – syftar till att effektivt kunna tillämpa det fjärde området: Självledarskap. Med självledarskap menar man förmågan att ta oss själva till det som är viktigt för oss.'
  },
  [TrVar.Preview]: {
    en: `Preview`,
    sv: 'Förhandsgranskning'
  },
  [TrVar.Previous]: {
    en: `Previous`,
    sv: 'Föregående'
  },
  [TrVar.PreviousChats]: {
    en: 'Previous chats',
    sv: 'Tidigare chattar'
  },
  [TrVar.PreviousCoaches]: {
    en: 'Previous coaches',
    sv: 'Tidigare coacher'
  },
  [TrVar.PreviousCoaching]: {
    en: 'Previous coaching',
    sv: 'Tidigare coaching'
  },
  [TrVar.PreviousMeetings]: {
    en: 'Previous meetings',
    sv: 'Tidigare möten'
  },
  [TrVar.ProvideUsYourDetails]: {
    en: `Provide us your details`,
    sv: 'Fyll i dina uppgifter'
  },
  [TrVar.PurchaseConfirmation]: {
    en: 'Purchase confirmation',
    sv: 'Bekräftelse på köp'
  },
  [TrVar.Question]: {
    en: `Question`,
    sv: 'Fråga'
  },
  [TrVar.Questions]: {
    en: `Questions`,
    sv: 'Frågor'
  },
  [TrVar.QuestionsToWorkWith]: {
    en: 'Powerful questions you can work with.',
    sv: 'Kraftfulla frågor du kan jobba med.'
  },
  [TrVar.RaiseYourselfToNewHeights]: {
    en: `Raise yourself and your colleagues to new heights.`,
    sv: 'Lyft dig och kollegorna till nya höjder.'
  },
  [TrVar.ReachYourPotential]: {
    en: `Reach your full potential`,
    sv: 'Nå din fulla potential'
  },  
  [TrVar.ReachYourPotentialFasterWithACoach]: {
    en: `Reach your full potential with a personal coach!`,
    sv: 'Nå din fulla potential snabbare med en personlig coach!'
  },  
  [TrVar.ReadMessage]: {
    en: 'Read message',
    sv: 'Läs meddelande'
  },
  [TrVar.ReadMore]: {
    en: `Read more`,
    sv: 'Läs mer'
  },
  [TrVar.ReadMoreAboutHowZebrainHandlesPersonalInformation]: {
    en: `Read more about how Zebrain handles personal information`,
    sv: 'Läs mer om hur Zebrain hanterar personuppgifter'
  },
  [TrVar.ReadMoreAboutYou]: {
    en: `Read more about you`,
    sv: 'Läs mer om dig'
  },
  [TrVar.ReadMoreAboutYour]: {
    en: `Read more about your`,
    sv: 'Läs mer om din'
  },
  [TrVar.ReceiveContinuedGuidanceThroughYourDevelopment]: {
    en: 'Receive continued guidance through your development',
    sv: 'Få fortsatt vägledning genom din utveckling '
  },
  [TrVar.ReceiveGuidanceInIdentifyingYourStrengthsAndMotivations]: {
    en: 'Receive guidance in identifying your strengths and motivations',
    sv: 'Få hjälp att hitta dina styrkor och drivkrafter '
  },
  [TrVar.ReceiveRegognitionOfSuccess]: {
    en: `Receiving recognition of success`,
    sv: 'Få bekräftelse på att man lyckas'
  },
  [TrVar.RecognisingWhenWeSucceedAndRewardingOurselves]: {
    en: `Recognising when we succeed and rewarding ourselves for our progress.`,
    sv: `Uppmärksamma när vi lyckas och belöna sig själv för sina framsteg.`
  },
  [TrVar.RecommendationTypeNps]: {
    en: `How likely is it that you would recommend Zebrain to a colleague or a friend?`,
    sv: 'Hur troligt är det att du skulle rekommendera Zebrain till en kollega eller vän?'
  },
  [TrVar.RecommendationTypeNpsCoach]: {
    en: `How likely is it that you would recommend your coach to a colleague or a friend?`,
    sv: 'Hur troligt är det att du skulle rekommendera din coach till en kollega eller vän?'
  },
  [TrVar.RecoverPassword]: {
    en: `Recover password`,
    sv: 'Återställ lösenord'
  },
  [TrVar.RedoTheAnalysis]: {
    en: `Redo the analysis`,
    sv: 'Gör om analysen'
  },
  [TrVar.References]: {
    en: `References`,
    sv: 'Referenser'
  },
  [TrVar.Reflection]: {
    en: `Reflection`,
    sv: 'Reflektion'
  },
  [TrVar.Reject]: {
    en: `Reject`,
    sv: 'Avböj'
  },
  [TrVar.RejectedByYou]: {
    en: `Rejected by you`,
    sv: 'Avböjt av dig'
  },
  [TrVar.Relations]: {
    en: `Relations.`,
    sv: 'Relationer.'
  },
  [TrVar.RelationsDescription]: {
    en: `Receive guidance on how to examine and strengthen your relations.`,
    sv: `Få guidning i att kartlägga och stärka dina relationer.`
  },
  [TrVar.RememberToFollowUpYourPlan]: {
    en: `Remember to follow up your plan!`,
    sv: 'Kom ihåg att följa upp din plan!'
  },
  [TrVar.RememberToTryToBePatientAndNotExpectInstantResult]: {
    en: `Remember to try to be patient and not to expect instant results. Changing 
                          ourselves is a process that can require both patience and
                          perseverance - but it should also feel pleasurable.
                          Give yourself time to create new healthy habits that
                          are good for you in the long term.`,
    sv: `Tänk på att försöka ha tålamod och inte förvänta dig för snabba resultat. 
                          Att förändra oss själva är en process som kan kräva både 
                          tålamod och uthållighet - men den ska också kännas lustfylld. 
                          Ge dig själv tid att skapa nya goda vanor som är bra för dig 
                          på lång sikt.`
  },
  [TrVar.Reminder]: {
    en: 'Reminder',
    sv: 'Påminnelse'
  },
  [TrVar.Remove]: {
    en: 'Remove',
    sv: 'Ta bort'
  },
  [TrVar.RemoveSuggestion]: {
    en: 'Remove suggestion',
    sv: 'Ta bort förslag'
  },
  [TrVar.RepeatPassword]: {
    en: 'Repeat password',
    sv: 'Upprepa lösenord'
  },
  [TrVar.ReportInterest]: {
    en: 'Report interest',
    sv: 'Anmäl intresse'
  },  
  [TrVar.Reschedule]: {
    en: 'Reschedule',
    sv: 'Boka om'
  },
  [TrVar.RescheduleClientMeeting]: {
    en: 'Reschedule client meeting',
    sv: 'Ombokning av klientmöte'
  },
  [TrVar.RescheduleMeeting]: {
    en: 'Reschedule meeting',
    sv: 'Boka om möte'
  },
  [TrVar.ResearchBehind]: {
    en: 'The research behind',
    sv: 'Forskningen bakom'
  },
  [TrVar.ResetPassword]: {
    en: 'Reset password',
    sv: 'Återställ lösenord'
  },
  [TrVar.Result]: {
    en: 'Result',
    sv: 'Resultat'
  },
  [TrVar.ResultFromYourMeasurement]: {
    en: 'Result from your measurement',
    sv: 'Resultat av din mätning'
  },
  [TrVar.ResultPerQuestion]: {
    en: 'Result per question',
    sv: 'Resultat per fråga'
  },
  [TrVar.ResultsPerArea]: {
    en: 'See your results in each area',
    sv: 'Se ditt resultat per område'
  },
  [TrVar.Review]: {
    en: 'Review answers',
    sv: 'Se svar'
  },
  [TrVar.RevisedNEOPersonality]: {
    en: 'Revised NEO Personality Inventory.',
    sv: 'Revised NEO Personality Inventory.'
  },
  [TrVar.Right]: {
    en: 'Right',
    sv: 'Höger'
  },
  [TrVar.RightNowWeHaveManyApplicants]: {
    en: 'Right now we have enough coaches. If we need more coaches in the future, we will contact you - provided that your profile is complete and matches our customers needs.',
    sv: 'Just nu har vi tillräckligt med coacher. Om vi skulle behöva fler coacher framöver, kommer vi att kontakta dig - förutsatt att din profil är komplett och matchar våra kunders behov.'
  },
  [TrVar.Rotate]: {
    en: 'Rotate',
    sv: 'Rotera'
  },
  [TrVar.Save]: {
    en: 'Save',
    sv: 'Spara'
  },
  [TrVar.SaveYourResults]: {
    en: 'Save your results and login when you want',
    sv: 'Spara dina resultat och logga in när du vill'
  },
  [TrVar.ScanningCoaches]: {
    en: 'Scanning coaches',
    sv: 'Scannar coacher'
  },
  [TrVar.ScheduleAMeeting]: {
    en: 'Schedule a meeting',
    sv: 'Lägg till mötestider'
  },
  [TrVar.SCMStereotypeContentModel]: {
    en: 'SCM, Stereotype Content Model.',
    sv: 'SCM, Stereotype Content Model.'
  },
  [TrVar.SeeChat]: {
    en: 'See chat',
    sv: 'Se chatt'
  },  
  [TrVar.SeeFinishedExercises]: {
    en: 'You can see your finished exercises here',
    sv: 'Här kan du se dina avslutade övningar'
  },  
  [TrVar.SeeFollowUpExercises]: {
    en: 'You can see your exercises ready to follow-up here',
    sv: 'Här kan du se vilka av dina övningar som behöver följas upp'
  },
  [TrVar.SeeOngoingExercises]: {
    en: 'You can see your on-going exercises here',
    sv: 'Här kan du se dina pågående övningar'
  },
  [TrVar.SeeQuestions]: {
    en: 'See questions',
    sv: 'Se frågor'
  },
  [TrVar.SeeVisionboard]: {
    en: 'See meeting notes',
    sv: 'Se mötesanteckningar'
  },
  [TrVar.SeeWholeProfile]: {
    en: 'See whole profile',
    sv: 'Se hela profilen'
  },
  [TrVar.SeeYourselfSucceed]: {
    en: 'To see yourself succeed.',
    sv: 'Se själv att man lyckas.'
  },
  [TrVar.Sek]: {
    en: 'Sek',
    sv: 'Kr'
  },
  [TrVar.Select]: {
    en: 'Select',
    sv: 'Välj'
  },
  [TrVar.SelectChat]: {
    en: 'Select chat',
    sv: 'Välj chatt'
  },
  [TrVar.SelectCoach]: {
    en: 'Select coach',
    sv: 'Välj coach'
  },
  [TrVar.SelectLanguageOfYourCoach]: {
    en: 'Select language of your coach',
    sv: 'Välj coachens språk'
  },
  [TrVar.SelectLanguagesYouCanCoachIn]: {
    en: 'Select languages you can coach in',
    sv: 'Välj språk du kan coacha i'
  },
  [TrVar.SelectNewTime]: {
    en: 'Select new time',
    sv: 'Välj ny tid'
  },
  [TrVar.SelfInsight]: {
    en: 'Self-insight',
    sv: 'Självinsikt'
  },
  [TrVar.SelfInsightSub1]: {
    en: `Self-insight means having an understanding of the feelings, 
                thoughts and impulses that are constantly occurring
                in our inner world. When we have a clear self-insight,
                we can anticipate and manage our reactions to things
                that happen to us. Self-insight is also about knowing
                ourselves - what we are good at, our limitations and
                what we need in order to thrive. The more we know
                about ourselves, the better we are at making long-term
                sustainable decisions that benefit both our well-being
                and our ability to perform.`,
    sv: `Att ha självinsikt innebär att ha förståelse 
                för de känslor, tankar eller impulser som ständigt 
                pågår i vår inre värld. Med bra självinsikt kan 
                vi förutse och parera våra reaktioner på sådant 
                som händer. Självinsikt handlar också om att känna 
                oss själva - vad vi är bra på, våra begränsningar 
                och vad vi behöver för att må bra. Ju mer vi vet 
                om oss själva, desto bättre är vi på att ta 
                långsiktigt hållbara beslut som gynnar både vårt 
                mående och vår förmåga att prestera.`
  },
  [TrVar.SelfInsightSub2]: {
    en: `Self-insight is essential for successful self-leadership, and is well documented in research.`,
    sv: `Att självinsikt är nödvändigt för ett framgångsrikt självledarskap är väl dokumenterat i forskning.`
  },
  [TrVar.SelfInsightDescription]: {
    en: `Get to know your strengths, limitations and what you need in order to feel good.`,
    sv: 'Lär känna dina styrkor, begränsningar och vad du behöver för att må bra.'
  },
  [TrVar.SelfInsightIsANecessaryFoundation]: {
  en: `The fact that self-insight is a necessary foundation for a successful self-leadership is well
                  documented in research (amongst others Manz 1992, Neck & Houghton 2006, D'Intino 2007).
                  By observing your thoughts, feelings, and behaviours as well as how others react, you
                  will create the prerequisites to lead yourself to success.
                  `,
    sv: `Att självinsikt är en nödvändig grund för ett framgångsrikt självledarskap är väl
                  dokumenterat i forskning (bl.a Manz 1992, Neck & Houghton 2006, och D'Intino 2007).
                  Genom att observera sina tankar, känslor och beteenden samt hur andra reagerar
                  skapar man förutsättningar för att leda sig själv till framgång.
                  `
  },
  [TrVar.SelfInsightIsTheFoundationToAllDevelopment]: {
    en: `Self-insight is the foundation to all development and success. To be conscious of
                  your strengths and understand that your driving forces as well as your obstacles
                  are equally crucial for the company as the individual. The more we know what is
                  important in our lives, where we are going, what we want to accomplish, our
                  strengths, how we affect others and our limitations, the more opportunities do we
                  get. When you develop your self-insight it will help you achieve 
                  your most important goals.
                  `,
    sv: `Självinsikt är grunden till all utveckling och framgång. Att vara medveten om sina
                  styrkor och förstå sina drivkrafter likaväl som sina hinder är lika avgörande för
                  företaget som för den enskilda individen. Ju mer vi känner till vad som är viktigt
                  i våra liv, vart vi är på väg, vad vi vill uppnå, våra styrkor, hur vi påverkar
                  andra och våra begränsningar, desto större möjligheter får vi. Att utveckla din
                  självinsikt kommer hjälpa dig att nå dina viktigaste mål.
                  `
  },
  [TrVar.SelfLeadership]: {
    en: 'Self-leadership',
    sv: 'Självledarskap'
  },
  [TrVar.SelfLeadershipSub1]: {
    en: `Self-leadership is the ability to act with intention based on what is important 
                  to us and where we want to be. It can be compared to being in the driver's
                  seat of a car: from the driver's seat we control the accelerator, the
                  brakes and the direction we travel. The opposite is sitting in the back
                  seat with no control over the car's direction or speed.`,
    sv: `Självledarskap är förmågan att agera utifrån vad som är viktigt för oss. Det kan 
                  liknas vid att sitta i förarsätet i en bil: Från förarsätet kontrollerar 
                  vi gas, broms och åt vilket håll vi färdas. Motsatsen är att sitta i 
                  baksätet utan möjlighet att påverka bilens riktning eller fart.`
  },  
  [TrVar.SelfLeadershipSub2]: {
    en: `Factors that influence how good we are at leading ourselves are self-awareness, 
                  good basic energy and knowing what we need in order to feel motivated.`,
    sv: `Faktorer som påverkar hur bra vi är på att leda oss själva är att ha självinsikt, 
                  bra grundenergi och att veta vad vi behöver för att känna motivation.`
  },    
  [TrVar.SelfLeadershipSub3]: {
    en: `Our measurement of self-leadership is based on factors that influence how capable we are of achieving things that are important to us.`,
    sv: `Vår mätning av självledarskap bygger på faktorer som påverkar hur väl vi lyckas uppnå sådant som är viktigt för oss.`
  },  
  [TrVar.SelfLeadershipIsTheAbilityToWorkOnOurOwn]: {
    en: `With self-leadership we mean the ability to work on our own to achieve what
                  is important for us. The test is built on the factors which are behind how well
                  we succeed with this, the factors that we can affect ourselves. Exactly what factors
                  these are depend on what you have chosen to work with during your developmental
                  process.
                  `,
    sv: `Med självledarskap menar vi förmågan att ta oss själva till det som är viktigt
                  för oss. Testet bygger på de faktorer som ligger bakom hur väl vi lyckas med
                  detta, faktorer vi själva kan påverka. Exakt vilka faktorer det handlar om beror
                  på vad du valt att arbeta med under din utvecklingsprocess.
                  `
  },
  [TrVar.SelfLeadershipWasIntroducedInLiterature]: {
    en: `The term 'self-leadership' was introduced in literature (Manz 1986; Manz & Sims 1991) 
                  with the purpose of working more efficiently. This research shows that individuals who practice a systematic
                  self-leadership process have a larger likelihood to perform, both in their private and
                  professional life (DiLiello & Houghton 2006).
                  `,
    sv: `Begreppet "Självledarskap" introducerades först i litteraturen som en
                  självpåverkansprocess (Manz 1986; Manz & Sims 1991) med syfte att arbeta mer
                  effektivt. Forskning visar att individer som praktiserar ett systematiskt
                  självledarskap har större sannolikhet för att prestera, både i privat och i
                  arbetslivet (DiLiello & Houghton 2006).
                  `
  },
  [TrVar.Send]: {
    en: 'Send',
    sv: 'Skicka'
  },
  [TrVar.SendAEmailToSupportAndWeWillHelpYouOut]: {
    en: 'Send an email to support@zebrain.se and describe your issue and we will help you.',
    sv: 'Mejla support@zebrain.se och beskriv problemet så hjälper vi dig.'
  },
  [TrVar.SendAMailToSupportAndWeWillHelpYou]: {
    en: 'Send a mail to support@zebrian.se and we will help you create an account.',
    sv: 'Skicka ett mejl till support@zebrain.se, så hjälper vi dig att skapa ett konto.'
  },
  [TrVar.SenseOfMeaningfulness]: {
    en: 'Sense of meaningfulness',
    sv: 'Känna meningsfullhet'
  },
  [TrVar.Settings]: {
    en: 'Settings',
    sv: 'Inställningar'
  },
  [TrVar.SettingYourOwnClearGoals]: {
    en: 'Setting your own clear goals.',
    sv: 'Att sätta egna tydliga mål.'
  },
  [TrVar.SetUpAccount]: {
    en: 'Set up account',
    sv: 'Skapa ditt konto'
  },
  [TrVar.ShareLinkBody]: {
    en: 'I want to inform you about Zebrain, an exciting digital platform for employee development through coaching. Coaching has an average ROI on 700% and I think this could give us all a real boost. Check this link out for more information: https://www.zebrain.se/en/how-zebrain-works',
    sv: 'Jag vill tipsa dig om Zebrain, en spännande digital plattform för medarbetarutveckling genom coaching. Coaching har en ROI på i snitt 700 % och jag tror att detta kan ge oss alla en riktig boost. Kolla här om du vill veta mer: https://www.zebrain.se/sv/sa-funkar-det'
  },
  [TrVar.ShareLinkTitle]: {
    en: 'Information how we can perform and feel better!',
    sv: 'Tips för hur vi kan prestera och må bättre!'
  },
  [TrVar.SharonParkerDescription]: {
    en: `Sharon Parker is one, out of many, who have proved inner motivation is
                  necessary to achieve self-leadership, but not enough to achieve success.
                  To succeed it is important to set clear goals and clear expectations.
                  (Gisela Bäcklander, doctoral dissertation from KTH, "Autonomous, yet Aligned:
                  Challenges of self-leadership in context" 2020).
                  `,
    sv: `Sharon Parker är en av många som har visat att inre motivation är nödvändigt
                  för att uppnå självledarskap, men inte tillräckligt för att uppnå framgång.
                  För att lyckas är det viktigt att sätta tydliga mål och tydliga förväntningar.
                  (Gisela Bäcklander, doktorsavhandling från KTH, "Autonomous, yet Aligned:
                  Challenges of self-leadership in context" 2020).
                  `
  },
  [TrVar.SignedOut]: {
    en: 'You have been signed out',
    sv: 'Du har loggats ut'
  },
  [TrVar.SignInWithEmail]: {
    en: 'Sign in with email',
    sv: 'Logga in med mejl'
  },
  [TrVar.SignInWithNewPassword]: {
    en: 'Sign in with new password:',
    sv: 'Logga in med nya lösenordet:'
  },
  [TrVar.SignOut]: {
    en: 'Sign out',
    sv: 'Logga ut'
  },
  [TrVar.SinceLastMeasurement]: {
    en: 'Since previous measurement',
    sv: 'Sedan förra mätningen'
  },
  [TrVar.SinceYouStarted]: {
    en: 'Since you started',
    sv: 'Sedan du startade'
  },
  [TrVar.SituationAfterCoaching]: {
    en: 'How do you want your situation to be?',
    sv: 'Hur vill du att din situation ska vara?'
  },
  [TrVar.ShortestMeetingTimeIs30Min]: {
    en: 'Shortest meeting time is 30 minutes',
    sv: 'Minsta tid är en halvtimme'
  },
  [TrVar.ShortSummary]: {
    en: 'Short summary',
    sv: 'Kort sammanfattning'
  },
  [TrVar.Show]: {
    en: 'Show',
    sv: 'Visa'
  },
  [TrVar.ShowingMeetingsWith]: {
    en: 'Showing meetings with',
    sv: 'Visar möten med'
  },
  [TrVar.ShowLanguages]: {
    en: 'Show language',
    sv: 'Visa språk'
  },
  [TrVar.ShowNotes]: {
    en: 'Show notes',
    sv: 'Visa anteckningar'
  },
  [TrVar.ShowsAmongOtherThingsTheImportanceOfAdapting]: {
    en: `Shows, among other things, the importance of adapting to and meeting others
                  who do not resemble oneself. Merril or Reid.`,
    sv: `Visar bland annat på betydelsen av att anpassa sig till och möta andra som inte
                  liknar en själv. Merril o Reid.`
  },
  [TrVar.ShowsChangeInCurrentStateSincePreviousMeeting]: {
    en: 'Shows change in current state since previous meeting',
    sv: 'Visar ändring i nu-läge sendan föregående mätning'
  },
  [TrVar.ShowVideo]: {
    en: 'Show video',
    sv: 'Visa video'
  },
  [TrVar.Smaller]: {
    en: 'Smaller',
    sv: 'Mindre'
  },
  [TrVar.SocialStyles]: {
    en: 'Social Styles.',
    sv: 'Social Styles.'
  },
  [TrVar.Start]: {
    en: 'Start',
    sv: 'Start'
  },
  [TrVar.StartBankIdPhone]: {
    en: 'Start BankID on your mobile device...',
    sv: 'Starta mobilt BankID på din mobiltelefon...'
  },
  [TrVar.Started]: {
    en: 'Started',
    sv: 'Påbörjad'
  },
  [TrVar.StartState]: {
    en: `Starting state`,
    sv: 'Startläge'
  },
  [TrVar.StartPresenting]: {
    en: `Present`,
    sv: 'Presentera'
  },
  [TrVar.StartWorkingWithYourPotential]: {
    en: `Start working with your potential`,
    sv: 'Börja jobba med din potential'
  },
  [TrVar.Statement]: {
    en: `Statement`,
    sv: 'Påstående'
  },
  [TrVar.Statements]: {
    en: `Statements`,
    sv: 'Påståenden'
  },
  [TrVar.Statistics]: {
    en: `Statistics`,
    sv: 'Statistik'
  },
  [TrVar.STCSystemsCenteredTherapy]: {
    en: `STC, Systems Centered Therapy.`,
    sv: 'STC, Systems Centered Therapy.'
  },
  [TrVar.StopPresenting]: {
    en: `Stop presenting`,
    sv: 'Sluta presentera'
  },
  [TrVar.StoreProfileForTwelveMonths]: {
    en: 'Your profile is stored for 12 months',
    sv: 'Din profil sparas i 12 månader'
  },
  [TrVar.Strengths]: {
    en: `Strengths`,
    sv: 'Styrkor'
  },
  [TrVar.StressDescription]: {
    en: `Learn how to identify and manage stress factors in your life.`,
    sv: `Lär dig identifiera och hantera stressfaktorer i ditt liv.`
  },
  [TrVar.SubmitCoachRequest]: {
    en: 'Sumbit interest',
    sv: 'Anmäl intresse'
  },
  [TrVar.SubscribeFromWeb]: {
    en: 'Subscribe from web',
    sv: 'Prenumerera från Webben'
  },
  [TrVar.Subscription]: {
    en: 'Subscription',
    sv: 'Prenumeration'
  },
  [TrVar.SubscriptionWillBeRenewed]: {
    en: 'Subscription will be renewed automatically',
    sv: 'Prenumerationen förnyas automatiskt'
  },
  [TrVar.SubscriptionWillEnd]: {
    en: 'Subscription will end',
    sv: 'Prenumerationen avslutas'
  },  
  [TrVar.SuggestedMeetings]: {
    en: `Suggested meetings`,
    sv: 'Föreslagna möten'
  },
  [TrVar.SuggestMeeting]: {
    en: 'Suggest meeting',
    sv: 'Föreslå möte'
  },
  [TrVar.SuggestMeetingToClient]: {
    en: 'Suggest meeting to client',
    sv: 'Föreslå mötestid till klient'
  },
  [TrVar.Summary]: {
    en: 'Summary',
    sv: 'Sammanfattning'
  },
  [TrVar.SummaryBillableMeetings]: {
    en: 'Summary billable meetings',
    sv: 'Sammanfattning debiterbara möten'
  },
  [TrVar.SumOfAndTheRelationshipBetweenTheseFourFactors]: {
    en: 'The sum of, and the relationship between these four factors, we call your',
    sv: 'Summan av, och förhållandet mellan dessa fyra faktorer har vi valt att kalla din'
  },
  [TrVar.Support]: {
    en: 'Support',
    sv: 'Support'
  },
  [TrVar.SupportAtZebrainSe]: {
    en: 'Support@zebrain.se',
    sv: 'Support@zebrain.se'
  },
  [TrVar.SupportAndChallengeYouInTheProcess]: {
    en: 'Support and challenge you in the process of achieving your goals.',
    sv: 'Stötta och utmana dig i processen att nå dina mål.'
  },
  [TrVar.Supporting]: {
    en: 'Supporting',
    sv: 'Stödjande'
  },
  [TrVar.SupportingBehaviour]: {
    en: 'Supporting behaviour',
    sv: 'Supporting behaviour'
  },
  [TrVar.SupportingDesc]: {
    en: 'When we use our supportive behaviour, we are responsive and attentive to the needs of others. We have a calm and humble demeanour and are often very patient in demanding situations.',
    sv: 'När vi använder vårt stödjande beteende är vi lyhörda och uppmärksamma på andras behov. Vi har en lugn och ödmjuk framtoning och har ofta stort tålamod i krävande situationer.'
  },
  [TrVar.SwitchToClient]: {
    en:'Switch to client',
    sv:'Växla till klient'
  },
  [TrVar.SwitchToCoach]: {
    en: 'Switch to coach',
    sv: 'Växla till coach'
  },
  [TrVar.TakeALookAtYourPotentialAssessmentOrRecommendedDevelopmentAreasForMoreIdeas]: {
    en: 'Take a look at your potential assessment or recommended development areas for more ideas.',
    sv: 'Kika på din potentialmätning eller rekommenderade utvecklingsområden för fler idéer.'
  },
  [TrVar.TakeTheShortcutToFullPotential]: {
    en: 'Take the shortcut to full potential',
    sv: 'Ta genvägen till full potential'
  },  
  [TrVar.TechnicalProblemsTheClientGetsAnotherMeeting]: {
    en: 'Technical problems - the client gets another meeting, but you get paid for the meeting',
    sv: 'Teknikproblem - klienten får nytt möte, men du får betalt för mötet'
  },  
  [TrVar.TelephoneNumber]: {
    en: '0768-93 62 65',
    sv: '0768-93 62 65'
  }, 
  [TrVar.Terminate]: {
    en: 'Terminate',
    sv: 'Avsluta'
  },  
  [TrVar.Terminated]: {
    en: 'Terminated',
    sv: 'Uppsagd'
  },
  [TrVar.TestChatCoach]: {
    sv: 'Prova på AI coaching!',
    en: 'Try AI coaching!'
  },
  [TrVar.TestMeetingBeforeCoachSession]: {
    en: 'Test the video call before the session.',
    sv: 'Testa videosamtalet innan mötet.'
  },
  [TrVar.TextAboutMeAsCoach]: {
    en: 'Short text about you as coach',
    sv: 'Kort text om dig som coach'
  },
  [TrVar.TextHere]: {
    en: 'Text here',
    sv: 'Text här'
  },
  [TrVar.Thanks]: {
    en: 'Thank you!',
    sv: 'Tack!'
  },
  [TrVar.ThanksCya]: {
    en: 'Thank you for this session. See you again soon!',
    sv: 'Tack för den här gången. Vi ses snart igen!'
  },
  [TrVar.ThanksWhenZebrainHasApproved]: {
    en: 'Thank you! As soon as Zebrain has approved your application, you can start receiving clients.',
    sv: 'Tack! Så fort Zebrain har godkänt din ansökan kan du börja ta emot klienter.'
  },
  [TrVar.ThatTimeHasAlreadyPassed]: {
    en: 'That times has already passed',
    sv: 'Den tiden har redan passerats'
  },
  [TrVar.TheAbilityToUnderstandAndActAccording]: {
    en: 'The ability to understand and act according to what is important to you.',
    sv: 'Förmågan att agera utifrån vad som är viktigt.'
  },
  [TrVar.TheChat]: {
    en: 'The chat',
    sv: 'Chatten'
  },
  [TrVar.TheLicense]: {
    en: 'The license',
    sv: 'Licensen'
  },
  [TrVar.TheMeetingIsBooked]: {
    en: 'The meeting is booked. Good luck!',
    sv: 'Mötet är bokat. Lycka till!'
  },
  [TrVar.TheLinkThatTookYouHereIsNotMeantForFirstTimeUsers]: {
    en: 'The link that took you here is not meant for first time users.',
    sv: 'Den här länken är inte menad för förstagångsanvändare.'
  },
  [TrVar.TheMeetingTookPlaceInSpiteOfProblems]: {
    en: 'The meeting took place in spite of problems',
    sv: 'Mötet blev av trots teknikproblem'
  },
  [TrVar.ThereAreNoConnectionsBetweenOurPersonalitiesOrOurDifferentBehaviouralPatterns]: {
    en: `Our algorithm is based on the four behaviors of Supportive, Engaging, Driving and Detailed. 
                It calculates the distribution between the different behaviors and how this combination
                characterizes your behavior.`,
    sv: `Vår algoritm baseras på de fyra beteendena Stödjande, Drivande, Detaljerade och Engagerande. 
                Den beräknar hur stor fördelningen är mellan de olika beteendena och hur denna kombination 
                utmärker ditt sätt att agera.`
  },
  [TrVar.ThereIsAHelpSectionInTheVideoClientWhereYouCanFindSolutions]: {
    en: `There is a help section in the video client where you can find solutions for the most
                              common technical issues. If the issue still remains, chat with your coach or email
                               support@zebrain.se.`,
    sv: `I videoklienten finns ett Hjälp-avsnitt där du hittar lösningar på de vanligaste tekniska
                              problemen. Om problemet kvarstår, chatta med din coach eller mejla
                               support@zebrain.se.`
  },
  [TrVar.ThereIsNoSavedConversation]: {
    en: 'There is no saved conversation from this meeting',
    sv: 'Det finns ingen sparad konversation för detta möte'
  },
  [TrVar.TheRoleOfTheClientIsTo]: {
    en: 'The role of the client is to',
    sv: 'Klientens roll är att'
  },
  [TrVar.TheRoleOfTheCoachIsTo]: {
    en: 'The role of the coach is to',
    sv: 'Coachens roll är att'
  },
  [TrVar.TheRoleOfTheCoachIsToSupportAndChallengeYouInTheProcessOfReachingYourGoals]: {
    en: `The coach's role is to support and challenge you in the process of reaching your goals and realizing 
                  your potential. A fundamental belief in coaching is that everyone has their own answers.
                  Therefore, we work extensively with questions that develop and motivate you to reflect
                  and take action. An important part of the process is setting and following up on your
                  goals.`,
    sv: `Coachens roll är att stötta och utmana dig i processen att nå dina mål och förverkliga din potential. 
                  En grundinställning inom coaching är att alla människor har sina egna svar. Därför arbetar vi 
                  mycket med frågor som utvecklar och motiverar dig till egen reflektion och handling. En viktig 
                  del i processen är att sätta och följa upp dina mål.`
  },
  [TrVar.TheseFactorsNeedToBeSufficientlyFulfilled]: {
    en: `These factors need to be sufficiently fulfilled to not diminish our motivation.
                  `,
    sv: `Dessa faktorer behöver vara tillräckligt uppfyllda för att inte dra ner vår
                  motivation.
                  `
  },
  [TrVar.TheseNotesAreOnlyVisible]: {
    en: 'These notes are only visible to you',
    sv: 'Här kan du skriva anteckningar om klienten som bara du kan se'
  },
  [TrVar.ThinkAbout]: {
    en: 'Think about',
    sv: 'Tänk på'
  },
  [TrVar.ThisIsBecauseTheLicenseLacksEndDateOrLimitedNumberOfMeetings]: {
    en: 'This is because the license lacks end date or limited number of meetings.',
    sv: 'Detta beror på att licensen saknar slutdatum eller begränsat antal möten.'
  },
  [TrVar.ThisIsHowYouAccessYourUntappedPotential]: {
    en: `This is how you access your untapped potential`,
    sv: `Så kommer du åt din oanvända potential`
  },
  [TrVar.ThisIsHowYouAccessYourUntappedPotentialSub1]: {
    en: `In our platform, you receive guidance on how 
                    to start using your untapped potential.
                    Through coaching and working with
                    your individual development areas,
                    you get the tools you need in order
                    to reach your desired state.`,
    sv: `I vår plattform får du vägledning i hur du 
                    kan börja använda din outnyttjade
                    potential. Genom coaching och att 
                    jobba med dina personligt anpassade 
                    utvecklingsområden, får du de 
                    verktyg du behöver för att nå 
                    ditt önskade läge.`
  },
  [TrVar.ThisIsHowYouAccessYourUntappedPotentialSub2]: {
    en: `During the time you work with the platform, we recommend 
                    that you continuously measure your potential to
                    see your progress. You don't have to redo the
                    entire measurement from scratch, just look
                    through it and adjust if your current state
                    or desired state has changed`,
    sv: `Under tiden du jobbar i plattformen rekommenderar 
                    vi att du kontinuerligt gör nya potential 
                    mätningar för att se dina framsteg. Du behöver 
                    inte göra om hela mätningen från noll, bara 
                    titta igenom den och justera om ditt nuläge 
                    eller önskade läge har förändrats.`
  },
  [TrVar.ThisIsIncluded]: {
    en: 'This is included',
    sv: 'Detta ingår'
  },
  [TrVar.ThisLicenseHasExpired]: {
    en: 'This license has expired',
    sv: 'Denna licens är inte giltig längre'
  },
  [TrVar.ThisLicenseHasNoAvailableSeats]: {
    en: 'This license has no available seats',
    sv: 'Denna licens har inga lediga platser'
  },
  [TrVar.ThisLinkHasExpired]: {
    en: 'This link has expired',
    sv: 'Giltighetstiden för länken har gått ut'
  },
  [TrVar.ThisLinkNoLongerWorks]: {
    en: 'This link no longer works.',
    sv: 'Du har klickat på en länk som inte längre fungerar.'
  },
  [TrVar.Time]: {
    en: 'Time',
    sv: 'Tid'
  },
  [TrVar.TimeAvailability]: {
    en: 'Time between booking and meeting',
    sv: 'Tid mellan bokning och möte'
  },
  [TrVar.TimeElevenFifteen]: {
    en: '11:15',
    sv: '11:15'
  },
  [TrVar.TimeElevenOClock]: {
    en: '11:00',
    sv: '11:00'
  },
  [TrVar.TimeFrequency]: {
    en: 'Time',
    sv: 'Gång'
  },
  [TrVar.TimeNineFortyFive]: {
    en: '09:45',
    sv: '09:45'
  },
  [TrVar.TimeNineOClock]: {
    en: '09:00',
    sv: '09:00'
  },  
  [TrVar.TimeTenOClock]: {
    en: '10:00',
    sv: '10:00'
  },  
  [TrVar.TimeTenThirty]: {
    en: '10:30',
    sv: '10:30'
  },
  [TrVar.TimesFrequency]: {
    en: 'Times',
    sv: 'Gånger'
  },
  [TrVar.TimeForFollowup]: {
    en: 'Time to track your performance',
    sv: 'Dags för uppföljning'
  },
  [TrVar.TimesPerMonth]: {
    en: `Times / month`,
    sv: 'Gånger / månad'
  },
  [TrVar.Tip]: {
    en: 'Tip',
    sv: 'Tips'
  },
  [TrVar.TipThanks]: {
    en: 'Thanks for the tip!',
    sv: 'Tack för tipset!'
  },
  [TrVar.TipSomeone]: {
    en: 'Tip someone',
    sv: 'Tipsa någon'
  },
  [TrVar.TipSomeoneYouLike]: {
    en: 'Tip someone you like',
    sv: 'Tipsa någon du gillar'
  },
  [TrVar.TipYourBoss]: {
    en: 'Tip your boss',
    sv: 'Tipsa chefen'
  },
  [TrVar.To]: {
    en: 'To',
    sv: 'Till'
  },
  [TrVar.ToCalendar]: {
    en: 'To the calendar',
    sv: 'Till kalendern'
  },
  [TrVar.ToChat]: {
    en: 'To chat',
    sv: 'Till chatt'
  },
  [TrVar.ToContinuallyEvaluateOurActionsOverALongPeriod]: {
    en: `To continually evaluate our actions over a long period of time`,
    sv: `Att kontinuerlig utvärdering vårt agerande under lång tid`
  },
  [TrVar.ToCreateAnAccountInZebrainYouNeedAnInvitation]: {
    en: 'To create an account in Zebrain you need an invitation link.',
    sv: 'För att skapa ett konto i Zebrain behöver du en inbjudningslänk.'
  },
  [TrVar.Today]: {
    en: 'Today',
    sv: 'Idag'
  },
  [TrVar.ToEvaluateAndImproveThePlatformItMayInSomeSituationsBeNecessaryToReview]: {
    en: 'To evaluate and improve the platform, it may in some situations be necessary to review user data. This is done only by a limited number of people who are carefully selected and trained to treat data with the highest respect and integrity. The purpose of this review is solely to optimize and improve the functionality and user experience of the platform, while protecting the privacy of users.',
    sv: 'För att utvärdera och förbättra plattformen kan det i vissa situationer vara nödvändigt att granska användardata. Detta görs enbart av ett begränsat antal personer som är noggrant utvalda och utbildade för att behandla data med högsta respekt och integritet. Syftet med denna granskning är enbart att optimera och förbättra plattformens funktioner och användarupplevelse, samtidigt som användarnas integritet skyddas.'
  },
  [TrVar.ToFeelReallyGood]: {
    en: 'To feel really good needs',
    sv: 'För att må riktigt bra behöver'
  },
  [TrVar.ToGetIntoTheHabitOfWorkingOnYourPersonal]: {
    en: `To get into the habit of working on your personal development, we 
                          recommend that you work a little and often rather
                          than rarely and a lot. That way your efforts will
                          have the greatest impact long term. For example:
                          do 1-2 exercises 2-3 times a week. We encourage
                          you to set aside time in your calendar. `,
    sv: `För att få in vanan att jobba med din personliga utveckling rekommenderar 
                          vi att du hellre gör lite och ofta än sällan och mycket. 
                          Då har din insats som störst effekt på lång sikt. 
                          Exempelvis: Gör 1-2 övningar 2-3 gånger i veckan. 
                          Planera gärna in tiden i din kalender. `
  },
  [TrVar.ToMeasurement]: {
    en: 'To measurement',
    sv: 'Till mätning'
  },
  [TrVar.TooBigImgError]: {
    en: 'This file is too large. Please choose a file that is smaller than 2 MB',
    sv: 'Den här filen är för stor. Välj en fil som är mindre än 2 MB'
  },
  [TrVar.TooMuchGoingOnInYourHead]: {
    en: 'Too much going on in your head? Start a conversation with a coach',
    sv: 'För mycket i huvudet? Börja chatta med en coach'
  },
  [TrVar.ToOverview]: {
    en: 'To Overview',
    sv: 'Till Översikt'
  },
  [TrVar.ToReadMoreAboutHowWeHandleYourPersonalInformation]: {
    en: 'To read more about how we handle your personal information, go to',
    sv: 'Om du vill läsa mer om hur vi hanterar dina personuppgifter, gå in på'
  },
  [TrVar.Total]: {
    en: 'Total',
    sv: 'Totalt'
  },
  [TrVar.TotalPotential]: {
    en: 'Total potential',
    sv: 'Totala potential'
  },
  [TrVar.TotalTime]: {
    en: 'Total time',
    sv: 'Total tid'
  },
  [TrVar.Trainer]: {
    en: 'Trainer',
    sv: 'Trainer'
  },
  [TrVar.TreatTheContentOfTheConversationsPersonalTrustPrivateRelations]: {
    en: 'Treat the content of the conversations, personal trust, private relationships, etc. completely confidentially.',
    sv: 'Behandla innehållet i samtalen, personliga förtroenden, privata förhållanden etc. helt konfidentiellt.'
  },
  [TrVar.TwelveDigitalCoachQeustions]: {
    en: '12 digital coach questions',
    sv: '12st digitala coachfrågor'
  },
  [TrVar.UnBookable]: {
    en: 'Unbookable',
    sv: 'Ej bokbar'
  },  
  [TrVar.UncancelTermination]: {
    en: 'Uncancel termination',
    sv: 'Ångra uppsägning'
  },
  [TrVar.UnexpectedErrorOccurred]: {
    en: 'An unexpected error occurred. Please try again in a moment.',
    sv: 'Ett oväntat fel inträffade. Vänligen pröva igen om en stund.'
  },
  [TrVar.UnfortunatelyTheMeetingWillBeUsed]: {
    en: 'Unfortunately, that meeting will be considered used.',
    sv: 'Då går tyvärr det mötestillfället förlorat.'
  },
  [TrVar.UnfortunatelyYouWillLoseMeetingsYouHaventUsed]: {
    en: `Unfortunately, you will lose meetings you haven't used.
                              Try to schedule all of your meetings the first time you speak with your
                              coach. If you have coaching through your company, please ask your
                              manager to extend your license.`,
    sv: `Du förlorar tyvärr de möten som du inte nyttjat. Försök
                              boka alla möten när du pratar med din coach första gången.
                              Om du har coaching via företaget, be gärna din chef förlänga
                              licensen.`
  },  
  [TrVar.UniqueMatching]: {
    en: 'Unique matching',
    sv: 'Unik matching'
  },
  [TrVar.UnknownLicenseError]: {
    en: 'Unknown license error',
    sv: 'Okänt fel på licenskoden'
  },  
  [TrVar.UnlimitedUsage]: {
    en: 'Unlimited usage',
    sv: 'Obegränsad användning'
  },
  [TrVar.UnlockExercise]: {
    en: 'Unlock exercise',
    sv: 'Lås upp övning'
  },  
  [TrVar.UnmuteMic]: {
    en: 'Enable microphone',
    sv: 'Sätt på ljud'
  },
  [TrVar.Unread]: {
    en: 'Unread',
    sv: 'Oläst'
  },
  [TrVar.UnreadMessages]: {
    en: 'Unread messages',
    sv: 'Olästa meddelanden'
  },
  [TrVar.UntilThenClickTheButtonBelow]: {
    en: 'Until then, click the button below and we will get in touch and solve coaching for your.',
    sv: 'Tills dess, klicka på knappen nedan så hör vi av oss och löser coaching åt dig.'
  },
  [TrVar.UntilThenFillOutTheContactForm]: {
    en: 'Until then, fill in your contact details below and we will get in touch and solve coaching for you.',
    sv: 'Tills dess, fyll i dina kontaktuppgifter nedan så hör vi av oss och löser coaching åt dig.'
  },
  [TrVar.UpdatePassword]: {
    en: 'Update password',
    sv: 'Byt lösenord'
  },
  [TrVar.Upgrade]: {
    en: 'Upgrade',
    sv: 'Uppgradera'
  },
  [TrVar.UpgradeTo]: {
    en: 'Upgrade to',
    sv: 'Uppgradera till'
  },
  [TrVar.UpgradeToCoaching]: {
    en: 'Upgrade to coaching',
    sv: 'Uppgradera till coaching'
  },
  [TrVar.UploadCertificate]: {
    en:  'Upload coach certificate',
    sv: 'Ladda upp coachcertifikat'
  },
  [TrVar.UploadedDocument]: {
    en: 'Uploaded document',
    sv: 'Uppladdat dokument'
  },
  [TrVar.ValidBetween]: {
    en: 'Valid between',
    sv: 'Giltig mellan'
  },
  [TrVar.ViewYourDevelopment]: {
    en: 'View your development',
    sv: 'Visa din utveckling'
  },
  [TrVar.VideoChatWithCoach]: {
    en: 'Video chat with coach',
    sv: 'Videomöte med coach'
  },
  [TrVar.VideoHelpNoPartnerPart1]: {
    en: 'Your',
    sv: 'Det kan hända att din'
  },
  [TrVar.VideoHelpNoPartnerPart2]: {
    en: 'Is probably late. Wait a few minutes.',
    sv: 'Är sen. Vänta några minuter.'
  },
  [TrVar.VideoMeeting]: {
    en: 'Video meeting',
    sv: 'Videomöte'
  },
  [TrVar.VideoMeetingIn]: {
    en: 'Video meetings',
    sv: 'Videomöten om'
  },
  [TrVar.W]: {
    en: 'W',
    sv: 'V'
  },
  [TrVar.WaitingFor]: {
    en: 'Waiting for',
    sv: 'Väntar på'
  },
  [TrVar.WaitingForConfirmation]: {
    en: 'Waiting for confirmation',
    sv: 'Väntar på bekräftelse'
  },
  [TrVar.WaitingForYourBankIdCode]: {
    en: 'Waiting for your BankID-code',
    sv: 'Väntar på din BankID-kod'
  },
  [TrVar.WaitingForYourBankIdSigning]: {
    en: 'Waiting for your BankID-signing',
    sv: 'Väntar på din BankID-signering'
  },
  [TrVar.WantCoach]: {
    en: 'Submit interest for a coach',
    sv: 'Anmäl intresse för coach'
  },
  [TrVar.WantedState]: {
    en: 'Desired state',
    sv: 'Önskat läge'
  },
  [TrVar.WantedTimeBetweenMeetings]: {
    en: 'Set the desired break between your client meetings',
    sv: 'Ställ in önskad paus mellan dina klientmöten'
  },
  [TrVar.WantsToDecreaseTheirPotential]: {
    en: 'Wants to decrease their potential with',
    sv: 'Vill minska sin potential med'
  },
  [TrVar.WantsToIncreaseTheirPotential]: {
    en: 'Wants to increase their potential with',
    sv: 'Vill öka sin potential med'
  },
  [TrVar.WantToCancel]: {
    en: 'Do you want to cancel?',
    sv: 'Vill du avbryta?'
  },
  [TrVar.WantToCancelMeeting]: {
    en: 'Do you want to cancel the meeting?',
    sv: 'Vill du avboka mötet?'
  },
  [TrVar.WantToCoachIn]: {
    en: 'I want to coach in',
    sv: 'Jag vill coacha inom'
  },
  [TrVar.WantToSave]: {
    en: 'Do you want to save?',
    sv: 'Vill du spara?'
  },
  [TrVar.WantToShareLink]: {
    en: 'Would you rather share a link?',
    sv: 'Vill du hellre dela en länk?'
  },
  [TrVar.Warning]: {
    en: 'Warning',
    sv: 'Varning'
  },
  [TrVar.WeAreSoHappyThatYouWantToBecomeACoachWithUs]: {
    en: 'Great to hear that you want to join in democratizing mental strength!',
    sv: 'Så roligt att just du vill vara med och demokratisera mental styrka!'
  },
  [TrVar.WeAreWorkingToMakeItPossibleToUpgradeToCoach]: {
    en: 'We are working on making it possible to upgrade to coach directly in the platform.',
    sv: 'Vi jobbar på för fullt så att det ska gå att uppgradera till coach direkt i plattformen.'
  },
  [TrVar.Website]: {
    en: 'Webiste',
    sv: 'Hemsida'
  },
  [TrVar.WeCouldNotCreateAccount]: {
    en: 'We could not create account. Contact support@zebrain.se and we will help you',
    sv: 'Det gick inte att skapa konto. Kontakta support@zebrain.se så hjälper vi dig'
  },
  [TrVar.WeDemocratiseMentalStrength]: {
    en: 'We democratise mental strength',
    sv: 'Vi demokratiserar mental styrka'
  },
  [TrVar.Week]: {
    en: 'Week',
    sv: 'Vecka'
  },
  [TrVar.WeeksPlanned]: {
    en: 'Weeks planned',
    sv: 'Veckor inplanerade'
  },
  [TrVar.WeHopeTheMeetingWentWell]: {
    en: 'We hope the meeting went well!',
    sv: 'Hoppas mötet gick bra!'
  },
  [TrVar.Welcome]: {
    en: 'Welcome',
    sv: 'Välkommen'
  },
  [TrVar.WelcomeAboard]: {
    en: 'Welcome aboard',
    sv: 'Välkommen ombord'
  },
  [TrVar.WelcomeAndThanksForYourApplication]: {
    en: `Welcome and thank you for your application!`,
    sv: `Välkommen och tack för din ansökan!`
  },
  [TrVar.WelcomeToZebrainCreateAUserAndGetStarted]: {
    en: 'Welcome to Zebrain! Create a user and get started with your development!',
    sv: 'Välkommen till Zebrain! Skapa en användare och kom igång med din utveckling!'
  },
  [TrVar.WelcomeToZebrainCreateAUserAndGetStartedWithYourDevelopment]: {
    en: `Welcome to Zebrain! Create a user and get started with your development! 
                    No payment details are required.`,
    sv: `Välkommen till Zebrain! Skapa en profil och kom igång med din utveckling! 
                    Inga betalningsuppgifter krävs.`
  },
  [TrVar.Wellbeing]: {
    en: 'Wellbeing',
    sv: 'Välmående'
  },
  [TrVar.WellDone]: {
    en: 'Good work',
    sv: 'Bra jobbat'
  },
  [TrVar.WeRecommendEveryOtherWeekSoThatYouWillHaveTimeToDoTasks]: {
    en: `We recommend every other week so that you will have time to do tasks and analyze what
                              has been said. Of course, if you want to book meetings more frequently at times that
                              is fine.`,
    sv: `Vi rekommenderar varannan vecka för att du ska hinna göra uppgifter och analysera det
                              som sagts, men vill du boka tätare möten någon gång går det självklart bra.`
  },
  [TrVar.WeReportCoachRequest]: {
    en: 'We will notify your interest to your employer.',
    sv: 'Vi meddelar ditt intresse till din arbetsgivare.'
  },
  [TrVar.WeWantToTipThemAboutCoaching]: {
    en: 'We just want to tip them about letting you and your colleagues develop through coaching.',
    sv: 'Vi vill bara tips hen om att låta dig och dina kollegor utvecklas genom coaching.'
  },
  [TrVar.WeWillBeInTouch]: {
    en: 'Thanks! We will get in touch soon.',
    sv: 'Tack! Vi hör av oss inom kort.'
  },
  [TrVar.WeWillNotify]: {
    en: 'When you submit your interest, we will notify your employer',
    sv: 'När du flaggar ditt intresse så meddelar vi din arbetsgivare'
  },
  [TrVar.WeWillNotifyYourEmployerThatYouWantChatCoaching]: {
    en: 'Great that you are interested in chat coaching. We will notify your employer.',
    sv: 'Vad kul att du är intresserad av chat-coaching. Vi meddelar din arbetsgivare.'
  },
  [TrVar.WeWillNotifyYourEmployerThatYouWantVideoCoaching]: {
    en: 'Great that you are interested in video coaching. We will notify your employer.',
    sv: 'Vad kul att du är intresserad av video-coaching. Vi meddelar din arbetsgivare.'
  },
  [TrVar.WhatCanITalkToMyCoachAbout]: {
    en: 'What can I talk to my coach about?',
    sv: 'Vad kan jag prata med min coach om?'
  }, 
  [TrVar.WhatHappensIfIDontCancelWithin24HoursAdvance]: {
    en: `What happens if I don't cancel my meeting 24 hours in advance?`,
    sv: 'Vad händer om jag inte bokar av mitt möte ett dygn innan?'
  },   
  [TrVar.WhatHappensIfIDontHaveTimeForAllMyMeetings]: {
    en: 'What happens if I dont have time for all my meetings?',
    sv: 'Vad händer om jag inte hinner ha alla möten?'
  }, 
  [TrVar.WhatHappensNext]: {
    en: 'What happens next?',
    sv: 'Vad händer nu?'
  }, 
  [TrVar.WhatHappensToMyCoachIfMyLicenseIsUpgradedToVideoCoaching]: {
    en: 'What happens to my coach if my license is upgraded to video coaching?',
    sv: 'Vad händer med min coach om min licens blir uppgraderad till video coaching?'
  }, 
  [TrVar.WhatIsCoaching]: {
    en: 'What is coaching?',
    sv: 'Vad är coaching?'
  }, 
  [TrVar.WhatIsPotential]: {
    en: 'What is potential?',
    sv: 'Vad är potential?'
  }, 
  [TrVar.WhatIsPotentialSub1]: {
    en: `The word potential can be explained as our ability to develop in order to achieve 
                      what we want and need. When you use your potential, you have the
                      capacity and willingness to change in favor of achievement. What we
                      usually strive to achieve is a good balance between performance
                      and well-being.`,
    sv: `Ordet potential kan förklaras som vår förmåga att utvecklas för att uppnå det vi 
                      vill och behöver. När någon använder potential så finns det kapacitet 
                      och vilja att förändras för att åstadkomma något önskat. Det vi 
                      oftast vill uppnå är att ha en bra balans mellan prestation och 
                      vårt välmående.`
  }, 
  [TrVar.WhatIsPotentialSub2]: {
    en: `Your inherent potential is affected by your characteristics and how you choose to 
                      relate to and act based on them. In some areas of your life it is likely
                      easy for you to use your full potential, while others present more of a
                      challenge. Regardless of what experiences we carry, we’re all capable of
                      growing and developing, and thus use more of our potential.`,
    sv: `Din inneboende potential påverkas av dina egenskaper och hur du väljer att förhålla 
                      dig till och agera utifrån dem. Inom vissa områden är det troligen lätt 
                      för dig att använda hela din potential, medan det är svårare inom andra. 
                      Men oavsett vilka erfarenheter vi bär på så besitter vi alla förmågan 
                      att utvecklas och därmed använda mer av vår potential.`
  }, 
  [TrVar.WhatShouldIDoIfIHaveTechnicalIssuesWithThePlatform]: {
    en: 'What should I do if I have technical issues with the platform?',
    sv: 'Vad gör jag om jag har tekniska problem med plattformen?'
  }, 
  [TrVar.WhatTheClientHasDone]: {
    en: 'What the client has done',
    sv: 'Vad klienten har gjort'
  }, 
  [TrVar.WhatWeNeedInOrderToFeelMotivated]: {
    en: 'What we need in order to feel motivated and perform.',
    sv: 'Vad vi behöver för att bli motiverade att prestera.'
  }, 
  [TrVar.WhatYouCanTalkToYourCoachAbout]: {
    en: 'What you can talk to your coach about',
    sv: 'Vad du kan prata med din coach om'
  }, 
  [TrVar.WelcomeToFramwework]: {
    en: 'Welcome to your Framwork',
    sv: 'Välkommen till ditt Framwork'
  },
  [TrVar.WhenWeAreAwareOfOurIgniterAndExtinguish]: {
    en: `When we are aware of our igniter and extinguishing factors, we can influence our ability to feel motivated.`,
    sv: `Genom att vara medveten om våra tändar- och släckarfaktorer kan vi påverka vår förmåga att känna motivation.`
  }, 
  [TrVar.WhenWeUseOurBoostingBehaviour]: {
    en: `When we use our "Boosting behaviour" we are focused on influencing
                      others with strong experiences and things that can be perceived as exciting. When this behaviour is
                      used, details and facts become uninteresting and boring. Our "Boosting behaviour" makes
                      us flexible and optimistic and we highlight opportunities rather than difficulties.`,
    sv: `När vi använder vårt "Boosting behaviour" är vi fokuserade på att påverka andra med starka upplevelser och saker
                      som kan uppfattas som spännande. När det här beteendet används är detaljer och fakta ointressanta och tråkiga.
                      Vårt "Boosting behaviour" gör oss flexibla och optimistiska och vi lyfter möjligheter snarare än
                      svårigheter.`
  },
  [TrVar.WhenWeUseOurDetailedBehaviour]: {
    en: `When we use our "Detailed behaviour" we focus on facts and details
                      and aim to do everything correctly. We are logical and place high demands on quality. Our
                      "Detailed behaviour" means that we work systematically and process all the information
                      available, before we make a decision.`,
    sv: `Med vårt "Detailed behaviour" fokuserar vi på fakta och detaljer med
                      målet att allt vi
                      sysslar med skall bli rätt. Vi är logiska och ställer höga krav på kvalitet. Vårt
                      "Detailed behaviour" gör att vi arbetar systematiskt och tar fram all information som finns
                      tillgänglig innan vi fattar beslut oavsett vad det gäller.`
  },
  [TrVar.WhenWeUseOurDrivingBehaviour]: {
    en: `When we use our "Driving behaviour" we take the initiative, we want
                      to decide, have control and achieve concrete, clear and measurable results. The control is more about
                      power, than attention to details. Our "Driving behaviour" makes sure we keep a high pace
                      and get things done as quickly as possible.`,
    sv: `När vi använder vårt "Driving behaviour" tar vi initiativ, vill bestämma, ha kontroll och
                      uppnå konkreta, tydliga och mätbara resultat. Kontrollen handlar mer om makt än fokus på detaljer.
                      Vårt "Driving behaviour" gör att vi håller ett högt tempo och ser till att saker och ting
                      blir gjorda så snabbt som möjligt.`
  },
  [TrVar.WhenWeUseOurSupportingBehaviour]: {
    en: `When we use our "Supporting behaviour" we are aware of the needs of
                      others, thrive with others and are good at seeing the positive in what is right now. We are
                      persistent and complete what we have started, even if it feels reluctant. We are calm and good at
                      listening to other people.`,
    sv: `Med vårt "Supporting behaviour" är vi uppmärksamma på andras behov,
                      trivs tillsammans med
                      andra och är bra på att se det positiva i det som är just nu. Vi är uthålliga och slutför det vi påbörjat
                      även om det bär emot. Vi är lugna och bra på att lyssna på andra människor.`
  }, 
  [TrVar.WhenWillYouDoIt]: {
    en: 'When will you do it?',
    sv: 'När ska du göra det?'
  }, 
  [TrVar.WhenYouGetUpgradedToVideoCoaching]: {
    en: 'When you get upgraded to video coaching, you will no longer have the coach you have been receiving chat coaching from. If you want to continue with your chat coach, you need to manually reselect them.',
    sv: 'När du får tillgång till videocoaching kommer du inte längre ha kvar coachen du har chattat med. Vill du fortsätta med din chattcoach behöver du välja om denna.'
  },
  [TrVar.WhenYourClientWantsToBookAMeetingZebrainWillCalculate]: {
    en: `When your client wants to book a meeting Zebrain will calculate available meeting times. The times
                suggested depend on the length of the meeting. Zebrain schedules a 15 minute pause between meetings
                (you can change length of pause under Edit profile).`,
    sv: `När din klient vill boka ett möte kommer Zebrain räkna ut förslag på mötestid. Tiderna som föreslås
                beror på mötets längd. Zebrain schemalägger alltid normalt en kvart mellan möten (du kan ändra själv
                under Redigera profil).`
  },
  [TrVar.WhenYourLicenseExpires]: {
    en: 'When your license expires',
    sv: 'Då din licens går ut'
  },
  [TrVar.WhenYouSaveTheMeeting]: {
    en: 'When you save the meeting, you and the client will receive a mail with meeting reschedule information.',
    sv: 'När du sparar mötet kommer du och klienten att få ett mejl med mötesombokningsinformation.'
  },
  [TrVar.WhenYouSaveTheMeetingSuggestion]: {
    en: `When you save the meeting suggestion, the client can accept or reject this suggestion in their
                  booking page.`,
    sv: `När du sparar mötesförslaget kan klienten acceptera eller neka förslaget på sin bokningssida.`
  }, 
  [TrVar.WhichActionsDidYouTake]: {
    en: 'Which actions do you take',
    sv: 'Vad gör du konkret'
  }, 
  [TrVar.WhichAllowsForMeeting]: {
    en: 'Which includes seeing a coach',
    sv: 'Vilket innebär ett möte'
  },
  [TrVar.WhichAreYourKeyTakeaways]: {
    en: 'Which are your key takeaways',
    sv: 'Vad tar du med dig'
  }, 
  [TrVar.WhileYouWaitCheckoutSoMe]: {
    en: 'While you wait, feel free to follow us on social media.',
    sv: 'Under tiden du väntar får du gärna följa oss på sociala medier.'
  }, 
  [TrVar.WhoCanSeeMyAnswersInThePlatform]: {
    en: 'Who can see my answers in the platform?',
    sv: 'Vem kan se mina svar i plattformen?'
  }, 
  [TrVar.WhoAmI]: {
    en: 'Who am I',
    sv: 'Vem är jag'
  }, 
  [TrVar.Whoops]: {
    en: 'Whoops',
    sv: 'Hoppsan'
  }, 
  [TrVar.WhyIsItImportantToBookAMeetingEveryOtherWeek]: {
    en: 'Why is it important to book a meeting every other week?',
    sv: 'Varför är det viktigt att boka möten varannan vecka?'
  }, 
  [TrVar.HowChangeCoach]: {
    en: 'I want to change coach, how do I do that?',
    sv: 'Jag vill byta coach, hur går jag tillväga?'
  }, 
  [TrVar.WhyZebrain]: {
    en: 'zebrain.se/en/why-zebrain',
    sv: 'zebrain.se/sv/varfor-zebrain'
  }, 
  [TrVar.With]: {
    en: 'With',
    sv: 'Med'
  }, 
  [TrVar.WithAccessYouUnlock]: {
    en: 'With Access you unlock all coach questions and areas of development for only 49kr/month.',
    sv: 'Med Access låser du upp alla coachfrågor och utvecklingsområden för endast 49kr/månad.'
  }, 
  [TrVar.WithLicenseAccess]: {
    en: 'With the Access license you will unlock all areas of development and coach questions for only 49kr/month. When you have Access you can choose to upgrade to have a personal coach.',
    sv: 'Med licensplan Access låser du upp alla utvecklingsområden och coachfrågor för endast 49kr/månad. När du har Access så kan du också välja att köpa till personlig coach.'
  },
  [TrVar.WithYou]: {
    en: 'With you',
    sv: 'Med dig'
  },
  [TrVar.WithOtherCoach]: {
    en: 'with other coach',
    sv: 'med annan coach'
  },
  [TrVar.Woman]: {
    en: 'Woman',
    sv: 'Kvinna'
  },
  [TrVar.WorkExperience]: {
    en: 'Work experience',
    sv: 'Arbetslivserfarenhet'
  },
  [TrVar.WorkPlace]: {
    en: 'Employer',
    sv: 'Arbetsplats'
  },
  [TrVar.WorldsMostWidelyUsedResearchBasedPersonalityModels]: {
    en: `The world's most widely used research-based personality models and tests to
                  predict different behaviours and behaviour patterns.`,
    sv: `Världens mest använda forskningsbaserade personlighetsmodeller och tester för att
                  förutsäga olika beteenden och beteendemönster.`
  }, 
  [TrVar.WouldYouLikeToContinueReceivingCoaching]: {
    en: 'Would you like to continue receiving coaching?',
    sv: 'Vill du fortsätta få personlig coaching?'
  },
  [TrVar.WriteAsOftenAsYouWant]: {
    en: 'Write as often as you want',
    sv: 'Skriv så ofta du vill'
  },
  [TrVar.WriteHere]: {
    en: 'Write here',
    sv: 'Skriv här'
  },
  [TrVar.WriteQuestion]: {
    en: 'Write your question here',
    sv: 'Skriv din fråga här'
  },
  [TrVar.WriteToYourCoachIn]: {
    en: 'Write to your coach in',
    sv: 'Skriv till din coach i'
  },
  [TrVar.WriteToYourCoachWhenever]: {
    en: 'Write to your coach whenever you want',
    sv: 'Skriv till din coach när du vill'
  },
  [TrVar.WrongFormatEmail]: {
    en: 'Wrong format for the email address. Try again',
    sv: 'Fel format på mejladress. Prova igen'
  },
  [TrVar.WrongFormatPersonalNumber]: {
    en: 'Wrong format for the personal number. Try again',
    sv: 'Fel format på personnumret. Prova igen'
  },
  [TrVar.WwwFacebookCom]: {
    en: 'https://www.facebook.com/zebraincoaching',
    sv: 'https://www.facebook.com/zebraincoaching'
  },
  [TrVar.WwwLinkedinCom]: {
    en: 'https://www.linkedin.com/company/zebrain/',
    sv: 'https://www.linkedin.com/company/zebrain/'
  },
  [TrVar.WwwZebrainSe]: {
    en: 'https://www.zebrain.se/en',
    sv: 'https://www.zebrain.se'
  },
  [TrVar.Yes]: {
    en: 'Yes',
    sv: 'Ja'
  },
  [TrVar.YesFollowUp]: {
    en: 'Do the follow up',
    sv: 'Ja, följ upp'
  },
  [TrVar.YesRemove]: {
    en: 'Yes, remove',
    sv: 'Ja, ta bort'
  },
  [TrVar.YesYouCanJustChooseThemAgain]: {
    en: 'Yes, you can. Just re-select them and your conversation will continue in the chat.',
    sv: 'Ja det kan du. Det är bara att du väljer om denne så kommer er konversation att fortsätta i chatten.'
  },
  [TrVar.You]: {
    en: 'You',
    sv: 'Du'
  },
  [TrVar.YouAddAMeetingBlockForMonday]: {
    en: `You add a meeting block for Monday, between 09:00 and 12:00. Your meeting pause is set to 15 minutes.
                  A client that is about to book a 30-minute meeting will the get the suggestions:`,
    sv: `Du lägger till ett mötesblock på måndag, mellan 09:00 och 12:00. Din mötespaus är satt till en kvart.
                  En klient som ska boka ett 30-minutersmöte kommer då få förslagen:`
  },
  [TrVar.YouAlsoNeed]: {
    en: 'You also need',
    sv: 'Vad som också är viktigt för dig'
  },
  [TrVar.YouAre]: {
    en: 'You are',
    sv: 'Du är'
  },
  [TrVar.YouAreAlso]: {
    en: 'You can also be',
    sv: 'Du kan också vara'
  },
  [TrVar.YouAreAlsoGoodAt]: {
    en: 'You are also good at',
    sv: 'Du är också bra på att'
  },
  [TrVar.YouCanBeAnonymous]: {
    en: 'Of course, you can be anonymous if you wish.',
    sv: 'Du får självklart vara anonym om du vill.'
  },
  [TrVar.YouCancelledTheMeetingLate]: {
    en: 'You cancelled the meeting late. It is not possible to reschedule.',
    sv: 'Du avbokade mötet sent. Det är inte ombokningsbart.'
  },
  [TrVar.YouCannotChatWithClientAnymore]: {
    en: 'You cannot chat with client any more',
    sv: 'Du kan inte chatta med klienten längre'
  },
  [TrVar.YouCanStillReadMessages]: {
    en: 'You can still read the messages, but the client cannot read new messages or chat with you.',
    sv: 'Du kan fortfarande läsa meddelande, men klienten kan inte läsa nya meddelanden eller chatta med dig.'
  },
  [TrVar.YouCanSynchroniseYourAvailableTimes]: {
    en: 'You can synchronise your available times with your calendar',
    sv: 'Du kan synkronisera dina lediga tider med din kalender'
  },
  [TrVar.YouCanTalkToYourCoachAbout1]: {
    en: 'You can talk to your coach about anything - big or small, work or personal. A good rule of thumb is to choose something that you either want to strengthen or change about yourself. Your coach is an expert at your development process and will give you guidance where you need a new perspective and approach.',
    sv: 'Du kan prata med din coach om precis vad du helst – oavsett om det är stora eller små problem, arbetsliv eller privatliv. En bra tumregel är att välja något som du antingen vill förstärka eller förändra hos dig själv. Din coach är expert på din utvecklingsprocess och kommer ge dig vägledning där du har ett behov av nya perspektiv och förhållningssätt.'
  },
  [TrVar.HowChangeCoachAnswer]: {
    sv: 'Kontakta oss på support@zebrain.se så hjälper vi dig.',
    en: 'Contact us at support@zebrain.se and we will help you.'
  },
  [TrVar.HowChangeCoachAnswer2]: {
    sv: 'Vi rekommenderar att du har minst två coachmöten innan du byter. ',
    en: 'We recommend that you have at least two coach meetings before you change.'
  },
  
  [TrVar.YouCanTalkToYourCoachAbout2]: {
    en: `If you can't think of anything you want to talk about, we recommend looking at your potential measurement or areas of development to see in which area(s) you have the most untapped potential. You can also ask your coach for more guidance.`,
    sv: 'Om du inte kommer på något du vill prata om rekommenderar vi att du tittar på din potentialmätning eller dina utvecklingsområden för att se inom vilket eller vilka områden du har mest oanvänd potential. Du kan också fråga din coach för mer vägledning.'
  },
  [TrVar.YouCanTalkToYourCoachAbout3]: {
    en: 'Should you need help to deal with difficult events in your past, we recommend that you turn to a psychologist or therapist, for example.',
    sv: 'Om det är så att du behöver hjälp att bearbeta svåra händelser i ditt förflutna rekommenderar vi att du vänder dig till en exempelvis till en psykolog eller terapeut. '
  },
  [TrVar.YouCannotMakeAnyChanges]: {
    en: 'You cannot make any changes here',
    sv: 'Du kan inte göra någon ändring här'
  },
  [TrVar.YouCanOwnYourDataUnderGDPR]: {
    en: `You own your own data under GDPR. Therefore, only you have access to your answers. However, a coach can see your potential measurement, behavioural analysis and your answers to the exercises in your Areas of Development.`,
    sv: `Du äger din egen data enligt GDPR. Därför är det endast du själv som har tillgång till dina svar. En coach kan däremot se din potentialmätning, beteendeanalys och dina svar på övningarna i Utvecklingsområden. `
  },
  [TrVar.YouCanWorkOn]: {
    en: 'You can work on',
    sv: 'Du kan jobba med'
  },
  [TrVar.YouCreate]: {
    en: 'Create',
    sv: 'Skapar'
  },
  [TrVar.YouAreEspeciallyGoodAt]: {
    en: 'You are especially good at',
    sv: 'Dina främsta styrkor'
  },
  [TrVar.YouDontHaveALicense]: {
    en: `You don't have a license`,
    sv: 'Du har ingen licens'
  },
  [TrVar.YouDontHaveAPlan]: {
    en: `You don't have a plan`,
    sv: 'Du har ingen plan'
  },
  [TrVar.YouGet]: {
    en: 'You get',
    sv: 'Du får'
  },
  [TrVar.YouHave]: {
    en: 'You have',
    sv: 'Du har'
  },
  [TrVar.YouHaveAcessToYourCoachWhileAccessLicense]: {
    en: 'You have access to your coach for as long as you have an Access-license. When and if it is time for you to be upgraded to video coaching, you need to choose the coach you chatted with again.',
    sv: 'Du har tillgång till din coach under tiden du har Accesslicens. När och om det är dags för dig att bli uppgraderad till video coaching behöver du välja om coachen du chattat med.'
  },
  [TrVar.YouHaveAlreadyUpgraded]: {
    en: 'You have already upgraded!',
    sv: 'Du har redan uppgraderat!'
  },
  [TrVar.YouHaveAlreadyUpgradedToAccess]: {
    en: 'You have already upgraded to Access',
    sv: 'Du har redan uppgraderat till Access'
  },
  [TrVar.YouHaveBeenOffered]: {
    en: 'You have been offered the license',
    sv: 'Du har blivit erbjuden licensen'
  },
  [TrVar.YouHaveCancelled]: {
    en: 'You have cancelled',
    sv: 'Du har avbokat'
  },
  [TrVar.YouHaveCompleted]: {
    en: 'You have completed',
    sv: 'Du har avklarat'
  },
  [TrVar.YouHaveDecreasedYourTotalPotential]: {
    en: 'You have decreased your total potential with',
    sv: 'Du har minskat din totala potential med'
  },  
  [TrVar.YouHaveImprovedYourTotalPotential]: {
    en: 'You have improved your total potential with',
    sv: 'Du har förbättrat din totala potential med'
  },
  [TrVar.YouHaveLicense]: {
    en: 'You have the license',
    sv: 'Du har licensen'
  },
  [TrVar.YouHaveLicensePlan]: {
    en: 'You have license',
    sv: 'Du har licensplan'
  },
  [TrVar.YouHaveNoAvailableMeetingTimes]: {
    en: 'You have no available meeting times',
    sv: 'Du har inga lediga mötestider'
  },
  [TrVar.YouHaveNoUnreadMessages]: {
    en: 'You have no unread messages',
    sv: 'Du har inga olästa meddelanden'
  },
  [TrVar.YouHaveNoUpcomingMeetingsBooked]: {
    en: 'You have no upcoming meetings booked',
    sv: 'Du har inga fler möten bokade'
  },
  [TrVar.YouHaveNotChangedAnyInputs]: {
    en: 'You have not changed any inputs.',
    sv: 'Du har inte ändrat något av reglagen.'
  },
  [TrVar.YouHaveNotChangedCurrentState]: {
    en: 'You have not changed any current state.',
    sv: 'Du har inte ändrat något nuläge.'
  },
  [TrVar.YouHaveNotChangedDesiredState]: {
    en: 'You have not changed any desired state.',
    sv: 'Du har inte ändrat något önskat läge.'
  },
  [TrVar.YouHaveNotCompletedAnyMeasurement]: {
    en: `You haven't completed any measurement.`,
    sv: 'Du har inte gjort någon mätning.'
  },
  [TrVar.YouHaveNotCreatedAnAccountYet]: {
    en: `You have not created an account yet. Start by creating your account and then you can upgrade.`,
    sv: 'Du har inte skapat konto än. Börja med att skapa konto så kan du uppgradera.'
  },
  [TrVar.YouHaveNotHadAnyMeetingsYet]: {
    en: 'You have not had any meetings yet',
    sv: 'Du har inte haft några möten hittills'
  },
  [TrVar.YouHaveToAgreeBeforeYouCanContinue]: {
    en: 'You have to agree before you can continue',
    sv: 'Du måste godkänna innan du kan fortsätta'
  },
  [TrVar.YouHaveUnreadMessages]: {
    en: 'You have unread messages',
    sv: 'Du har olästa meddelanden'
  },
  [TrVar.YouHaveWorkedThrough]: {
    en: 'You have worked through',
    sv: 'Du har arbetat igenom'
  },
  [TrVar.YouMissedTheMeeting]: {
    en: 'You missed the meeting',
    sv: 'Du missade mötet'
  },
  [TrVar.YouMustSelectOneAreaToBeCoached]: {
    en: 'You must select at least one coaching area',
    sv: 'Du måste välja minst ett område att coachas inom'
  },
  [TrVar.YouMustSelectOneAreaToCoach]: {
    en: 'You must select at least one area to coach within',
    sv: 'Du måste välja minst ett område att coacha inom'
  },
  [TrVar.YouMustSelectOneLanguage]: {
    en: 'You must select at least one language',
    sv: 'Du måste välja minst ett språk'
  },
  [TrVar.YouNeed]: {
    en: 'Need',
    sv: 'Behöver'
  },
  [TrVar.YouNeedToEnterALicenseCode]: {
    en: 'You need to enter a license code to proceed.',
    sv: 'Du måste fylla i en licenskod för att komma vidare.'
  },
  [TrVar.YouNeedToFeelGood]: {
    en: 'To feel really good you need',
    sv: 'Dina största behov'
  },
  [TrVar.YouOfferTheClientAnotherMeeting]: {
    en: 'You offer the client another meeting without cost',
    sv: 'Du bjuder klienten på ett möte till utan kostnad'
  },
  [TrVar.YourMeetingIsBooked]: {
    en: 'Your meeting is booked',
    sv: 'Ditt möte är bokat'
  },
  [TrVar.YouSeek]: {
    en: 'Seek',
    sv: 'Söker'
  },
  [TrVar.YouWant]: {
    en: 'Want',
    sv: 'Önskar'
  },
  [TrVar.YouWantTo]: {
    en: 'You want to',
    sv: 'Du vill'
  },
  [TrVar.YouWantToIncrease]: {
    en: 'You want to increase to',
    sv: 'Du vill öka till'
  },
  [TrVar.YouWillBeMatchedWithACoachBasedOnTheAreas]: {
    en: `You will be matched with a coach based on the areas you've chosen, which languages you want to be coached in, as well as your behavioural analysis.`,
    sv: 'Din matchning med coach baseras på de områden du väljer, vilket språk du vill coachas på samt din beteendeanalys.'
  },
  [TrVar.Your]: {
    en: 'Your',
    sv: 'Din'
  },
  [TrVar.YourAnalysisResult]: {
    en: 'Your analysis result',
    sv: 'Ditt analysresultat'
  },
  [TrVar.YourBehaviourProfile]: {
    en: 'Your behavioural profile',
    sv: 'Din beteendeprofil'
  },
  [TrVar.YourBookings]: {
    en: 'Your bookings',
    sv: 'Dina bokningar'
  },
  [TrVar.YourChatCoach]: {
    en: 'Your chat coach',
    sv: 'Din chatt-coach'
  },
  [TrVar.YourAICoach]: {
    en: 'Your AI coach',
    sv: 'Din AI coach'
  },
  [TrVar.YourClients]: {
    en: 'Your clients',
    sv: 'Dina klienter'
  },
  [TrVar.YourCoach]: {
    en: 'Your coach',
    sv: 'Din coach'
  },
  [TrVar.YourCoachAreas]: {
    en: 'Your coach areas',
    sv: 'Dina coachområden'
  },
  [TrVar.YourCoachRepliesThrice]: {
    en: 'Your coach will respond to you three times a week',
    sv: 'Din coach svarar dig tre gånger i veckan'
  },
  [TrVar.YourCurrentState]: {
    en: 'Your current state',
    sv: 'Ditt nuläge'
  },
  [TrVar.YourDesiredState]: {
    en: 'Your desired state',
    sv: 'Ditt önskade läge'
  },
  [TrVar.YourDetails]: {
    en: 'Your details',
    sv: 'Dina uppgifter'
  },
  [TrVar.YourEmployer]: {
    en: 'Your employer',
    sv: 'Din arbetsplats'
  },
  [TrVar.YourEmployerCannotSeeYourAnswersEither]: {
    en: 'Your employer cannot see your answers either. The employer only sees data at the group level of at least 5 employees and cannot filter on job role, gender, age or similar to identify individuals in the group.',
    sv: 'Din arbetsgivare kan inte heller se dina svar. Arbetsgivaren ser endast data på gruppnivå om minst 5 medarbetare och kan inte heller filtrera på yrkesroll, kön, ålder eller liknande för att identifiera individer i gruppen.'
  },
  [TrVar.YourLicense]: {
    en: 'Your license',
    sv: 'Din licens'
  },
  [TrVar.YourLicenseDoesNotIncludeCoachMeetings]: {
    en: 'In your license there are no meetings included with a coach.',
    sv: 'I din licensplan ingår inte möten med coach.'
  },
 [TrVar.YourLicenseIsConnected]: {
    en: 'Your license is connected to the coach below.',
    sv: 'Din licens är kopplad till coach nedan.'
  },
  [TrVar.YourMeasurements]: {
    en: 'Your measurements',
    sv: 'Dina mätningar'
  },
  [TrVar.YourName]: {
    en: 'Your name',
    sv: 'Ditt namn'
  },
  [TrVar.YourOverview]: {
    en: 'Your overview',
    sv: 'Din översikt'
  },
  [TrVar.YourPreviousCoaches]: {
    en: 'Your previous coaches',
    sv: 'Dina tidigare coacher'
  },
  [TrVar.YourPreviousMeasurements]: {
    en: 'Your previous measurements',
    sv: 'Dina tidigare mätningar'
  },
  [TrVar.YourProfile]: {
    en: 'Your profile',
    sv: 'Din profil'
  },
  [TrVar.YourProfileIsSaved12Months]: {
    en: 'Your profile will be saved for 12 months.',
    sv: 'Din profil sparas i 12 månader.'
  },
  [TrVar.YourProgram]: {
    en: 'Your framework',
    sv: 'Ditt ramverk'
  },
  [TrVar.YourProgramIntro]: {
    en: 'Here is information that your employer wants to share with you about your program and your organization.',
    sv: 'Här hittar du information som din arbetsgivare vill dela med dig om ditt program och er organisation.'
  },
  [TrVar.YourPurchaseIsBeingProcessed]: {
    en: 'Your purchase is being processed...',
    sv: 'Ditt köp genomförs...'
  },
  [TrVar.YourSuggestion]: {
    en: 'Your suggestion',
    sv: 'Ditt förslag'
  },
  [TrVar.YourSuggestionTo]: {
    en: 'Your suggestion to',
    sv: 'Ditt förslag till'
  },
  [TrVar.Yours]: {
    en: 'Your',
    sv: 'Ditt'
  },
  [TrVar.YourStartState]: {
    en: 'Your starting state',
    sv: 'Ditt startläge'
  },
  [TrVar.YourTotalPotentialIsUnchanged]: {
    en: 'Your total potential is unchanged',
    sv: 'Din totala potential är oförändrad'
  },
  [TrVar.YourTotalPotentialWith]: {
    en: 'Your total potential with',
    sv: 'Din totala potential med'
  },  
  [TrVar.YouveGotAccessToEverything]: {
    en: `You've got access to all areas of development, behavioural analysis, and continual measurements of your development.`,
    sv: 'Du har nu tillgång till alla utvecklingsområden, beteendeanalys och kontinuerlig mätning av din utveckling.'
  },
  [TrVar.YvonneAgazarinsModel]: {
    en: `Yvonne Agazarin's model and her work in resolving conflicts
                  and creating consensus between different individuals and groups.`,
    sv: `Yvonne Agazarins modell och hennes arbete med att lösa konflikter
                  och skapa samförstånd mellan olika individer och grupper.`
  },
  [TrVar.ZebrainLegal]: {
    en: 'zebrain.se/en/legal',
    sv: 'zebrain.se/sv/legal'
  },
  [TrVar.ZebrainsClientsCanMeasureTheirEffectOfCoaching]: {
    en: `Zebrain's clients can measure their effect of coaching. You can see your client's measurements under`,
    sv: 'Zebrains klienter kan mäta sin effekt av coachingen. Du finner dina klienters mätningar under'
  },
  [TrVar.ZebrainsMethodAreas]: {
    en: `The three first areas that we measure and follow up - Self-insight, Basic energy
                  and Motivation - aim to effectively use the fourth area: Self-leadership.
                  It is crucial for your development to yield optmised effects included ROI
                  (Return Of Investment).
                  `,
    sv: `De tre första områdena vi mäter och följer upp - Självinsikt, Grundenergi
                  och Motivation - syftar till att effektivt kunna tillämpa det fjärde området:
                  Självledarskap. Det är avgörande för att din utveckling skall ge optimala
                  effekter inklusive ROI (Return Of Investment).
                  `
  },
  [TrVar.ZebrainsOwnMethod]: {
    en: `Our own developed method, our algorithms, and our motivation test is built on,
                  amongst others, Self Determination Theory (SDT) which was developed early
                  1970s by Edward Deci and Richard Ryan, Kenneth W Thomas as well as research by
                  Teresa Amabile, Head of Research at Harvard Business School. Our method is also
                  built on the more modern research that shows how praise, especially unexpected,
                  affects our inner motivation in a positive way.
                  `,
    sv: `Vår egenutvecklade metod, våra algoritmer och vårt motivationstest bygger
                  bl.a på Self Determination Theory (SDT) som utvecklades på 1970-talet av
                  Edward Deci och Richard Ryan, Kenneth W Thomas samt forskning av Teresa
                  Amabile, forskningschef på Harvard Business School. Vår metod bygger även
                  på senare forskning som visar hur beröm, framförallt oväntad, påverkar
                  den inre motivationen positivt.
                  `
  },
  [TrVar.ZebrainsReference1]: {
    en: `* (Manz & Sims 1991, Manz 1992, Neck & Houghton 2006, Marques-Quinteiro
                  & Curral 2012).`,
    sv: `* (Manz & Sims 1991, Manz 1992, Neck & Houghton 2006, Marques-Quinteiro
                  & Curral 2012).`
  },
  [TrVar.ZebrainsReference2]: {
    en: `** (Manz & Sims 1991, Neck & Houghton 2006).`,
    sv: `** (Manz & Sims 1991, Neck & Houghton 2006).`
  },
  [TrVar.ZebrainsReference3]: {
    en: `*** (Manz & Sims 1991, Neck & Houghton 2006, D'Intino et al. 2007).`,
    sv: `*** (Manz & Sims 1991, Neck & Houghton 2006, D'Intino et al. 2007).`
  },
  [TrVar.ZebrainsReference4]: {
    en: `**** (Manz 1992, Neck & Houghton 2006).`,
    sv: `**** (Manz 1992, Neck & Houghton 2006).`
  },
  [TrVar.ZebrainsTestMethods]: {
    en: `The test, and our methods, are built on factors behind how well we succeed
                  with something important to us. Regardless if your motivation stems from improvements
                  to your private or professional life, our algorithn can pick out and differentiate
                  these motivating factors to make the appropriate recommendations.
                  `,
    sv: `Testet och vår metod bygger på faktorer som ligger bakom hur väl vi lyckas med
                  något som är viktigt för oss. Det gäller oaavsett vad du vill lyckas med och kan
                  se olika ut beroende på om det gäller arbetet eller privat.
                  `
  },
  [TrVar.ZebrainsUniqueMeasurementOfPotential]: {
    en: `Zebrain's unique measure of potential contains four areas. The sum of, and the
                  relationship between these four areas make up your total potential. The areas
                  are based on extensive research and practical experience within behavioural science,
                  inner motivation, neuroscience, psychometry and psychology.
                  `,
    sv: `Zebrains unika mätning av potential innehåller fyra områden. Summan av, och
                  förhållandet mellan dessa fyra områden bildar din totala potential. Områdena
                  är baserade på omfattande forskning och praktiska erfarenheter inom beteendeanalys,
                  inre motivation, neurovetenskap, psykometri och psykologi.
                  `
  },
  [TrVar.ZebrainsUniqueMeasurementOfPotentialContainsFourFactors]: {
    en: `Zebrain's unique measurement of potential contains four factors based on extensive research
                and practical experiences with behavioral analysis, inner motivation, neuro science, psycometry
                and psychology. These four factors are`,
    sv: `Zebrains unika mätning av potential innehåller fyra faktorer baserade på omfattande forskning och
                praktiska erfarenheter inom beteendeanalys, inre motivation, neurovetenskap, psykometri och
                psykologi. Dessa fyra faktorer är`
  },  
}
