import React from "react";
import {
  IonContent,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonLabel
} from "@ionic/react";
import Api from "../../services/Api";
import './Login.css';
import WebSocketClient from "../../services/WebSocketClient";
import { InputInterface, InputsInterface } from "../form/interfaces";
import Input from "../form/Input";
import { countInputErrors } from "../form/CheckInput";
import ExternalLogging from "../../services/ExternalLogging";
import { TrVar } from "../../services/translate"
import {CONFIG} from "../../constants";

enum LOGIN {
  BankID,
  Password,
  ForgotPassword,
  None,
  NoLogin
}

enum PERSON_STATE {
  NOT_STARTED,
  ONBOARDING,
  ONBOARDED
}

enum WHO {
  UNKNOWN,
  CLIENT,
  COACH
}

enum ERROR_PAGE {
  NONE,
  LICENSE_NOT_FOUND,
  LICENSE_EXPIRED,
  LICENSE_NO_SEATS_AVAILABLE,
  UNKNOWN
}

interface ComponentProps {
  exitLogin?: Function
}

interface ComponentState {
  errorMsg: string
  errorPage: ERROR_PAGE
  who: WHO
  onBoarding: boolean
  loggedIn: boolean
  formOk: boolean
  authProviderTalk: boolean
  unknownUser: boolean
  bankIdMessage: string
  orderRef: string
  transactionId: string
  loginMethod: number
  waitingForPoll: boolean
  showCreate: boolean
  renderNr: number
  loaded: boolean
  inputs: InputsInterface
  showFormError: boolean
  signupAgreement: boolean
  showAgreementError: boolean
  upgradeIncognito: boolean
  package: string
  showBankIdForm: boolean
  showBankIdOnThisUnit: boolean
  autoStartToken: string
  showBankIdDesktopView: boolean
}

const LAST_LOGIN_TOKEN = "zebrain_last_login_type"

export default class Login extends React.Component<ComponentProps, ComponentState> {

  private api = Api.getInstance()
  private webSocketClient = WebSocketClient.getInstance()
  private externalLogging = ExternalLogging.getInstance()
  private pollInterval: any
  private canCreateAccount = false
  urlSearchParams: any;
  inputs: InputsInterface = {}

  constructor(props: ComponentProps) {
    super(props);
    this.setURLSearch();
    this.state = {
      errorMsg: '',
      errorPage: ERROR_PAGE.NONE,
      who: this.getWho(),
      onBoarding: this.getOnboarding(),
      loggedIn: this.api.loggedIn,
      formOk: false,
      authProviderTalk: false,
      unknownUser: false,
      bankIdMessage: '',
      orderRef: '',
      transactionId: '',
      loginMethod: LOGIN.None,
      waitingForPoll: false,
      showCreate: false,
      renderNr: 0,
      loaded: false,
      inputs: this.inputs,
      showFormError: false,
      signupAgreement: false,
      showAgreementError: false,
      upgradeIncognito: window.location.pathname.indexOf('create_account') > -1,
      package: this.urlSearchParams.package,
      showBankIdForm: false,
      showBankIdOnThisUnit: false,
      autoStartToken: '',
      showBankIdDesktopView: true,
    }
  }

  componentDidMount() {
    this.setURLSearch();
    this.setInputFields()
    this.checkDevice();

    if (this.urlSearchParams.login_method) {
      let login = LOGIN.None

      if (this.urlSearchParams.login_method === '0') {
        login = LOGIN.BankID
      } else if (this.urlSearchParams.login_method === '2') {
        login = LOGIN.Password
      }
      
      this.setState({
        loginMethod: login,
        loaded: true,
      })  
    } else {
      const lastLogin: LOGIN = window.localStorage[LAST_LOGIN_TOKEN] ?
        parseInt(window.localStorage[LAST_LOGIN_TOKEN]) : LOGIN.None

      if (lastLogin === LOGIN.None && this.state.package === 'free') {
        if (window.location.search.indexOf('utm_medium') > -1) {
          this.externalLogging.enable()
        }
        if (this.urlSearchParams.incognito === '1') {
          this.createIncognitoAccount()
          return
        }
      }

      if (this.state.package && this.state.package !== 'free') {
        this.validateLicenseCode()
      }

      this.canCreateAccount = this.setShowCreate()
      let showCreate = (lastLogin === LOGIN.None && this.canCreateAccount)
      
      this.setState({
        showCreate: showCreate,
        loginMethod: lastLogin,
        loaded: true,
      })      
    }
  }

  checkDevice = () => {
    if ("maxTouchPoints" in navigator) {
      if (navigator.maxTouchPoints > 0) {
        this.setState({ showBankIdDesktopView: false })
      } else {
        const UA = navigator.userAgent;
        if(/\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
          /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)) {
          this.setState({ showBankIdDesktopView: false })
        } else {
          this.setState({ showBankIdDesktopView: true })
        }
      }
    }
  }

  iOS = () => {
    // Detect if user is on iOS.
    return navigator.userAgent.includes('iPhone')
      || (navigator.userAgent.includes('iPad')
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document))
  }

  validateLicenseCode = () => {
    const data = {
      code: this.state.package
    }
    const expectedErrors = ['license_code_not_found', 'license_expired', 'no_seats_available']
    this.api.post('validate_license_code', data, expectedErrors).then(result => {
      console.log('License is valid')
    }).catch(response => {
      if (response.isExpectedError) {
        let errorPage = ERROR_PAGE.UNKNOWN
        if (response.errorName === 'license_code_not_found') {
          errorPage = ERROR_PAGE.LICENSE_NOT_FOUND
        } else if (response.errorName === 'license_expired') {
          errorPage = ERROR_PAGE.LICENSE_EXPIRED
        } else if (response.errorName === 'no_seats_available') {
          errorPage = ERROR_PAGE.LICENSE_NO_SEATS_AVAILABLE
        }
        this.setState({errorPage: errorPage})
      }
    });
  }

  setShowCreate = ():boolean => {
    if (this.state.package || this.state.who === WHO.COACH || this.state.upgradeIncognito ||
      window.location.pathname.startsWith('/app/invite')) {
      return true
    }
    return false
  }

  setInputFields = () => {
    this.inputs.email = {
      type: 'text',
      name: 'email',
      placeholder: this.api.trTxt(TrVar.Email),
      value: '',
      error: '',
      label: this.api.trTxt(TrVar.Email) + '*',
      maxLength: 64,
      minLength: 1
    }

    this.inputs.password = {
      type: 'password',
      name: 'password',
      placeholder: this.api.trTxt(TrVar.Password),
      value: '',
      error: '',
      label: this.api.trTxt(TrVar.Password) + '*',
      maxLength: 64,
      minLength: 1
    }

    this.inputs.passwordAgain = {
      type: 'password',
      name: 'passwordAgain',
      placeholder: this.api.trTxt(TrVar.RepeatPassword),
      value: '',
      error: '',
      label: this.api.trTxt(TrVar.RepeatPassword) + '*',
      maxLength: 64,
      minLength: 1
    }

    this.inputs.personnumber = {
      type: 'text',
      name: 'personnumber',
      placeholder: this.api.trTxt(TrVar.DateOfBirthPlaceholder) + 'XXXX',
      value: '',
      error: '',
      label: this.api.trTxt(TrVar.IdNumber) + '*',
      maxLength: 12,
      minLength: 1
    }
  }


  getWho = () => {
    if (window.location.pathname.startsWith('/app/client') || window.location.pathname.startsWith('/app/invite')) {
      return WHO.CLIENT
    } else if (window.location.pathname.startsWith('/app/coach')) {
      return WHO.COACH
    } else {
      return WHO.UNKNOWN
    }
  }

  getOnboarding = () => {
    if (window.location.pathname.startsWith('/app/client_onboarding')) {
      return true
    } else if (window.location.pathname.startsWith('/app/coach_onboarding')) {
      return true
    } else {
      return false
    }
  }

  setURLSearch = () => {
    const searchParams = Object.fromEntries(new URLSearchParams(window.location.search))
    this.urlSearchParams = searchParams;
  }

  setLastLoginType = (loginType: LOGIN) => {
    window.localStorage[LAST_LOGIN_TOKEN] = loginType
  }

  createIncognitoAccount = () => {
    this.api.post('signup_incognito', {})
      .then(response => {
        this.postLogin(response.json.token, response.json.personId)
      })
  }

  toggleAgreement = () => {
    if (this.state.signupAgreement) {
      this.setState({ signupAgreement: false });
    } else {
      this.setState({ signupAgreement: true, showAgreementError: false });
    }
  }

  componentWillUnmount() {
    clearInterval(this.pollInterval)
  }

  private postLogin = (token: string, personId: string) => {

    this.setLastLoginType(this.state.loginMethod)
    if (this.props.exitLogin) {
      // Login is opened by other component that will handle postLogin stuff
      this.api.logIn(token, personId)
      this.webSocketClient.checkOpen()
      this.props.exitLogin(true)
    } else {
      // Login was opened by App.tsx since it had no valid session
      this.api.logInNoDispatch(token, personId)
      this.api.get('person_state')
        .then(response => {
          const clientState = response.json.clientState
          const coachState = response.json.coachState
          const url = this.redirectOrLogin(clientState, coachState)
          if (url) {
            window.location.href = url
          } else {
            this.api.logIn(token, personId)
            this.webSocketClient.checkOpen()
          }
        })
    }
  }

  redirectOrLogin = (clientState: PERSON_STATE, coachState: PERSON_STATE) => {
    if (this.urlSearchParams.incognito === '1') {
      return '/app/client_onboarding?incognito=1&package=free'
    } else if (window.location.pathname.startsWith('/app/start')) {
      if (coachState === PERSON_STATE.ONBOARDED) {
        return '/app/coach'
      } else if (coachState === PERSON_STATE.ONBOARDING) {
        return '/app/coach_onboarding'
      } else if (clientState === PERSON_STATE.ONBOARDED) {
        return '/app/client'
      } else {
        let url = '/app/client_onboarding'
        if (this.state.package) {
          url += '?package=' + this.state.package
        }
        return url
      }
    } else if (window.location.pathname.startsWith('/app/client') ||
      (this.state.package && window.location.pathname.startsWith('/app/start'))) {
      if (clientState === PERSON_STATE.ONBOARDED) {
        return undefined
      } else {
        let url = '/app/client_onboarding'
        if (this.state.package) {
          url += '?package=' + this.state.package
        }
        return url
      }
    } else if (window.location.pathname.startsWith('/app/coach')) {
      if (coachState === PERSON_STATE.ONBOARDED) {
        return undefined
      } else {
        return '/app/coach_onboarding'
      }
    }
    return undefined
  }

  private personalNumberOk = (personNumber:string) => {
    const pn = personNumber;
    if (pn.length !== 12) {
      return false;
    }
    const isnum = /^\d+$/.test(personNumber)
    if (!isnum) {
      return false
    }
    return true;
  }

  private emailOk = (email:string) => {
    if (email.length > 5 || email.indexOf('@') > -1) {
      return true;
    }
    return false;
  }

  private loginPasswordOk = (password:string) => {
    return password.length > 5;
  }

  private signupPasswordOk = (password:string) => {
    return password.length > 9;
  }

  private passwordAgainOk = (password:string, passwordAgain:string) => {
    return password === passwordAgain;
  }

  handleProgressStatus = (progressStatus: string) => {
    if (progressStatus === 'OUTSTANDING_TRANSACTION') {
      this.setState({bankIdMessage: this.api.trTxt(TrVar.WaitingForYourBankIdCode)})
    } else if (progressStatus === 'USER_SIGN') {
      this.setState({bankIdMessage: this.api.trTxt(TrVar.WaitingForYourBankIdSigning)})
    } else if (progressStatus === 'NO_CLIENT') {
      this.setState({bankIdMessage: this.api.trTxt(TrVar.PleaseStartBankId)})
    } else if (progressStatus === 'START_FAILED') {
      this.setState({bankIdMessage: this.api.trTxt(TrVar.BankIdStartTimeout), authProviderTalk: false})
      clearInterval(this.pollInterval)
    } else if (progressStatus === 'EXPIRED_TRANSACTION') {
      this.setState({bankIdMessage: this.api.trTxt(TrVar.BankIdCompleteTimeout), authProviderTalk: false})
      clearInterval(this.pollInterval)
    } else if (progressStatus === 'COMPLETE') {
      this.setState({bankIdMessage: 'Error: done, should not be handled here...'})
      clearInterval(this.pollInterval)
    } else {
      this.setState({bankIdMessage: 'Unknown response: ' + progressStatus})
    }
  }

  private loginPassword = () => {
    const data = {
      email: this.inputs.email.value,
      password: this.inputs.password.value
    }

    this.setState({errorMsg: ''})

    if (!this.emailOk(data.email)) {
      this.setState({errorMsg: this.api.trTxt(TrVar.InvalidEmail)})
      return
    }

    if (!this.loginPasswordOk(data.password)) {
      this.setState({errorMsg: this.api.trTxt(TrVar.PasswordTooShort)})
      return
    }

    this.api.post('login', data, ['login_failed'])
      .then(response => {
        this.postLogin(response.json.token, response.json.personId)
      })
      .catch(response => {
        if (response.isExpectedError) {
          this.setState({errorMsg: this.api.trTxt(TrVar.LoginFailed)})
        }
      });
  }

  inputChange = (input: InputInterface) => {
    const formError = countInputErrors(this.inputs) > 0
    this.setState({ inputs: this.inputs, showFormError: formError })
  }

  private signupPassword = () => {
    if (!this.state.signupAgreement) {
      this.setState({ showAgreementError: true })
      return;
    } 

    const data = {
      email: this.inputs.email.value,
      password: this.inputs.password.value,
      password_verify: this.inputs.passwordAgain.value
    }

    this.setState({ errorMsg: '', showAgreementError: false });

    if (!this.emailOk(data.email)) {
      this.setState({errorMsg: this.api.trTxt(TrVar.InvalidEmail)})
      return
    }

    if (!this.signupPasswordOk(data.password)) {
      this.setState({errorMsg: this.api.trTxt(TrVar.PasswordTooShort)})
      return
    }

    if (!this.passwordAgainOk(data.password, data.password_verify)) {
      this.setState({errorMsg: this.api.trTxt(TrVar.PasswordDontMatch)})
      return
    }

    this.api.post('signup_password', data, ['email_in_use'])
    .then(response => {
      this.postLogin(response.json.token, response.json.personId)
    })
    .catch(response => {
      if (response.isExpectedError) {
        this.setState({errorMsg: this.api.trTxt(TrVar.EmailInUse)})
      }
    });
  }


  private loginBankId = () => {
    if (this.state.showCreate) {
      if (!this.state.signupAgreement) {
        this.setState({ showAgreementError: true });
        return;
      }      
    }


    let data = {
      personal_number: this.inputs.personnumber.value,
      login_only: !this.canCreateAccount
    }
    
    this.setState({ errorMsg: '', unknownUser: false, showAgreementError: false });

    if (!this.personalNumberOk(data.personal_number)) {
      this.setState({errorMsg: this.api.trTxt(TrVar.WrongFormatPersonalNumber)})
      return
    }
    if (this.state.authProviderTalk) {
      console.log('Session started, hold your horses')
      return
    }
    this.setState({authProviderTalk: true, bankIdMessage: this.api.trTxt(TrVar.StartBankIdPhone), errorMsg: ''})


    this.api.post('login_bankid_start', data, ['no_user_account']).then(response => {
      if (response.json.status === 'ok') {
        this.setState({orderRef: response.json.orderRef, transactionId: response.json.transactionId})
        this.startPolling(false)
      } else if (response.json.status === 'already_in_progress') {
        this.setState({errorMsg: this.api.trTxt(TrVar.AnotherSessionIsAlreadyStarted), authProviderTalk: false})
      } else {
        this.setState({errorMsg: response.json.message, authProviderTalk: false})
      }
    })
    .catch(_response => {
      let errorMsg = ''
      if (_response.isExpectedError) {
        errorMsg = this.api.trTxt(TrVar.FoundNoAccountWithThatPersonNumber)
      }
      this.setState({authProviderTalk: false, errorMsg: errorMsg})

    });
  }

  startPolling = (isPhone: boolean) => {
    this.pollInterval = setInterval(() => {
      console.log('polling...')
      if (this.state.authProviderTalk && !this.state.waitingForPoll) {
        if (this.state.loginMethod === LOGIN.BankID) {
          this.pollBankId(isPhone)
        }
      }
    }, 3000)
  }

  pollBankId = (noId: boolean) => {
    this.setState({authProviderTalk: true, waitingForPoll: true, errorMsg: ''})
    const postData = {
      personal_number: noId ? null : this.inputs.personnumber.value,
      orderRef: this.state.orderRef,
      transactionId: this.state.transactionId,
      login_only: !this.canCreateAccount
    };
    let url = "login_bankid_poll"

    this.api.post(url, postData, ['person_number_in_use'])
      .then(response => {
        this.setState({waitingForPoll: false})
        if (response.json.status === 'ok') {
          clearInterval(this.pollInterval)
          this.postLogin(response.json.token, response.json.personId)
        } else if (response.json.status === 'pending') {
          this.handleProgressStatus(response.json.progressStatus)
        } else { // USER_CANCEL, CANCELLED, EXPIRED_TRANSACTION
          let message = response.json.message
          if (!message) {
            message = this.api.trTxt(TrVar.LoginCancelled)
          }
          this.setState({errorMsg: message, authProviderTalk: false})
          clearInterval(this.pollInterval)
        }
      })
      .catch(_response => {
        this.setState({waitingForPoll: false})
        clearInterval(this.pollInterval)
        let errorMsg = this.api.trTxt(TrVar.BankIdError)
        if (_response.isExpectedError) {
          if (_response.errorName === 'person_number_in_use') {
            errorMsg = this.api.trTxt(TrVar.WeCouldNotCreateAccount)
          }
        }
        this.setState({errorMsg: errorMsg, authProviderTalk: false});
      });
  }

  private forgotPassword = () => {
    const data = {
      email: this.inputs.email.value
    }

    this.setState({errorMsg: ''})

    if (!this.emailOk(data.email)) {
      this.setState({errorMsg: this.api.trTxt(TrVar.InvalidEmail)})
      return
    }

    this.api.post('forgot_password', data).then(_response => {
      this.setState({errorMsg: this.api.trTxt(TrVar.InstructionEmail) + ' ' + this.inputs.email.value})
    });
  }

  changeLanguage = (lang: string) => {
    this.api.setLanguage(lang)

    this.inputs.email = {
      type: 'text',
      name: 'email',
      placeholder: this.api.trTxt(TrVar.Email),
      value: '',
      error: '',
      label: this.api.trTxt(TrVar.Email) + '*',
      maxLength: 64,
      minLength: 1
    }

    this.inputs.password = {
      type: 'password',
      name: 'password',
      placeholder: this.api.trTxt(TrVar.Password),
      value: '',
      error: '',
      label: this.api.trTxt(TrVar.Password) + '*',
      maxLength: 64,
      minLength: 1
    }

    this.inputs.passwordAgain = {
      type: 'password',
      name: 'passwordAgain',
      placeholder: this.api.trTxt(TrVar.RepeatPassword),
      value: '',
      error: '',
      label: this.api.trTxt(TrVar.RepeatPassword) + '*',
      maxLength: 64,
      minLength: 1
    }

    this.inputs.personnumber = {
      type: 'text',
      name: 'personnumber',
      placeholder: this.api.trTxt(TrVar.DateOfBirthPlaceholder) + 'XXXX',
      value: '',
      error: '',
      label: this.api.trTxt(TrVar.IdNumber) + '*',
      maxLength: 12,
      minLength: 1
    }
    
    this.setState({renderNr: this.state.renderNr + 1})
  }

  private showUnknownUserOptions = () => {
    return (
      <div className="top_margin text_center bg_light_blue color_black p-4">
        <p><b>{this.api.trTxt(TrVar.CannotFindAccount)}</b></p>
      </div>
    )
  }

  private changeView = (view?: string) => {
    this.setState({ signupAgreement: false, showAgreementError: false });
    if (view === 'bankid') {
      this.setState({loginMethod: LOGIN.BankID, errorMsg: ''})
    } else if (view === 'password') {
      this.setState({loginMethod: LOGIN.Password, errorMsg: ''})
    } else if (view === 'forgotpassword') {
      this.setState({loginMethod: LOGIN.ForgotPassword, errorMsg: ''})
    } else if (view === 'none') {
      this.setState({loginMethod: LOGIN.None, errorMsg: ''})
    }
  }

  changeLoginMethod = () => {
    if (this.urlSearchParams.login_method) {
      return (
        <>
        </>
      )
    } else {
      return (
        <div data-cy="change-login" onClick={() => this.changeView('none')}
            className="top_margin font_small cursor-pointer underline">
          {this.api.trTxt(TrVar.ChangeLoginMethod)}
        </div>
      )      
    }
  }

  toggleCreateLogin = () => {
    if (this.state.upgradeIncognito) {
      return (<></>)
    }
    if (this.state.showCreate) {
      return (
        <>
          <div className="top_margin">{this.api.trTxt(TrVar.DoYouAlreadyHaveAnAccount)}</div>
          <div  style={{ cursor: 'pointer', textDecoration: 'underline' }} data-cy="already-account" className=" small inline_block " onClick={() => this.setState({ showCreate: false })}>
            {this.api.trTxt(TrVar.Login)}
          </div>
        </>
      )
    } else if (this.canCreateAccount) {
      return (
        <>
          <div className="top_margin">{this.api.trTxt(TrVar.DoYouNotHaveAnAccount)}</div>
          <div style={{ cursor: 'pointer', textDecoration: 'underline' }} data-cy="have-no-account"
               className="small inline_block" onClick={() => this.setState({ showCreate: true })}>
            {this.api.trTxt(TrVar.CreateAccount)}
          </div>
        </>
      )
    } else {
      return (
        <div className="top_margin cursor-pointer underline" data-cy="have-no-account"
             onClick={() => this.setState({loginMethod: LOGIN.NoLogin})}>
          {this.api.trTxt(TrVar.DoYouNotHaveAnAccount)}
        </div>
      )
    }
  }

  renderAgreement = () => {
    return (
      <>
        <div className="top_margin flex">
          <div data-cy="agreement-input" className={"login_agreement right_margin relative " + (this.state.signupAgreement ? 'checked' : '')} onClick={() => this.toggleAgreement()} />
          <div style={{ fontSize: '10px', textAlign: 'left' }}>
                <div>
                  {this.api.trTxt(TrVar.IApproveThatZebrainStoresMyPersonalInformation)}
                </div>
                <div>
                  {this.api.trTxt(TrVar.ReadMoreAboutHowZebrainHandlesPersonalInformation)} <a target={'_blank'} style={{ cursor: 'pointer', textDecoration: 'underline', color: '#fff' }} href={`https://www.zebrain.se/` + (this.api.lang === 'sv' ? 'sv' : 'en') + `/legal/`} rel="noreferrer">{this.api.trTxt(TrVar.Here).toLowerCase()}.</a>
                </div>
          </div>
        </div>
        {
          this.state.showAgreementError &&
          <div className="top_margin error_red bg_white p-4">
            {this.api.trTxt(TrVar.YouHaveToAgreeBeforeYouCanContinue)}
          </div>
        }
      </>
    );
  }

  renderLoginNone = () => {
    return (
      <div>
        <h1 className="bottom_margin">
          {
            this.state.showCreate ?
              this.state.package === 'free' ?
                this.api.trTxt(TrVar.CreateFreeAccount)
                :
                this.api.trTxt(TrVar.CreateAccount)
              :
              this.api.trTxt(TrVar.HappyToSeeYou)
          }
        </h1>
        {
          this.state.showCreate ?
            <div style={{ maxWidth: '450px', margin: '0 auto' }}>
              {
                this.state.package === 'free' ?
                  this.api.trTxt(TrVar.WelcomeToZebrainCreateAUserAndGetStartedWithYourDevelopment)
                  :
                  this.state.upgradeIncognito ?
                    this.api.trTxt(TrVar.ChooseHowToLogin)
                    :
                    this.api.trTxt(TrVar.WelcomeToZebrainCreateAUserAndGetStarted)
              }
              {
                (this.state.package === 'free' && this.urlSearchParams.package !== 'free') &&
                <p className="top_margin">
                  {
                    this.api.trTxt(TrVar.IfYourCompanyPaysYourLicenseYouShouldNotCreateAccount)
                  }
                </p>
              }
            </div>
            :
            <div>
              {this.api.trTxt(TrVar.ChooseTheLoginMethodYouUsedWhenYouCreatedYourAccount)
              }
            </div>
        }
        <div className="flex justify-center">
          <div className="login_segment">
            <div>
              <img style={{ width: "57px", marginTop: '2px' }} className={(this.state.showCreate ? 'svg_white' : '')} alt="email" src={"/assets/icon/mail.svg"} />
              <p style={{ marginTop: '2px' }} className={(this.state.showCreate ? "color_white_imp" : "")}>{this.api.trTxt(TrVar.Email)}</p>
            </div>
            <div data-cy="email-login" onClick={() => this.changeView('password')} style={{ width: '89px' }} className={"button inline_block " + (this.state.showCreate ? 'green' : '')}>{this.api.trTxt(TrVar.Choose)}</div>
          </div>

          <div className="login_segment">
            <img className={(this.state.showCreate ? "svg_white" : "")} alt="bankID" src={'/assets/icon/bankid.svg'} style={{ display: 'block', height: "65px" }} />
            <div data-cy="bankid-login" onClick={() => this.changeView('bankid')} style={{ width: '89px' }} className={"button inline_block " + (this.state.showCreate ? 'green' : '')}>{this.api.trTxt(TrVar.Choose)}</div>
          </div>          
        </div>
        {
          this.state.unknownUser && this.showUnknownUserOptions()
        }
        {
          this.toggleCreateLogin()
        }
      </div>
    );
  }

  renderErrorPage = () => {
    let reason: string
    if (this.state.errorPage === ERROR_PAGE.LICENSE_NOT_FOUND) {
      reason = this.api.trTxt(TrVar.CouldNotFindLicense)
    } else if (this.state.errorPage === ERROR_PAGE.LICENSE_EXPIRED) {
      reason = this.api.trTxt(TrVar.ThisLicenseHasExpired)
    } else if (this.state.errorPage === ERROR_PAGE.LICENSE_NO_SEATS_AVAILABLE) {
      reason = this.api.trTxt(TrVar.ThisLicenseHasNoAvailableSeats)
    } else {
      reason = this.api.trTxt(TrVar.UnknownLicenseError)
    }
    return (
      <>
        <h1 className="bottom_margin">
          {
            this.api.trTxt(TrVar.LicenseIsNotAvailable)
          }
        </h1>
        <div className="top_margin">
          {reason}
        </div>
        <div className="top_margin">
          {
            this.api.trTxt(TrVar.ContactSupportZebrain)
          }
        </div>
      </>
    );
  }

  renderLoginPassword = () => {
    return (
      <>
        { (this.state.showCreate && !this.state.upgradeIncognito) &&
          <div className="blue">
            {this.api.trTxt(TrVar.Part1Of2)}
          </div>
        }
        <h1 className="bottom_margin">
          {
            this.state.showCreate ?
              this.state.package === 'free' ?
                this.api.trTxt(TrVar.CreateFree)
                :
                this.api.trTxt(TrVar.EmailAndPassword)
              :
              this.api.trTxt(TrVar.SignInWithEmail)}
        </h1>
        {this.changeLoginMethod()}
        <div className="login_inputs top_margin inline_block">
          <div>
            <Input data="email-input" input={this.inputs.email} update={this.inputChange}
              classes={"page_input " + (this.state.showCreate ? ' ' : 'dark_blue')} language={this.api.lang} errorClasses="bg_white p-4" />
          </div>
          <div className="top_margin">
            <Input data="password-input" input={this.inputs.password} update={this.inputChange}
              classes={"page_input " + (this.state.showCreate ? ' ' : 'dark_blue')} language={this.api.lang} errorClasses="bg_white p-4" />
          </div>
          {
            this.state.showCreate &&
            <>
              <div className="top_margin">
                <Input data="password-again-input" input={this.inputs.passwordAgain} update={this.inputChange}
                  classes="page_input" language={this.api.lang} errorClasses="bg_white p-4" />
              </div>
              <div style={{ marginTop: '10px', textAlign: 'left', fontSize: '13px' }}>
                {this.api.trTxt(TrVar.PasswordMustIncludeAtLeastTenCharacters)}
              </div>
              {this.renderAgreement()}
            </>
          }
          {
            this.state.errorMsg.length > 0 &&
              <div data-cy="login-password-error" className="top_margin bg_white color_black p-4">{this.state.errorMsg}</div>
          }
          <div className="mt-12">
            {this.state.showCreate ?
              <div data-cy="email-signup" onClick={() => this.signupPassword()} className={"button inline_block green "}>{this.api.trTxt(TrVar.GetStarted)}</div>
              :
              <div data-cy="login-account" onClick={() => this.loginPassword()} className={"button inline_block green"}>{this.api.trTxt(TrVar.Logging)}</div>
            }
          </div>
          {this.toggleCreateLogin()}
          <div className="top_margin">
            {!this.state.showCreate &&
              <div data-cy="forgot-password" onClick={() => this.changeView('forgotpassword')} style={{ textDecoration: 'underline', cursor: 'pointer', marginTop: '30px' }} className="font_smaller">{this.api.lang === 'sv' ? 'Glömt lösenord?' : 'Forgot password?'}</div>
            }
          </div>
        </div>
      </>
    );
  }

  startBankIdPhone = () => {
    if (this.state.showCreate) {
      if (!this.state.signupAgreement) {
        this.setState({ showAgreementError: true });
        return;
      }      
    }

    if (CONFIG.SYSTEM === 'develop') {
      alert('This will not work in develop system - we cannot talk to BankId')
      return
    }

    let data = {
      personal_number: null,
    }
    
    this.setState({ errorMsg: '', unknownUser: false, showAgreementError: false });

    if (this.state.authProviderTalk) {
      console.log('Session started, hold your horses')
      return
    }

    this.setState({authProviderTalk: true, errorMsg: ''})

    this.api.post('login_bankid_start', data).then(response => {
      if (response.json.status === 'ok') {
        this.setState({
          autoStartToken: response.json.autoStartToken,
          orderRef: response.json.orderRef,
          transactionId: response.json.transactionId
        }, () => {
          let redirect = 'null'
          let bankidUrl = 'https://app.bankid.com/?autostarttoken=' + this.state.autoStartToken
          if (CONFIG.SYSTEM !== 'prod' && this.iOS()) {
            if (navigator.userAgent.includes('CriOS')) {
              // Chrome identifies as CriOS. Use hack in redirect to avoid opening new tab
              redirect = encodeURIComponent('googlechrome://')
            } else {
              redirect = encodeURIComponent(window.location.href + '#polling')
            }
            console.log('Redirect: ' + redirect)
          }
          window.location.href = bankidUrl + '&redirect=' + redirect;
          this.startPolling(true)        
        })
      }
    })
    .catch(_response => {
      let errorMsg = ''
      this.setState({authProviderTalk: false, errorMsg: errorMsg})
    });      
 
  }

  renderLoginBankId = () => {
    return (
      <>
        { (this.state.showCreate && !this.state.upgradeIncognito) &&
          <div className="blue">
            {this.api.trTxt(TrVar.Part1Of2)}
          </div>
        }  
        <h1 className="bottom_margin">
          {
            this.state.showCreate ?
              this.api.trTxt(TrVar.BankId)
              :
              this.api.trTxt(TrVar.LoginWithBankId)}
        </h1>
        {this.changeLoginMethod()}
        { (!this.state.showBankIdForm && !this.state.showBankIdOnThisUnit) &&
          <>
          {
            this.state.showBankIdDesktopView ?
              <>
               <div className="btn_top_margin">
                  <div onClick={() => this.setState({ showBankIdForm: true })} data-cy="login-different-unit"
                      className="button inline_block mobile_size_btn">
                    {this.api.trTxt(TrVar.BankIdMobile)}
                  </div>
                </div>                    
                <div onClick={() => {
                  this.setState({
                    showBankIdOnThisUnit: true
                  });
                  this.startBankIdPhone()
                  }} className="btn_top_margin underline cursor-pointer">{this.api.trTxt(TrVar.BankIdOnThisDevice)}</div>               
              </>
              :
              <>
                <div className="btn_top_margin">
                <div onClick={() => {
                  this.setState({
                    showBankIdOnThisUnit: true
                  });
                  this.startBankIdPhone()
                  }} className="button mobile_size_btn inline_block">{this.api.trTxt(TrVar.BankIdMobile)}</div>
                </div>
                <div className="btn_top_margin">
                  <div onClick={() => this.setState({ showBankIdForm: true })} data-cy="login-different-unit"
                      className="underline cursor-pointer">
                    {this.api.trTxt(TrVar.BankIdWithPersonNumber)}
                  </div>
                </div>                     
              </>
          }
             
          </>
        }

        { this.state.showBankIdOnThisUnit &&
          <>
            <div className="btn_top_margin">
              <div onClick={() => {
                this.setState({
                  showBankIdOnThisUnit: false
                })
                }} className="button red inline_block">{this.api.trTxt(TrVar.Cancel)}</div>            
            </div>

          </>
        }

        <div className="login_inputs top_margin inline_block">
          {
            this.api.lang !== 'sv' &&
            <p className={"top_margin " + (this.state.showCreate ? 'color_white' : '')}>
              ({this.api.trTxt(TrVar.OnlyWorksSweden)})
            </p>
          }
          {
              this.state.errorMsg.length > 0 &&
                <div data-cy="login-bankid-error" className="top_margin bg_white p-4 error_red">{this.state.errorMsg}</div>
          }          
          {this.state.showBankIdForm &&
          <>
            <div>
              <Input data="personnumber-input" input={this.inputs.personnumber} update={this.inputChange}
                classes={"page_input top_margin " + (this.state.showCreate ? '' : 'dark_blue')} language={this.api.lang} errorClasses="bg_white p-4" />
            </div>
            {
              this.state.showCreate &&
              this.renderAgreement()
            }

            {
              this.state.authProviderTalk &&
              <div className="top_margin p-4 bg_light_blue color_black">
                {this.api.trTxt(TrVar.StartBankIdPhone)}
              </div>
            }
            {
              !this.state.authProviderTalk &&
              <div className="flex top_margin justify-center section_direction reverse">
              <div onClick={() => this.setState({ showBankIdForm: false })} className="button r_top_margin mobile_size_btn inline_block black right_margin">{this.api.trTxt(TrVar.GoBack)}</div>
                  
                <div data-cy="login-bankid" onClick={() => this.loginBankId()}
                  className={"button mobile_size_btn inline_block " }>
                  {
                    this.state.showCreate ?
                      this.api.trTxt(TrVar.GetStarted)
                      :
                      this.api.trTxt(TrVar.Logging)
                  }
                </div>
              </div>
            }            
          </>
          }

        </div>
      </>
    );
  }

  renderLoginForgotPassword = () => {
    return (
      <>
        <h1 className=" bottom_margin">
          {this.api.trTxt(TrVar.RecoverPassword)}
        </h1>
        {this.changeLoginMethod()}
        <div className="login_inputs top_margin inline_block">
          <div>
            {this.api.trTxt(TrVar.EnterYourEmailAddressToRecoverPassword)}
          </div>
          <div className="top_margin">
            <Input data="email-input" input={this.inputs.email} update={this.inputChange}
              classes="page_input dark_blue" language={this.api.lang} errorClasses="bg_white p-4" />
          </div>
          <div className="mt-12">
            <div onClick={() => this.forgotPassword()} className="button green inline_block">{this.api.trTxt(TrVar.RecoverPassword)}</div>
          </div>
          <div className="top_margin">
            {!this.state.showCreate &&
              <div onClick={() => this.changeView('password')} style={{ textDecoration: 'underline', cursor: 'pointer', marginTop: '30px' }} className="font_smaller">{this.api.trTxt(TrVar.GoBack)}</div>
            }
          </div>
          {
            this.state.errorMsg.length > 0 &&
            <div className="top_margin bg_white color_black p-4">{this.state.errorMsg}</div>
          }
        </div>
      </>
    );
  }

  renderNoLogin = () => {
    return (
      <>
        <h1 className="bottom_margin">
          {this.api.trTxt(TrVar.NoAccount)}?
        </h1>
        <div className="top_margin">
          <p>
          {
            this.api.trTxt(TrVar.ToCreateAnAccountInZebrainYouNeedAnInvitation)
          }
          </p>
          <p className="mt-4">
            {
              this.api.trTxt(TrVar.SendAMailToSupportAndWeWillHelpYou)
            }
          </p>
        </div>
        <div onClick={() => this.changeView('none')}
             className="mt-16 cursor-pointer underline">
          {this.api.trTxt(TrVar.BackToLoginPage)}
        </div>
      </>
    );
  }

  localRoute = () => {
    if (this.state.errorPage !== ERROR_PAGE.NONE) {
      return this.renderErrorPage()
    } else if (this.state.loginMethod === LOGIN.None) {
      return this.renderLoginNone()
    } else if (this.state.loginMethod === LOGIN.Password) {
      return this.renderLoginPassword()
    } else if (this.state.loginMethod === LOGIN.BankID) {
      return this.renderLoginBankId()
    } else if (this.state.loginMethod === LOGIN.ForgotPassword) {
      return this.renderLoginForgotPassword()
    } else if (this.state.loginMethod === LOGIN.NoLogin) {
      return this.renderNoLogin()
    }

  }

  render() {
    // For some unknown reason bankid segment is chosen even if loginMethod is password (after upgrading ionic version).
    // Waiting for loaded until rendering solves this problem.
    if (!this.state.loaded) {
      return (<></>)
    }

    return (
      <IonPage>
        <IonContent>
          <div className="page_content text_center">
            {
              !this.state.upgradeIncognito &&
              <div className="relative">
                <img className="login_logo" src="/assets/icon/white/logo_text.svg" alt="zebrain logo" />
                <div style={{ position: 'absolute', top: '-65px', right: '0' }} className="cursor-pointer" onClick={() => this.changeLanguage(this.api.lang === 'sv' ? 'en' : 'sv')}>
                  <img className="circle_small" alt="english" src={"/assets/icon/language_" + (this.api.lang === 'sv' ? 'en' : 'sv') + ".png"} />
                </div>
              </div>
            }
            <div style={{ marginTop: '115px' }}
              className={"page_section relative  " + (this.state.showCreate ? 'background_stripes' : 'background_stripes dark')}>
              {
                this.localRoute()
              }
            </div>
          </div>
        </IonContent>
      </IonPage>
    )
  }
}
